import React from 'react'
import PostContainer from '../../components/containers/Posts/PostContainer'
import WithSidebar from '../../components/shared/WithSidebar';

const Post = () => {
    return (
        <WithSidebar>
        <PostContainer />
        </WithSidebar>
    )
};

export default Post;