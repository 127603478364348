import React, { useState } from "react";
import {
  Input,
  Form,
  Icon,
  Button,
  Row,
  notification,
  Select,
  message,
  Modal,
  List,
  Col,
} from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { groupAccessPeriod } from "../../../../utils/statics";
import { coursePeriod } from "../../../../utils/statics";
import { courseType } from "../../../../utils/statics";

import CourseBlock from "../../../snippets/listing/CourseBlock";
import SettingsBody from "../../../shared/SettingsBody";
import { invokeCreateCourse } from "../../../../actions/courses";
import { getAllCourses } from "../../../../actions/courses";

const { TextArea } = Input;
const { Option } = Select;

interface NewGroupContainer {
  invokeCreateCourse: (responseBody: any) => any;
  getAllCourses: () => any;
  form: any;
  groups: Array<any>;
  courses: Array<any>;
}

function CreateCourseContainer(props: NewGroupContainer) {
  const [groupCategory, setGroupCategory] = useState<any>();
  const [groupType, setGroupType] = useState<any>();
  const [period, setPeriod] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [coursetype, setCourseType] = useState<any>();

  React.useEffect(() => {
    if (props.courses.length < 1) {
      props.getAllCourses();
    }
  }, []);

  //Course created notification
  const openNotification = () => {
    notification.open({
      message: "Success",
      description: "Course Created!",
      icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
    });
  };

  //Create course modal
  const showModal = () => {
    setVisible(true);
  };

  //Modal cancel event
  const handleCancel = () => {
    setVisible(false);
  };

  //Modal confirm
  const handleOk = () => {
    props.form.validateFields((err: any, vals: any) => {
      if (!err) {
        const {
          title,
          description,
          group_access_period_id,
          course_code,
          price,
          period_id,
          group_id,
          url,
        } = vals;

        if(coursetype == 1) {
          //Free
          const params = {
            ...vals,
            course_code: null,
            url: "",
            word_count: parseInt(vals.word_count.toString()),
            group_access_period_id: parseInt(
              vals.group_access_period_id.toString()
            ),
            price: parseInt(vals.price.toString()),
            period_id: parseInt(vals.period_id.toString()),
          };
          const submit = props.invokeCreateCourse(params);
          submit.then((group_id: any) => {
            if (group_id) {
              openNotification();
              setTimeout(function () {
                window.location.reload();
              }, 2000);
            } else message.info(`Couldn't save group`);
          });

        } else {
          
          //Paid
          const params = {
            ...vals,
            word_count: parseInt(vals.word_count.toString()),
            group_access_period_id: parseInt(
              vals.group_access_period_id.toString()
            ),
            price: parseInt(vals.price.toString()),
            period_id: parseInt(vals.period_id.toString()),
          };

          const submit = props.invokeCreateCourse(params);
          submit.then((group_id: any) => {
            if (group_id) {
              openNotification();
              setTimeout(function () {
                window.location.reload();
              }, 2000);
            } else message.info(`Couldn't save group`);
          });

        }
       
      }
    });
  };

  const { getFieldDecorator } = props.form;
  const { form } = props;

  return (
    <SettingsBody active="courses">
      <div className="twp-wrapper inner-l">
        <Row className="reader">
          <Row>
            <Col span={16}>
              <h1>Courses</h1>
            </Col>
            <Col span={8}>
              <div style={{ textAlign: "end" }}>
                <Button type="primary" onClick={showModal}>
                  Create Courses
                </Button>
              </div>
            </Col>
          </Row>
          <Modal
            title="Create a Course"
            visible={visible}
            onOk={handleOk}
            okText="Submit"
            //   confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <Form>
              <Row className="inner">
                <Form.Item htmlFor="title">
                  {getFieldDecorator("title", {
                    rules: [
                      {
                        required: true,
                        message: "Can't leave this empty",
                      },
                    ],
                  })(<Input placeholder="Title" />)}
                </Form.Item>
                <Form.Item htmlFor="description">
                  {getFieldDecorator("description", {
                    rules: [
                      {
                        required: true,
                        message: "Can't leave this empty",
                      },
                    ],
                  })(
                    <TextArea
                      placeholder="Course Description"
                      rows={5}
                    ></TextArea>
                  )}
                </Form.Item>
                <Form.Item>
                    <Select
                      placeholder="Course Type"
                      onChange={(v) => setCourseType(v)}
                    >
                      {courseType.courseType.map((s) => (
                        <Option key={s.id + "group_category_id"} value={s.id}>
                          {s.type}
                        </Option>
                      ))}
                    </Select>
                
                </Form.Item>
                <Form.Item htmlFor="product_name">
                {getFieldDecorator("product_name", {
                  rules: [
                    {
                      required: true,
                      message: "Can't leave this empty",
                    },
                  ],
                })(<Input placeholder="Product Name" />)}
              </Form.Item>
                <Form.Item htmlFor="word_count">
                  {getFieldDecorator("word_count", {
                    rules: [
                      {
                        required: true,
                        message: "Can't leave this empty",
                      },
                    ],
                  })(<Input placeholder="Word Count" />)}
                </Form.Item>
                <Form.Item htmlFor="group_access_period_id">
                  {getFieldDecorator("group_access_period_id", {
                    rules: [
                      {
                        required: true,
                        message: "Please select a value",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Group Access Period"
                      onChange={(v) => setGroupCategory(v)}
                    >
                      {groupAccessPeriod.groupAccessPeriod.map((s) => (
                        <Option key={s.id + "group_category_id"} value={s.id}>
                          {s.period}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                {coursetype != 1 ? (
                  <>
                  <Form.Item htmlFor="course_code">
                  {getFieldDecorator("course_code", {
                    rules: [
                      {
                        required: true,
                        message: "Can't leave this empty",
                      },
                    ],
                  })(<Input placeholder="Course Code" />)}
                </Form.Item>
                <Form.Item htmlFor="url">
                {getFieldDecorator("url", {
                  rules: [
                    {
                      required: true,
                      message: "Can't leave this empty",
                    },
                  ],
                })(<Input placeholder="URL" />)}
              </Form.Item>
              </>
                ): null }
                <Form.Item htmlFor="price">
                  {getFieldDecorator("price", {
                    rules: [
                      {
                        required: true,
                        message: "Can't leave this empty",
                      },
                    ],
                  })(<Input placeholder="Price" />)}
                </Form.Item>

                <Form.Item htmlFor="period_id">
                  {getFieldDecorator("period_id", {
                    rules: [
                      {
                        required: true,
                        message: "Can't leave this empty",
                      },
                    ],
                  })(
                    <Select placeholder="Period" onChange={(v) => setPeriod(v)}>
                      {coursePeriod.coursePeriod.map((s) => (
                        <Option key={s.id + "_group_id"} value={s.id}>
                          {s.period}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item htmlFor="group_id">
                  {getFieldDecorator("group_id", {
                    rules: [
                      {
                        required: true,
                        message: "Please select a value",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Group"
                      onChange={(v) => setGroupType(v)}
                    >
                      {props.groups.map((s) => (
                        <Option key={s.id + "_group_id"} value={s.id}>
                          {s.group_name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                
              </Row>
            </Form>
          </Modal>
          <List dataSource={props.courses} renderItem={CourseBlock} />
        </Row>
      </div>
    </SettingsBody>
  );
}

const Actions = {
  invokeCreateCourse,
  getAllCourses,
};

const mapStateToProps = (state: any) => ({
  groups: state.groups.list,
  courses: state.courses.list,
});

//Connect to redux store
const _Form = connect(mapStateToProps, Actions)(CreateCourseContainer);

//Connect to Form and export
export default Form.create({ name: "create_course" })(_Form);
