import React from 'react'
import WithoutHeader from '../../components/shared/WithoutHeader'
import LoginContainer from '../../components/containers/Auth/Login'

function Login() {
    return (
        <WithoutHeader>
            <LoginContainer />
        </WithoutHeader>
    )
}

export default Login;
