import {
  FETCH_GROUP,
  FETCH_GROUP_FAILED,
  FETCH_GROUP_SUCCESS,
  TOGGLE_FOLLOW_GROUP
} from "./_types";

const fetchGroups = () => ({ type: FETCH_GROUP });

const fetchGroupFailed = (err) => ({ type: FETCH_GROUP_FAILED, err });

const fetchGroupSuccess = (payload) => ({ type: FETCH_GROUP_SUCCESS, payload });

const invokeGroupSuccess = () => ({ type: FETCH_GROUP_SUCCESS });

const groupSetFollow = (obj) => ({ type: TOGGLE_FOLLOW_GROUP, obj });


export const invokeCreateGroup = (params) => (dispatch, getState, services) => {
  dispatch(fetchGroups());
  let group_service = services.get("GroupService");
  return group_service
    .createGroup(params)
    .then((res) => {
      dispatch(invokeGroupSuccess)
      return res.data[0].group_id;
    })
    .catch((err) => {
      dispatch(fetchGroupFailed())
      return null
    });
}

export const fetchGroupsInit = (id) => (dispatch, getState, services) => {
  dispatch(fetchGroups());
  let group_service = services.get("GroupService");
  return group_service
    .getGroups(id)
    .then((res) => {
      // dispatch(fetchGroupSuccess(res.data));
      return res.data[0]
    })
    .catch((err) => {
      dispatch(fetchGroupFailed(err));
    });
};

export const followGroups = (id) => (dispatch, getState, services) => {
  const params = {
    group_id: id,
    token: localStorage.getItem("twp-token")
  };

  let group_service = services.get("GroupService");
  return group_service.followGroup(params)
  .then((res) => {
    return res
  })
  .catch((err) => {
    dispatch(fetchGroupFailed(err));
  });
};

export const fetchAllGroups = () => (dispatch, getState, services) => {
  dispatch(fetchGroups());
  let group_service = services.get("GroupService");
  group_service
    .getAllGroups()
    .then((res) => {
      dispatch(fetchGroupSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchGroupFailed(err));
    });
};

export const updateGroup = (params) => (dispath, getState, services) => {
  let group_service = services.get("GroupService");
  return group_service.updateGroup(params).then((res) => {
    return res
  }).catch((err) => console.log({ err }))
}

export const fetchGroupAccessPeriods = () => (dispatch, getState, services) => {
  const group_service = services.get("GroupService")
  return group_service.fetchGroupAccessPeriods()
    .then((res) => {
      return res.data
    }).catch((err) => console.log({ err }))
}

export const fetchGroupFollowers = (group_id) => (dispatch, getState, services) => {
  const group_service = services.get("GroupService")
  return group_service.fetchGroupFollowers(group_id)
    .then((res) => {
      return res.data[0]
    }).catch((err) => console.log({ err }))
}

export const searchGroupFollowers = (params) => (dispatch, getState, services) => {
  const group_service = services.get("GroupService")
  return group_service.searchGroupFollowers(params)
    .then((res) => {
      return res.data;
    }).catch((err) => console.log({ err }))
}

export const addRemoveFollowersToGroups = (params) => (dispatch, getState, services) => {
  const group_service = services.get("GroupService")
  return group_service.addRemoveFollowersToGroups(params)
    .then((res) => {
      return res;
    }).catch((err) => console.log({ err }))
}

export const deleteGroup = (group_id) => (dispatch, getState, services) => {
  dispatch(fetchGroups());

  let group_service = services.get("GroupService");
  group_service
    .deleteGroup({ group_id })
    .then((res) => dispatch(invokeGroupSuccess()))
    .catch((err) => dispatch(fetchGroupFailed()));
};

export const fetchAdminGroups = () => (dispatch, getState, services) => {
  const group_service = services.get("GroupService")
  return group_service.getAdminGroups()
    .then((res) => {
      return res.data
    }).catch((err) => console.log({ err }))
}

export const fetchProfileGroups = () => (dispatch, getState, services) => {
  const group_service = services.get("GroupService")
  return group_service.getAllGroups()
    .then((res) => {
      return res
    }).catch((err) => console.log({ err }))
}

