import {
    USER_SET_PROFILE_BIO,
    USER_SET_PROFILE_POSTS,
    USER_SET_PROFILE_GROUPS,
    FETCH_USER_SUBSCRIPTION,
    FETCH_POST_INITIATE,
    FETCH_POST_INITIATE_SUCCESS,
    FETCH_POST_INITIATE_FAILED
} from './_types'

const postApiInvoke = () => ({ type: FETCH_POST_INITIATE });

const postApiInvokeSuccess = () => ({ type: FETCH_POST_INITIATE_SUCCESS });

const postApiInvokeFailed = () => ({ type: FETCH_POST_INITIATE_FAILED });

const setUserBio = (bio) => ({ type: USER_SET_PROFILE_BIO, payload: bio });

// const setUserSubscription = () => ({ type: FETCH_POST_INITIATE_SUCCESS });

const setUserSubsriptionSuccess = (sub) => ({ type: FETCH_USER_SUBSCRIPTION, payload: sub });

// const setUserSubscriptionFailed = () => ({ type: FETCH_POST_INITIATE_FAILED });

const setUserPosts = (posts) => ({ type: USER_SET_PROFILE_POSTS, payload: posts });

const setUserGroups = (grps) => ({ type: USER_SET_PROFILE_GROUPS, payload: grps });

export const fetchUserPosts = (id,offset,limit) => (dispatch, getState, services) => {
    dispatch(postApiInvoke());
    const UserService = services.get("UserService")
    return UserService.getUserPosts(id,offset,limit)
        .then((res) => {
            const { posts } = res.data[0]
            const psts = posts;
            if (psts && psts.length > 0) {
                dispatch(setUserPosts(psts))
            }
            dispatch(postApiInvokeSuccess());
            return res;
        }).catch((err) => console.log({ err }))
}

export const fetchUserProfile = () => (dispatch, getState, services) => {
    dispatch(postApiInvoke());
    const UserService = services.get("UserService")
    UserService.getUserProfile().then((res) => {
        const bio = res.data[0]
        const groups = bio.user_groups ? bio.user_groups : [];

        dispatch(setUserBio(bio));
        localStorage.setItem('twp-user-id', parseInt(bio.id));
        localStorage.setItem('search-user-profile', bio.profile_pic);
        localStorage.setItem('twp-user-role', bio.user_role)

        if (groups && groups.length > 0) {
            dispatch(setUserGroups(groups));
        }
        dispatch(postApiInvokeSuccess());
    }).catch((err) => console.log({ err }))
}

export const updateUserProfile = (params) => (dispatch, getState, services) => {

    const UserService = services.get("UserService")
    UserService.updateUserProfile(params).then((res) => {
        const bio = res.data[0]
        const groups = bio.user_groups ? JSON.parse(bio.user_groups) : [];

        dispatch(setUserBio(bio));

        if (groups && groups.length > 0) {
            dispatch(setUserGroups(groups));
        }
    }).catch((err) => console.log({ err }))
}

export const updateSubscription = (subs) => (dispatch, getState, services) => {
    const UserService = services.get("UserService")
    const token = localStorage.getItem('twp-token')
    const params = {
        password: subs.password,
        subscription_id: subs.subscription_id,
        token: token
    }

    return UserService.updateSubscription(params)
        .then((res) => {
            return res.data
        }).catch((err) => console.log({ err }))
}

export const fetchSubscriptions = () => (dispatch, getState, services) => {
    const UserService = services.get("UserService")
    return UserService.getSubscriptions()
        .then((res) => {
            return res.data
        }).catch((err) => console.log({ err }))
}

export const fetchUserSubscription = () => (dispatch, getState, services) => {
    const UserService = services.get("UserService")
    return UserService.getUserSubscription()
        .then((res) => {
            localStorage.setItem("user-id", res.data[0].user_id)
            dispatch(setUserSubsriptionSuccess(res.data[0]))
            return res.data[0];

        }).catch((err) => console.log({ err }))
}

export const uploadProfilePicture = (a , b) => (dispatch, getState, services) => {
    const obj = {
        folder: b,
        file_name: a
    }
    const UserService = services.get("UserService")
    return UserService.uploadImage(obj) 
    .then((res) => {
        return res.data;
    })
}

export const fetchSubscriptionPeriods = () => (dispatch, getState, services) => {
    const UserService = services.get("UserService")
    return UserService.fetchSubscriptionPeriods()
        .then((res) => {
            return res.data
        }).catch((err) => console.log({ err }))
}

export const fetchAdminUserRoles = () => (dispatch, getState, services) => {
    const UserService = services.get("UserService")
    return UserService.fetchAdminUserRoles()
        .then((res) => {
            return res.data
        }).catch((err) => console.log({ err }))
}

export const fetchUserRoles = () => (dispatch, getState, services) => {
    const UserService = services.get("UserService")
    return UserService.fetchUserRoles()
        .then((res) => {
            return res.data
        }).catch((err) => console.log({ err }))
}

export const updateUserRole = (params) => (dispatch, getState, services) => {
    const UserService = services.get("UserService")
    return UserService.updateUserRole(params)
        .then((res) => {
            return res.data
        }).catch((err) => console.log({ err }))
}

export const verifyPromoCode = (params) => (dispatch, getState, services) => {
    let promoParams = {
        promo_code: params
    }
    const UserService = services.get("UserService")
    return UserService.promoCodeValidator(promoParams)
        .then((res) => {
            return res;
        }).catch((err) => console.log({ err }))
}

export const editGroupSubPlans = () => (dispatch, getState, services) => {
    const UserService = services.get("UserService")
    return UserService.getEditGroupSubPlans()
        .then((res) => {
            return res.data
        }).catch((err) => console.log({ err }))
}

export const fetchThinkificToken = () => (dispatch, getState, services) => {
    const UserService = services.get("UserService")
    return UserService.getThinkificToken()
        .then((res) => {
            return res.data
        }).catch((err) => console.log({ err }))
}

export const fetchUserFollowers = (user_id) => (dispatch, getState, services) => {
    const UserService = services.get("UserService")
    return UserService.getUserFollowers(user_id)
        .then((res) => {
            return res
        }).catch((err) => console.log({ err }))
}

export const fetchUserFollowing = (user_id) => (dispatch, getState, services) => {
    const UserService = services.get("UserService")
    return UserService.getUserFollowing(user_id)
        .then((res) => {
            return res 
        }).catch((err) => console.log({ err }))
    
}