import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

//Routes
import Routes from './routes'

//Redux Store
import configureStore from './utils/store'

//Services
import { register as registerServices } from './services/register';

// 404
import PageNotFound from './screens/404';

//Layout 
import Body from './components/shared/Body'

//App container
import AppContainer from './components/containers/AppContainer'

function App() {
    const store = configureStore(registerServices());


    return (
        <Provider store={store}>
            <BrowserRouter>
                <Body>
                    <Switch>
                        {
                            Routes.map(({ path, component, exact, shared, practice }) => shared ?
                                <Route key={path} path={path} exact={exact} component={component} practice={practice}></Route>
                                : <PrivateRoute key={path} path={path} exact={exact} component={component} practice={practice} />
                            ) 
                        }
                        <Route children={PageNotFound}></Route>
                    </Switch>
                </Body>
            </BrowserRouter>
        </Provider>
    );
}


//function to check if the user is authenticated --- runs everytime route is switched
const isAuthenticated = () => {
    const token = localStorage.getItem('twp-token')
    return token ? true : false
}

   

//Use for routes that is login protected
const PrivateRoute = ({ practice: practice, component: Component, ...rest }) => (
    practice !== true ? (
        <Route {...rest} render={(props) => (
            isAuthenticated() 
                ? (
                    <>
                        <AppContainer/>
                        <Component {...props} />
                    </>
                    
                ) : <Redirect to='/auth/login' />
        )} />
    ): (
        <Route {...rest} render={(props) => (
                <>
                    <AppContainer practice={true}/>
                    <Component {...props} />
                </>
    )} />
    )
    
)


export default App;
