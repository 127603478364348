import React, { useState } from "react";
import { Menu, Dropdown, Progress, List, Divider, Icon, Row, Col, Typography, Tooltip } from "antd";
import { unionBy } from "lodash";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { invokeFetchAssignments } from "../../../actions/app";

const { Text } = Typography;

interface AssignmentContainerProps {
  invokeFetchAssignments: () => any;
  assignments: any;
}

function AssignmentContainer(props: AssignmentContainerProps) {
  const [groups, setGroups] = useState<any>([]);
  const [assignments, setAssignments] = useState<any>([]);
  const [completed, setCompleted] = useState<any>([]);
  const [incompleted, setInCompleted] = useState<any>([]);
  const [assignmentsInPosts, setAssignmentsInPosts] = useState(0);

  React.useEffect(() => {
    props.invokeFetchAssignments();
  }, []);

  React.useEffect(() => {
    let as = [];
    const grps =
      props.assignments && props.assignments.length > 0
        ? props.assignments.map((a) => {
            const ss = a.Assignments || [];

            as = unionBy(as, ss, "group_assignment_type");

            return {
              assignments: ss,
              ...a,
            };
          })
        : [];

    const com = as.filter((a: any) => a.completed == "true");
    const inCom = as.filter((a: any) => a.completed == "false");
    
    setInCompleted(inCom);
    setCompleted(com);
    setAssignments(as);
    setGroups(grps);
  }, [props.assignments]);


  React.useEffect(() => {
    let falsePost = groups.filter(
      (a: any) => a.assignment_completed == "false" && a.Assignments.length > 0
    );

    let c = 0;
    const mainPost =
      falsePost && falsePost.length > 0
        ? falsePost.map((a) => {
            a.Assignments.map((x) => {
              if (a) {
                c++;
              }
              setAssignmentsInPosts(c);
              return x;
            });
          })
        : [];
  }, [groups]);

  return assignments && assignments.length > 0 ? (
    <Dropdown
      overlay={
        <Menu className="header-dropdown">
          <div className="assignment-header">
            <Row>
              <Col span={12}>
                <h2>Assignments</h2>
              </Col>
            </Row>
            <Row>
              <span>
                Complete your assignment to unlock critiques on your writing
                pieces
              </span>
            </Row>
            <Row>
              <a className="see-all-assignments" href={`/assignments`}>
                See All Assignments
            <Divider />
              </a>
            </Row>
          </div>
          {groups.map((d, index) =>
            d.group_assignment_type != "none" ? (
              <div className="twp-assignemnts" key={d.post_id}>
                {d.assignment_completed == "false" ? (
                  <List
                    key={index + "_assignments"}
                    id={`assignment_list_${d.post_id}`}
                    header={
                      <div>
                        <h3 className="imp">
                          <a className="link-a" href={d.group_category === "Practice" ? `/practice/post/${d.post_id}` : `/post/${d.post_id}`}>    
                          {d.title}                       
                          </a>
                        </h3>
                        <label>
                          <Link to={d.group_category === "Practice" ? `/practice/${d.group_id}` : `/group/${d.group_id}`}>
                            {d.group_name}
                          </Link>
                        </label>
                        <br/>{d.group_category === "Practice"? <Text style={{fontSize: 12}}  mark> PRACTICE</Text>: null} 

                        <br />
                        <label>
                          {d.group_assignment_type == "Free Choice" ? (
                            <>
                             <Text mark>Free Choice</Text>
                             <br/>
                             <Text style={{fontSize: 12}} disabled>Critique on 3 Writing Pieces in the Group to unlock critiques on your own Writing Pieces</Text>
                            </>
                            ): null}
                          </label>
                      </div>
                    }
                    dataSource={d.assignments}
                    renderItem={(a: any, index) =>
                      a.name && a.assignment_post_id ? (
                        <List.Item key={index + "_assignments"}>
                          <div
                            className={a.completed == "true" ? "active" : ""}
                          >
                            {a.completed == "true" ? (
                              <Icon type="check-circle" />
                            ) : null}
                            <a
                              className="link-a"
                              href={d.group_category === "Practice" ? `/practice/post/${a.assignment_post_id}` : `/post/${a.assignment_post_id}`}
                            >
                              {a.title}
                            </a>{" "}
                            by <a className="link-a">{a.name}</a> in{" "}
                            <a className="link-a" href={d.group_category === "Practice" ? `/practice/${a.assignment_post_id}` : `/post/${a.assignment_post_id}`}>
                              {a.group_name}
                            </a>
                          </div>
                        </List.Item>
                      ) : (
                        <></>
                      )
                    }
                  >
                    <Divider />
                  </List>
                ) : null}
              </div>
            ) : null
          )}
        </Menu>
      }
      trigger={["click"]}
      placement="bottomLeft"
    >
      <div className="assignment-btn">
        {/* {grpIncomplete == "false" ? ( */}
        <>
        <Tooltip title="Assignments">
          <Progress
            type="circle"
            strokeColor={{
              "0%": "#3CB371",
              "100%": "#87d068",
            }}
            strokeWidth={15}
            percent={100 / (assignmentsInPosts / incompleted.length)}
            width={22}
          />
          <label>
            {incompleted.length}/{assignmentsInPosts}
          </label>
          </Tooltip>
        </>
        {/* ): null} */}
      </div>
    </Dropdown>
  ) : null;
}

const Actions = {
  invokeFetchAssignments,
};

const mapStP = (state: any) => ({
  assignments: state.app.assignments,
});

export default connect(mapStP, Actions)(AssignmentContainer);
