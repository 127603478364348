import React from 'react'
import { Tabs, Icon, Row } from 'antd'
import { useHistory } from 'react-router-dom'
import {Show, Hide} from './Responsive'
interface Props {
    children: any,
    active: string
}

const { TabPane } = Tabs;

const links = [
    {
        name: "Profile",
        slug: "profile",
        icon: "user"
    },
    {
        name: "Your Activity",
        slug: "activity-feed",
        icon: 'history'
    },
    {
        name: "Assignments",
        slug: "assignments",
        icon: 'carry-out'
    },
    {
        name: "Settings",
        slug: "settings",
        icon: 'setting'
    },
    {
        name: 'Subscriptions',
        slug: "user-subscriptions",
        icon: 'dollar' 
    },
    {
        name: 'Notifications',
        slug: "notifications",
        icon: 'bell' 
    }
]

const userRoleLinks =  [
    {
        name: "Profile",
        slug: "profile",
        icon: "user"
    },
    {
        name: "Activity Feed",
        slug: "activity-feed",
        icon: 'history'
    },
    {
        name: "Assignments",
        slug: "assignments",
        icon: 'carry-out'
    },
    {
        name: "Settings",
        slug: "settings",
        icon: 'setting'
    },
    {
        name: 'Subscriptions',
        slug: "user-subscriptions",
        icon: 'dollar' 
    },
    {
        name: 'Notifications',
        slug: "notifications",
        icon: 'bell' 
    },
    // {
    //     name: 'Add New Group',
    //     slug: "new-group",
    //     icon: 'profile' 
    // },
    {
        name: 'Groups',
        slug: "groups",
        icon: 'profile' 
    },
    {
        name: 'Courses',
        slug: "courses",
        icon: 'read' 
    },
    {
        name: 'Team',
        slug: "team",
        icon: 'team' 
    },
    {
        name: 'Active Subscriptions',
        slug: "active-subscription",
        icon: 'team'
    }
]

function SettingsBody(props: Props) {
    const [active, setActive] = React.useState(props.active)
    const history = useHistory();
    const userRole = localStorage.getItem("twp-user-role");

    function handleChange(key: string) {
        setActive(key)
        //setTimeout(() => history.push('/' + key), 300)
        history.push('/' + key)
    }

    return (
        <>
            <Hide in="xs">
                <div className="twp-wrapper inner">
                    <Row className="bg-primary">
                        <div className="settings-tab-wrapper">
                                 { userRole === "admin" ? (
                                     <Tabs defaultActiveKey="1" tabPosition="left" onChange={handleChange} activeKey={active} className="config-tab" size="large">
                                     {userRoleLinks.map((v, i) => (
                                         <TabPane className="config-tab" tab={
                                             <Row>
                                                 <Icon type={v.icon} /> {v.name}
                                             </Row>
                                         } key={`${v.slug}`}>
                                             <div className="settings-tab-body" style={{ marginLeft: 0}}>
                                                 {props.children}
                                             </div>
                                         </TabPane>
                                     ))}
                                 </Tabs>
                                 ): (
                                    <Tabs defaultActiveKey="1" tabPosition="left" onChange={handleChange} activeKey={active} className="config-tab" size="large">
                                    {links.map((v, i) => (
                                        <TabPane className="config-tab" tab={
                                            <Row>
                                                <Icon type={v.icon} /> {v.name}
                                            </Row>
                                        } key={`${v.slug}`}>
                                            <div className="settings-tab-body" style={{ marginLeft: 0}}>
                                                {props.children}
                                            </div>
                                        </TabPane>
                                    ))}
                                </Tabs>
                                 )

                            }
                            
                        </div>
                    </Row>
                </div>
            </Hide>
            <Show in="xs">
                <div className="bg-primary container">
                    {props.children}
                </div>
            </Show>
        </>
    )
}

export default SettingsBody;
