
import {
    AUTH_SIGN_UP,
    AUTH_SIGN_UP_SUCCESS
} from "./_types";

const authSignUpInvoke = () => ({type: AUTH_SIGN_UP});
const authSignUpInvokeSuccess = user => ({type: AUTH_SIGN_UP_SUCCESS});


export const signIn = (creds) => (dispatch, getState, services) => {
    let auth_service = services.get("AuthService");
    return auth_service
    .login(creds)
    .then(res => res)
    .catch((err) => console.log({ err }))
}

export const signUp = (user) => (dispatch, getState, services) => {
    let auth_service = services.get("AuthService");
    return auth_service
    .signUp(user)
    .then((res) => {
        dispatch(authSignUpInvoke(user))
        return res
    })
    .catch((err) => console.log({ err }))
}

export const verifyEmail = (token) => (dispatch, getState, services) =>  {
    const service = services.get("AuthService");
    return service
    .verifyEmail(token)
    .then((res) => {
        return res
    })
    .catch((err) => console.log({ err }))
}

export const forgotPassword = (email) => (dispatch, getState, services) =>  {
    const service = services.get("AuthService");
    return service.forgotPassword(email);
}

export const resetPassword = (obj) => (dispatch, getState, services) =>  {
    const service = services.get("AuthService");
    return service.resetPassword(obj);
}

export const changePassword = (passwords) => (dispatch, getState, services) =>  {
    const params = {
        old_password: passwords.old_password,
        new_password: passwords.new_password,
        token: localStorage.getItem('twp-token')
    }
    const service = services.get("AuthService");
    return service.changePassword(params)
    .then((res) => {
        return res;
    })
    .catch((err) => console.log({ err }))
}

export const practiceSignUp = (user) => (dispatch, getState, services) => {
    let auth_service = services.get("AuthService");
    return auth_service
    .practiceSignUp(user)
    .then(res => res)
    .catch((err) => console.log({ err }))
}

