import React from "react";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

const initPost = {
  post: "",
  summary: "",
};

interface IPost {
  // id?: string;
  post: string;
}
interface Props {
  onChange: ({}: IPost) => void;
  post: IPost;
}

function MessageEditor(props: Props) {
  const { post } = props;
  const [editor, setEditor] = React.useState(EditorState.createEmpty());
  const [obj, setObj] = React.useState<IPost>(post || initPost);

  React.useEffect(() => {
    const text = convertToRaw(editor.getCurrentContent());
    const html = draftToHtml(text);

    //checks length
    const s = text.blocks[0]
      ? text.blocks[0].text.split(" ").splice(0, 40).join(" ").toString()
      : "";
    const t = text.blocks.reduce((acc, r) => `${r.text} ${acc}`, "");
    const f = t.trim().split(/\s+/).length;

    setObj({
      ...obj,
      post: html,
    });
  }, [editor]);

  React.useEffect(() => {
    props.onChange(obj);
  }, [obj]);

  React.useEffect(() => {
    props.onChange(obj);
  }, [post]);

  return (
    <div className="mini-create-message">
      <Editor
        handlePastedText={() => false}
        editorState={editor}
        onEditorStateChange={setEditor}
        toolbar={{
          options: ["inline", "link", "list"],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          list: {
            // className: "post-container",
            options: ["unordered", "ordered"],
          },
        }}
        wrapperClassName="twp-editor-wrapper"
        editorClassName="twp-editor"
        placeholder="Write your message here"
      />
    </div>
  );
}

export default MessageEditor;
