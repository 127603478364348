import { FETCH_ASSIGNMENTS } from "../actions/_types";

const initial_state = {
    notifications: [],
    assignments: []
}

const setAssignments = (state, assignments) => {
    return {
        ...state,
        assignments: assignments
    }
};

const appReducers = (state = initial_state, action) => {
    switch (action.type) {
        case FETCH_ASSIGNMENTS:
            return setAssignments(state, action.payload);
        default:
            return state;
    }
};

export default appReducers;