import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Col, Row, Input, Avatar, Button, Icon, notification } from "antd";
import { updateUserProfile } from "../../../../actions/user";
import ImageUpload from "../../../snippets/ImageUpload/ImageUpload";

const { TextArea } = Input;

interface Bio {
  badges: any;
  bio: string;
  books_published: any;
  comments_count: number;
  email: string;
  first_name: string;
  genre: any;
  id: number;
  member_since: string;
  last_name: string;
  posts_count: number;
  profile_pic: any;
  subscription_plan: string;
  user_groups: any;
  user_role: string;
  writing_goal: any;
  website: string;
}

interface Props {
  bio: Bio;
  handleClose: () => any;
  updateUserProfile: (params: any) => any;
  uploadProfilePicture: (params: any) => any;
  form: any;
  loading: boolean;
}

function _Form(props: Props) {
  const { bio } = props;
  const [imgurl, setImgurl] = useState(bio.profile_pic);
  const [iconUploaded, setIconUploaded] = useState(false);
  const [words, setWords] = React.useState(0);
  const [errors, setErrors] = useState<Array<string>>([]);

  //Profile successful update notification
  const openNotification = () => {
    notification.open({
      message: "Success",
      description: "Profile Updated!",
      icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
    });
  };

  // const bioNotification = () => {
  //   notification.open({
  //     message: "Error",
  //     description: "Bio is too long!",
  //     icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
  //   });
  // };


  function handler(e) {
    props.form.validateFields((err: any, vals: any) => {
      if (!err) {
        //Removes any quotes added to prevent api breaking
        let removeQuotes = vals.books_published.replace(/['"]+/g, "");

        const params = {
          ...vals,
          books_published: removeQuotes,
          profile_pic: imgurl,
        };

        const isvalid = validate();

        if (isvalid) {
          const submit = props.updateUserProfile(params);

          openNotification();
          setTimeout(function () {
            window.location.reload();
          }, 1000);

        }
      }
    });
  }

  const validate = () => {
    setErrors([]); //Reset validation to validate again.

    let errs: Array<string> = [];
    if (words > 500) {
      errs.push(
        "Your bio is too long. You cannot post anymore than " + 500 + " words"
      );
    }
    setErrors(errs);

    // Return validation
    if (errs.length < 1) return true;

    return false;
  }

  React.useEffect(() => {
    setWords(bio.bio.length);
  }, [bio.bio]);

  const { getFieldDecorator } = props.form;

  return (
    <Form layout="vertical">
      <Row gutter={16} type="flex" align="middle" justify="center">
        <Avatar size={120} src={imgurl} />
      </Row>
      <Row
        className="inner"
        gutter={16}
        type="flex"
        align="middle"
        justify="center"
      >
        <div style={{ marginLeft: 12 }}>
          <ImageUpload
            id="profile_pic"
            folder="images"
            height={800}
            width={800}
            onFileUpload={(imageUrl) => {
              setImgurl(imageUrl);
              setIconUploaded(true)
            }}
          />
        </div>
      </Row>
      <Row gutter={8}>
        <Form.Item label="First Name" htmlFor="first_name">
          {getFieldDecorator("first_name", {
            initialValue: bio.first_name,
            rules: [
              {
                required: true,
                message: "Can't leave this empty",
              },
            ],
          })(<Input />)}
        </Form.Item>
      </Row>
      <Row gutter={8}>
        <Form.Item label="Last Name" htmlFor="last_name">
          {getFieldDecorator("last_name", {
            initialValue: bio.last_name,
            rules: [
              {
                required: true,
                message: "Can't leave this empty",
              },
            ],
          })(<Input />)}
        </Form.Item>
      </Row>
      <Form.Item label="Bio" htmlFor="bio">
        {getFieldDecorator("bio", {
          initialValue: bio.bio,
        })(<TextArea onChange={(e) => setWords(e.target.value.length)} rows={5}></TextArea>
        )}
        <div>
          {words <= 500 ? (
            <span>
              {words} / {500}
            </span>
          ) : (
            <span style={{ color: "red" }}>
              {words} / {500}
            </span>
          )}
        </div>
      </Form.Item>
      <Form.Item label="Writing Goal" htmlFor="writing_goal">
        {getFieldDecorator("writing_goal", {
          initialValue: bio.writing_goal,
        })(<TextArea rows={5}></TextArea>)}
      </Form.Item>
      <Form.Item label="Books Published" htmlFor="books_published">
        {getFieldDecorator("books_published", {
          initialValue: bio.books_published,
        })(<TextArea rows={5}></TextArea>)}
      </Form.Item>
      <Form.Item label="Website" htmlFor="website">
        {getFieldDecorator("website", {
          initialValue: bio.website,
        })(<Input />)}
      </Form.Item>
      {errors.length > 0 ? (
        <Row className="inner">
          {errors.map((err, i) => (
            <div key={`err_${i}`} style={{ color: "red" }}>
              <b>* {err}</b>
            </div>
          ))}
        </Row>
      ) : null}
      <Row gutter={8} type="flex">
        <Col>
          <Button onClick={handler} type="primary">
            Confirm changes
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

const EditProfileContainer = Form.create({ name: "edit_profile" })(_Form);

const mapStoP = (state: any) => ({
  bio: state.user.bio,
  loading: state.groups.loading,
});

const Actions = {
  updateUserProfile,
};

export default connect(mapStoP, Actions)(EditProfileContainer);
