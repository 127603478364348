import React, { useState } from "react";
import { connect } from "react-redux";
import {
    Row,
    Col,
    Modal,
    Form,
    Select,
    Button,
    Input,
    Descriptions,
    Card,
    Tag,
    Table,
    Typography
} from "antd";

import SettingsBody from "../../../shared/SettingsBody";
import { checkActiveSubscription } from "../../../../actions/app";


interface TeamProps {
    checkActiveSubscription: (id: any) => any;
    form: any;
}

const inputRule = [
    { required: true, message: "Please input the user email" },
];

const { Text } = Typography

//Table headers
const columns = [
    {
        title: 'Course',
        dataIndex: 'course',
        key: 'course',
        render: text => <a>{text}</a>,
    },
    {
        title: 'Username',
        dataIndex: 'user',
        key: 'user',
    },
    {
        title: 'User ID',
        dataIndex: 'user_id',
        key: 'user_id',
    },
    {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
    },
    {
        title: 'Expiration Date',
        dataIndex: 'expiration_date',
        key: 'expiration_date',
    }
];


function ActiveSubscriptionContainer(props: TeamProps) {
    const [loading, toggleLoading] = useState(false);
    const [userSubscription, setUserSubscription] = useState({
        user_subscriptions: [{
            created_at: "",
            expiration_date: 0,
            plan: "",
            user: "",
            user_id: ""
        }],
        course_subscriptions: [{
            course: "",
            expiration_date: 0,
            created_at: "",
            user: "",
            user_id: ""
        }],

    })

    const onSignIn = async () => {
        props.form.validateFields(async (err: any, vals: any) => {
            if (!err) {
                toggleLoading(true);
                const subs = await props.checkActiveSubscription(vals.email);
                if (subs) {
                    setUserSubscription(subs);
                }
            }
        })
    }


    const { getFieldDecorator } = props.form;

    return (
        <SettingsBody active="active-subscription">
            <div className="twp-wrapper">
                <Row>
                    <Text strong>Enter user email to get course/user information</Text>
                </Row>
                <br />
                <Row>
                    <Form
                        name="basic"
                    >
                        <Row>
                            <div style={{ display: "flex", alignContent: "center" }}>
                                <Col span={10}>
                                    <Form.Item>
                                        {getFieldDecorator("email", {
                                            rules: inputRule,
                                        })(
                                            <Input
                                                size="small"
                                                style={{ width: 300, height: 40 }}
                                                placeholder="User Email"
                                                id="email"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Button
                                        type="primary"
                                        className="btn-c1"
                                        size="large"
                                        onClick={onSignIn}
                                    >
                                        Search
                                    </Button>
                                </Col>
                            </div>
                        </Row>
                    </Form>
                    {userSubscription.user_subscriptions[0].user_id !== "" ?
                        <>
                            <Row>
                                <Descriptions title="User Info">
                                    <Descriptions.Item label="User ID ">{userSubscription.user_subscriptions[0].user_id}</Descriptions.Item>
                                    <Descriptions.Item label="Username">{userSubscription.user_subscriptions[0].user}</Descriptions.Item>
                                    <Descriptions.Item label="Plan">{userSubscription.user_subscriptions[0].plan}</Descriptions.Item>
                                    <Descriptions.Item label="Expiration Date">{userSubscription.user_subscriptions[0].expiration_date}</Descriptions.Item>
                                    <Descriptions.Item label="Created At">
                                        {userSubscription.user_subscriptions[0].created_at}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Row>
                            <Row>
                                <Table columns={columns} dataSource={userSubscription.course_subscriptions} />
                            </Row>
                        </>
                        : null}
                </Row>
            </div>
        </SettingsBody>
    );
}

const Actions = {
    checkActiveSubscription
};


const _Form = connect(null, Actions)(ActiveSubscriptionContainer);

export default Form.create({ name: "activeSubscription" })(_Form);
