import { FETCH_ASSIGNMENTS, USER_SET_PROFILE_GROUPS, USER_SET_PROFILE_BIO, FETCH_GROUP_FAILED } from "./_types";

const saveAssignments = (assignments) => ({ type: FETCH_ASSIGNMENTS, payload: assignments });
const setUserGroups = (grps) => ({ type: USER_SET_PROFILE_GROUPS, payload: grps });
const setUserBio = (bio) => ({ type: USER_SET_PROFILE_BIO, payload: bio });
const fetchUserFollowFailed = (err) => ({ type: FETCH_GROUP_FAILED, err });

export const invokeFetchAssignments = () => (dispatch, getState, services) => {
  let app_services = services.get("AppService");
  app_services
    .fetchAssignments()
    .then((res) => {
      dispatch(saveAssignments(res.data && res.data.length > 0 ? res.data : []))
    })
};

export const fetchAllUserProfile = (id) => (dispatch, getState, services) => {
  const app_services = services.get("AppService")
  return app_services.getUserProfileDetails(id)
  .then((res) => {
    return res.data[0]
    // const bio = res.data[0]
    // const groups = bio.user_groups ? bio.user_groups : [];
    // dispatch(setUserBio(bio));
    // // localStorage.setItem('twp-user-id', parseInt(bio.id));

    // if (groups && groups.length > 0) {
    //   dispatch(setUserGroups(groups));
    // }
  }).catch((err) => console.log({ err }))
}

export const followUserProfile = (id) => (dispatch, getState, services) => {
  const user = {
    follower_id: id,
    token: localStorage.getItem("twp-token"),
  }
  const app_services = services.get("AppService")
  return app_services.followUserProfile(user).catch((err) => {
    dispatch(fetchUserFollowFailed(err))
  })
}

export const clearAssignments = (id) => (dispatch, getState, services) => {
  let param = {
    post_id: id
  }
  const app_services = services.get("AppService");
  return app_services.removeAssignments(param)
    .then((res) => {
      return res;
    })
    .catch((err) => console.log({ err }))
}

export const siteWalkThrough = (params) => (dispatch, getState, services) => {
  const app_services = services.get("AppService");
  app_services.updateTourStatus(params)
  .then((res) => {
    // return res
  })
  .catch((err) => console.log({err}))
}

export const checkActiveSubscription = (email) => (dispatch, getState, services) => {
  const app_services = services.get("AppService");
  return app_services.checkActiveSubscription(email)
  .then((res) => {
    return res.data
  })
  .catch((err) => console.log({err}))
}