import React from 'react'
import GroupListingContainer from '../components/containers/Settings/Groups/GroupListingContainer'
import WithSidebar from '../components/shared/WithSidebar'

const GroupListing = () => {
    return (
            <GroupListingContainer />
    )
};

export default GroupListing;