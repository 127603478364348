import React, { useState } from "react";
import {
  Card,
  Divider,
  Icon,
  Button,
  Row,
  notification,
} from "antd";
import { requestFreeSubscription } from "../../../actions/subscription";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

interface Props {
  list: Array<List>;
  title: string;
  price: string;
  btnText?: string;
  priceSuffix?: string;
  packageSuffix?: string;
  requestFreeSubscription: (charge_id: any) => any;
  subParams: (responseBody: SubDetails) => any;
}

interface SubDetails {
  first_name: string;
  last_name: string;
  email: string;
}

interface List {
  text: string;
  ticked: boolean;
}

function PricingCard(props: Props) {
  const { title, list, price, priceSuffix, packageSuffix } = props;
  const history = useHistory();

  const [subscriptionParams, setSubParams] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  React.useEffect(() => {
    setSubParams(props.subParams);
  }, []);

  const checkhundredDay = (evet) => {
    if (document.getElementById("hundredDay")) {
      window.location.href = `https://thewritepractice.samcart.com/products/100-day-book/?first_name=${subscriptionParams.first_name}&last_name=${subscriptionParams.last_name}&email=${subscriptionParams.email}`
    }
  }
  const checktwpBasic = (evet) => {
    if (document.getElementById("twpBasic")) {
      window.location.href = `https://thewritepractice.samcart.com/products/becoming-writer-monthly/?first_name=${subscriptionParams.first_name}&last_name=${subscriptionParams.last_name}&email=${subscriptionParams.email}`;
    }
  }
  const checktwpPremium = (evet) => {
    if (document.getElementById("twpPremium")) {
      window.location.href = `https://thewritepractice.samcart.com/products/becoming-writer-premium-monthly/?first_name=${subscriptionParams.first_name}&last_name=${subscriptionParams.last_name}&email=${subscriptionParams.email}`;
    }
  }

  
  const freeSubscribe = (event) => {
    props.requestFreeSubscription(1).then((res) => {
      if (res && res.success === true) {
        openNotification();
        history.push("/");
      }
    });
  }

  const openNotification = () => {
    notification.open({
      message: "Subscribed to a Free Account",
      icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
    });
  };

  return (
    <div className="steps-content">
      {/* {current == 0 ? ( */}
      <div className="twp-wrapper inner">
        <Card title={title} bordered={false} className="sub-package-card">
          <h2 className="subs-package-title">
            {price}
            <span className="subs-package-title-month">{priceSuffix}</span>
          </h2>
          <Divider />
          {packageSuffix ? (
            <div className="pckg-suffix-padding">
              <span className="pckg-suffix">{packageSuffix}</span>
            </div>
          ) : null}
          <Row className="sub-package-details">
            {list &&
              list.map((l, i) => (
                <li key={"price-list_" + title + "_" + i}>
                  <Icon type={l.ticked ? "check" : "close"} /> {l.text}
                </li>
              ))}
          </Row>
          <Divider />
          <Row type="flex" justify="center">
            {title === "100 Day Book" ? (
              <Button
                id="hundredDay"
                onClick={checkhundredDay}
                type="primary"
                className="btn-c1"
                size="large"
              // disabled
              >
                Subscribe
              </Button>
            ) : title === "The Write Practice Pro Basic" ? (
              <Button
                id="twpBasic"
                onClick={checktwpBasic}
                type="primary"
                className="btn-c1"
                size="large"
              // disabled
              >
                Subscribe
              </Button>
            ) : title === "The Write Practice Pro Premium" ? (
              <Button
                id="twpPremium"
                type="primary"
                className="btn-c1"
                size="large"
                onClick={checktwpPremium}
              >
                Subscribe
              </Button>
            ) : null}
          </Row>
        </Card>
      </div>
    </div>
  );
}

const Actions = {
  requestFreeSubscription,
};
export default connect(null, Actions)(PricingCard);
