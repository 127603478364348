import React from 'react'

function NetworkDetector() {
    const [online, toggleNetwork] = React.useState(navigator.onLine);

    React.useEffect(() => {
        window.addEventListener('offline', () => {
            toggleNetwork(false)
        }); 

        window.addEventListener('online', () => {
            toggleNetwork(true)
        });
    }, []);

    return !online ? (
        <div className="error-collapse" >
            <p>Network Connection Lost: You need internet connection for this app to work</p>
        </div>
    ) : null
}

export default NetworkDetector
