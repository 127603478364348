import {
    ADD_CRITIQUE,
    DELETE_CRITIQUE,
    UPDATE_CRITIQUE,
    FETCH_CRITIQUES
} from "./_types";

const setCritiquesFromPost = critiques => ({ type: FETCH_CRITIQUES, payload: critiques });

const addToCritiques = critique => ({ type: ADD_CRITIQUE, payload: critique })

const updateCritique = critique => ({ type: UPDATE_CRITIQUE, payload: critique })

const critiqueDelete = critique_id => ({ type: DELETE_CRITIQUE, payload: critique_id });

export const requestAddCritique = (critique) => (dispatch, getState, services) => {
    let group_service = services.get('CritiqueService');
    return group_service.addCritique(critique)
        .then((res) => {
            const critique = {
                ...res.data[0],
                user: res.data[0].user
            }
            dispatch(addToCritiques(critique))
        })
        .catch((err) => console.log({ err }))
}

export const fetchCritiques = post_id => (dispatch, getState, services) => {
    let critique_service = services.get("CritiqueService")
    critique_service.getCritique(post_id)
        .then(res => {
            const critique = res.data.map(c => ({
                ...c,
                user: c.user
            }));
            dispatch(setCritiquesFromPost(critique));
        })
        .catch(err => console.log({ err }))
}

export const requestUpdateCritique = (critique) => (dispatch, getState, services) => {
    let critique_service = services.get('CritiqueService');
    critique_service.updateCritique(critique)
        .then((res) => {
            dispatch(updateCritique(critique))
        })
        .catch((err) => console.log({ err }))
}

export const requestDeleteCritique = (id) => (dispatch, getState, services) => {
    let critique_service = services.get('CritiqueService');
    critique_service.deleteCritique(id)
        .then((res) => {
            dispatch(critiqueDelete(id))
        })
        .catch((err) => console.log({ err }))
}
