import React from "react";
import { Row, Typography, Icon, Col, List, Avatar, Button, Spin } from "antd";

const { Title, Text } = Typography;
const { Item } = List;

function CourseBlock(props: any) {
  const link = `/course/${props.id}`;

  return props.loading ? (
    <div className="loading-spin">
      <Spin />
    </div>
  ) : (
    <div  className="post-block inner" key={props.index + "_post"}>
      <a href={link}>
      <Row className="group-inner">
        <Col span={14}>
          <a href={link}><Text strong>{props.title}</Text></a>
        </Col>
        <Col>
          <span>{props.period}</span>
        </Col>
      </Row>
      </a>
    </div>
  );
}

export default CourseBlock;
