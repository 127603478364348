export default class SubscriptionService {
    
    /**
     * @param {ApiService} apiService
     */
    constructor(apiService) {
        this.api = apiService;
    }

    // addSubscription(subscription) {
    //     return this.api.post(`/add-subscription-plan`, subscription)
    // }

    changeSubscription(subscription) {
        return this.api.put(`update-user-subscription`, subscription)
    }

    deleteSubscription(id) {
        return this.api.delete('delete-subscription-plan', { subscription_id: id})
    }

    addFreeSubscriptionPlan(subscription) {
        return this.api.post('/user/add-subscription', subscription)

    }

}
