import React from 'react'
import { Row, Typography, Col, Icon } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { formatTime } from '../../../utils/helpers'
import PracticeAvatar from '../Avatars/PracticeAvatar'
import { partition } from "lodash";

const { Text } = Typography
const MAX_LENGTH = 40

function ArticleBlock(props: any) {

    var userObj = props.user;
    const link = `/practice/${props.group_id}`

    const [nested, main] = partition(props.comments, (o) => o.parent_id);

    return (
        <div className="post-block" key={props.index + "_post"}>
                <>
                    <Row>
                        <Link to={link}><h2 className="post-title">{props.group_name}</h2></Link>
                        <div className="post-description">
                            {props.description ? (
                                <div>
                                    <Link to={link}><p className=""><p>{props.description}</p></p></Link>
                                </div>
                            ): null}
                        </div>
                    </Row>
                    <Row type="flex" align="bottom" justify="space-between" className="action-row">
                        {props.user ? (
                            <Col>
                                <PracticeAvatar
                                    title={props.user.name}
                                    subtitle={""}
                                    img={props.user.profile_pic}
                                    size={36}
                                    userRole={props.user.user_role}
                                />
                            </Col>
                        ) : (
                            <Col>
                                {/* <span>{date}</span> */}
                            </Col>
                        )}
                        <Col>
                            <a href={link} className="feed-critique-font-type">{props.post_count} practices</a>
                            {/* <Link to={link}>{main.length} Critiques {nested.length} Replies</Link> */}
                        </Col>
                    </Row>
                </>
        </div>
    );
}

export default ArticleBlock
