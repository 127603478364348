import { FETCH_NOTIFICATIONS } from "../actions/_types";

const initial_state = {
    notifications: []
}

const setNotifications = (state, notifications) => {
    return {
        ...state,
        notifications: notifications
    }
};

const appReducers = (state = initial_state, action) => {
    switch(action.type) {
        case FETCH_NOTIFICATIONS:
            return setNotifications(state, action.payload);
        default:
            return state;
    }
}

export default appReducers;