
import {combineReducers} from "redux";
import groupReducers from './groups'
import postReducers from './posts';
import userReducers from './user';
import critiqueReducers from './critiques';
import appReducers from './app';
import notificationReducers from './notification';
import courseReducer from './courses';
import subscriptionReducer from './subscription';
import practiceReduser from './practice';
import articleReducer from './articles';
import practiceCritiqueReducer from './practiceCritiques'

const appReducer = combineReducers({
    // singlegroups: groupReducers,
    groups: groupReducers,
    posts: postReducers,
    user: userReducers,
    critiques: critiqueReducers,
    app: appReducers,
    nots: notificationReducers,
    courses: courseReducer,
    subscription: subscriptionReducer,
    articles: articleReducer,
    practices: practiceReduser,
    practiceComments: practiceCritiqueReducer

});

export default (state, action) => appReducer(state, action);