export default class UserService {

    /**
     * @param {ApiService} apiService
     */
    constructor(apiService) {
        this.api = apiService;
    }

    getUserProfile() {
        return this.api.get(`user/profile`);
    }
    getUserPosts(id,offset,limit) {
        return this.api.get(`posts/user/${id}?offset=${offset}&limit=${limit}`);
    }
    updateUserProfile(bio) {
        return this.api.put(`user/edit-profile`, bio);
    }
    updateSubscription(subscription) {
        return this.api.put('user/update-subscription', subscription);
    }
    getSubscriptions() {
        return this.api.get('subscription-plans')
    }
    getUserSubscription(){
        return this.api.get('user/account')
    }
    uploadImage(obj){
        return this.api.post('user/upload-profile-pic', obj)
    }
    fetchSubscriptionPeriods(){
        return this.api.get('subscription-periods')
    }
    fetchUserRoles(){
        return this.api.get('user-roles')
    }
    fetchAdminUserRoles(){
        return this.api.get('user/admins')
    }
    updateUserRole(obj) {
        return this.api.post('user/update-user-role', obj);
    }
    promoCodeValidator(code) {
        return this.api.post('validate-promocode', code);
    }
    getEditGroupSubPlans() {
        return this.api.get('plans')
    }
    getThinkificToken() {
        return this.api.get('my-courses')
    }
    getUserFollowers(user_id) {
        return  this.api.get(`user/followers/${user_id}`)
    }
    getUserFollowing(user_id) {
        return  this.api.get(`user/following/${user_id}`)
    }
}
