import React from 'react'
import {Skeleton} from 'antd'
import {Hide} from '../../../components/shared/Responsive'

interface Props {
    count: number
}

function Listing(props: Props) {
    return (
        <div className="reader inner">
            <Skeleton className="area" active avatar paragraph={{rows: 3}} />
            <div className="inner"></div>
            <Skeleton className="area" active avatar paragraph={{rows: 3}} />
            <Hide in="sm">
                <div className="inner"></div>
                <Skeleton className="area" active avatar paragraph={{rows: 3}} />
            </Hide>
        </div>
    )
}

export default Listing
