import React from "react";
import { partition } from "lodash";
import { Link } from "react-router-dom";

import { Row, Col } from "antd";
import { formatTime } from "../../../utils/helpers";
import ProfileAvatar from "../Avatars/ProfileAvatar";

const MAX_LENGTH = 40;

function PracticeCommentBlock(props: any) {

    var groupObj = props.group;
    const link = `/practice/post/${props.post_id}`
    const grpLInk = `/group/${12}`
    const date = formatTime(props.created_at);
    let splitTitle = props.title.substring(0, 150)
    const [nested, main] = partition(props.comments, (o) => o.parent_id);

    return (
        <div className="post-block" key={props.index + "_post"}>
            {/* <a href={link}> */}
            {props.post_type === "Message" ? (
                <>
                    <Row type="flex" align="bottom" justify="space-between" className="action-row">
                        {props.user && props.group ? (
                            <Col>
                                <ProfileAvatar
                                    title={props.user.name + " posted a " + "<a class='feed-message-font-type'>" + "message" + "</a>" + " in the " + "<a href=" + `${grpLInk}` + ">" + groupObj.group + "</a>" + " group"}
                                    subtitle={date}
                                    img={props.user.profile_pic}
                                    size={36}
                                    userRole={props.user.user_role}
                                    postType={"Message"}
                                    postWordCount={props.word_count}
                                />
                            </Col>
                        ) : (
                            <Col>
                                <span>{date}</span>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <br />
                        {props.title.length > MAX_LENGTH ? (
                            <Link to={link} className="post-title">{splitTitle}<a className="link" href={link}> read more</a></Link>
                        ) : <Link to={link}><h2 className="post-title">{props.title}</h2></Link>
                        }
                        {/* <a href={link}><h2 className="post-title">{props.title}</h2></a> */}
                        <div className="post-description">

                            {props.summary === null ? (
                                <p></p>
                            ) : (
                                <div>
                                    {props.summary.length > MAX_LENGTH ? (
                                        <p className="">{props.summary}<Link className="link" to={link}> read more</Link></p>
                                    ) :
                                        <p>{props.summary}</p>
                                    }
                                </div>
                            )}
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <Col>
                                {props.comments === null ? (
                                    <a href={link} className="feed-critique-font-type"> 0 Replies</a>
                                ) : (
                                    <a href={link} className="feed-critique-font-type">{props.comments.length} Replies</a>

                                )}
                            </Col>
                        </div>
                    </Row>
                </>

            ) : (
                <>
                    <Row>
                        <Link to={link}><h2 className="post-title">{props.title}</h2></Link>
                        <div className="post-description">
                            {props.summary === null ? (
                                <p></p>
                            ) : (
                                <div>
                                    {props.summary.length > MAX_LENGTH ? (
                                        <p className="">{props.summary}<Link className="link" to={link}> read more</Link></p>
                                    ) :
                                        <p>{props.summary}</p>
                                    }
                                </div>
                            )}
                        </div>
                    </Row>
                    <Row type="flex" align="bottom" justify="space-between" className="action-row">
                        {props.user && props.group ? (
                            <Col>
                                <ProfileAvatar
                                    title={props.user.name}
                                    subtitle={date}
                                    img={props.user.profile_pic}
                                    groupSubtitle={groupObj.group_name}
                                    size={36}
                                    userRole={props.user.user_role}
                                />
                            </Col>
                        ) : (
                            <Col>
                                <span>{date}</span>
                            </Col>
                        )}
                        <Col>
                            {props.comments === null ? (
                                <Link to={link} className="feed-critique-font-type">  0 Critiques 0 Replies</Link>
                            ) : (
                                <Link to={link} className="feed-critique-font-type">{main.length} Critiques {nested.length} Replies</Link>
                            )
                            }
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
}

export default PracticeCommentBlock;
