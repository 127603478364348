// App 
const APP_PREFIX = "@@user_app/"; 

export const FETCH_ASSIGNMENTS = `${APP_PREFIX}FETCH_ASSIGNMENTS`;

// Notifications
const NOTIFICATION_PREFIX = "@@user_notifications/";
export const FETCH_NOTIFICATIONS =  `${NOTIFICATION_PREFIX}FETCH_NOTIFICATIONS`;


// Groups 
const GROUP_PREFIX = "@@user_groups/"; 
export const FETCH_GROUP = `${GROUP_PREFIX}FETCH_GROUP`;
export const FETCH_SINGLE_GROUP = `${GROUP_PREFIX}FETCH_SINGLE_GROUP`;
export const FETCH_GROUP_SUCCESS = `${GROUP_PREFIX}FETCH_GROUP_SUCCESS`;
export const FETCH_GROUP_FAILED = `${GROUP_PREFIX}FETCH_GROUP_FAILED`;
export const TOGGLE_FOLLOW_GROUP = `${GROUP_PREFIX}FOLLOW_GROUP`;

// Courses
const COURSE_PREFIX = "@@user_course/"; 
export const FETCH_COURSE = `${COURSE_PREFIX}FETCH_COURSE`;
export const FETCH_COURSE_SUCCESS = `${COURSE_PREFIX}FETCH_COURSE_SUCCESS`;
export const FETCH_COURSE_FAILED = `${COURSE_PREFIX}FETCH_COURSE_FAILED`;


// Posts 
const POST_PREFIX = "@@user_posts/"; 
export const FETCH_POST_INITIATE= `${POST_PREFIX}FETCH_POST_INITIATE`;
export const FETCH_POST_INITIATE_FAILED = `${POST_PREFIX}FETCH_POST_INITIATE_FAILED`;
export const FETCH_POST_INITIATE_SUCCESS = `${POST_PREFIX}FETCH_POST_INITIATE_SUCCESS`;
export const FETCH_FEED_SUCCESS = `${POST_PREFIX}FETCH_FEED_SUCCESS`;
export const FETCH_SINGLE_POST = `${POST_PREFIX}FETCH_SINGLE_POST`;
export const UPDATE_POST_INITIATE= `${POST_PREFIX}UPDATE_POST_INITIATE`;

// Writing Pieces
export const UPDATE_WRITING_PIECE = `${POST_PREFIX}UPDATE_WRITING_PIECE`;
export const UPDATE_WRITING_PIECE_SUCCESS = `${POST_PREFIX}UPDATE_WRITING_PIECE_SUCCESS`;
export const UPDATE_WRITING_PIECE_FAILED = `${POST_PREFIX}UPDATE_WRITING_PIECE_FAIL`;
export const DELETE_WRITING_PIECE = `${POST_PREFIX}DELETE_WRITING_PIECE`;
export const DELETE_WRITING_PIECE_SUCCESS = `${POST_PREFIX}DELETE_CRITIQUE_SUCCESS`;
export const DELETE_WRITING_PIECE_FAILED = `${POST_PREFIX}DELETE_CRITIQUE_FAILED`;

//Critiques
const CRITIQUE_PREFIX = '@@user_post_critique/'
export const ADD_CRITIQUE = `${CRITIQUE_PREFIX}ADD_CRITIQUE`;
export const UPDATE_CRITIQUE = `${CRITIQUE_PREFIX}UPDATE_CRITIQUE`;
export const DELETE_CRITIQUE = `${CRITIQUE_PREFIX}DELETE_CRITIQUE`;
export const FETCH_CRITIQUES = `${CRITIQUE_PREFIX}FETCH_CRITIQUES`;
export const FETCH_CRITIQUE_INITIATE_SUCCESS = `${CRITIQUE_PREFIX}FETCH_CRITIQUE_INITIATE_SUCCESS`;
export const FETCH_CRITIQUE_INITIATE_FAILED = `${CRITIQUE_PREFIX}FETCH_CRITIQUE_INITIATE_FAILED`;


//User 
const USER_PREFIX = '@@user_post_critique'
export const USER_SET_PROFILE_BIO = `${USER_PREFIX}USER_SET_PROFILE_BIO`;
export const USER_SET_PROFILE_GROUPS = `${USER_PREFIX}USER_SET_PROFILE_GROUPS`;
export const USER_SET_PROFILE_POSTS = `${USER_PREFIX}USER_SET_PROFILE_POSTS`;

//Auth
const AUTH_PREFIX = '@@user_auth/';
export const AUTH_SIGN_UP = `${AUTH_PREFIX}AUTH_SIGN_UP`;
export const AUTH_SIGN_UP_SUCCESS = `${AUTH_PREFIX}AUTH_SIGN_UP_SUCCESS`;
export const AUTH_VERIFY_EMAIL = `${AUTH_PREFIX}AUTH_VERIFY_EMAIL`;

//Subscription
const SUBSCRIPTION_PREFIX = '@@user_subscription/';
export const ADD_SUBSCRIPTION = `${SUBSCRIPTION_PREFIX}ADD_SUBSCRIPTION`;
export const UPDATE_SUBSCRIPTION = `${SUBSCRIPTION_PREFIX}UPDATE_SUBSCRIPTION`;
export const DELETE_SUBSCRIPTION = `${SUBSCRIPTION_PREFIX}DELETE_SUBSCRIPTION`;
export const FETCH_USER_SUBSCRIPTION =  `${NOTIFICATION_PREFIX}FETCH_USER_SUBSCRIPTION`;

//Feed
const FEED_PREFIX = '@@user_feed/';
export const FETCH_FEED = `${FEED_PREFIX}FETCH_FEED`;

//Practice
const ARTICLE_PREFIX = '@@user_articles';
export const FETCH_ARTICLES_SUCCESS = `${ARTICLE_PREFIX}FETCH_ARTICLES`;
export const FETCH_PRACTICE_POST_SUCCESS = `${ARTICLE_PREFIX}FETCH_PRACTICE_POST_SUCCESS`;
export const UPDATE_PRACTICE_POST = `${ARTICLE_PREFIX}UPDATE_PRACTICE_POST`;
export const FETCH_PRACTICE_CRITIQUES = `${ARTICLE_PREFIX}FETCH_PRACTICE_CRITIQUES`;
export const ADD_PRACTICE_CRITIQUE = `${ARTICLE_PREFIX}ADD_PRACTICE_CRITIQUE`;
export const UPDATE_PRACTICE_CRITIQUE = `${ARTICLE_PREFIX}UPDATE_PRACTICE_CRITIQUE`;
export const DELETE_PRACTICE_CRITIQUE = `${ARTICLE_PREFIX}DELETE_PRACTICE_CRITIQUE`;