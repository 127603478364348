import {
    USER_SET_PROFILE_BIO,
    USER_SET_PROFILE_GROUPS,
    USER_SET_PROFILE_POSTS,
    FETCH_POST_INITIATE,
    FETCH_POST_INITIATE_FAILED,
    FETCH_POST_INITIATE_SUCCESS,
    FETCH_USER_SUBSCRIPTION
} from "../actions/_types";

const initial_state = {
    bio: {
        badges: null,
        bio: "",
        books_published: null,
        comments_count: 0,
        email: "",
        first_name: "",
        genre: null,
        id: 20,
        last_name: "",
        posts_count: 0,
        profile_pic: null,
        subscription_plan: "",
        user_groups: null,
        user_role: "user",
        writing_goal: null
    },
    groups: [],
    posts: [],
    sub: []
}



const userReducer = (state = initial_state, action) => {
    switch(action.type) {
        case USER_SET_PROFILE_BIO:
            return setProfile(state, action.payload);
        case USER_SET_PROFILE_GROUPS:
            return setGroups(state, action.payload);
        case USER_SET_PROFILE_POSTS:
            return setPosts(state, action.payload);
        case FETCH_POST_INITIATE:
            return onFetch(state);
        case FETCH_POST_INITIATE_SUCCESS:
            return onFetchFinish(state);
        case FETCH_POST_INITIATE_FAILED:
            return onFetchFinish(state)
        case FETCH_USER_SUBSCRIPTION:
            return getUserSubscription(state, action.payload);
        default:
            return state;
    }
};

const getUserSubscription = (state, sub) => {
    return {
        ...state,
        sub: sub
    }
};


const setProfile = (state, bio) => ({
    ...state,
    bio
});

const setGroups = (state, groups) => ({
    ...state,
    groups: groups.length > 0 ? groups : []
});

const setPosts = (state, posts) => ({
    ...state,
    posts: posts.length > 0 ? posts : []
});

const onFetch = (state) => ({
    ...state,
    loading: true
});

const onFetchFinish = (state) => ({
    ...state,
    loading: false
});

export default userReducer;