import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import {
    Icon,
    Typography,
    Row,
    Col
} from "antd";
import GeneralSettingsBody from '../../shared/GeneralSettingsBody'
import { formatTime } from '../../../utils/helpers'
import { fetchUserPosts } from '../../../actions/user'

interface Bio {
    badges: any,
    bio: string,
    books_published: any,
    comments_count: number,
    email: string,
    first_name: string,
    genre: any,
    id: number,
    member_since: string,
    last_name: string,
    posts_count: number,
    profile_pic: any,
    subscription_plan: string,
    user_groups: any,
    user_role: string,
    writing_goal: any,
    website: string
}

interface Props {
    posts: Array<any>,
    bio: Bio,
    fetchUserPosts: (id: number) => any
}

function GeneralActivityFeed(props: Props) {
    const { posts, bio } = props;
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false)



    useEffect(() => {
        setLoading(true)
        if (bio && bio.id)
            props.fetchUserPosts(bio.id)
    }, [])


    return posts ? (
        <GeneralSettingsBody active="profile">
            <div>
                {
                    posts && posts.map(item => item.title ? (
                        <div className="activity-box">
                            <a href={`/post/${item.post_id}`}>
                                <Row>
                                    <a href={`/post/${item.post_id}`}><h3>{item.title}</h3></a>
                                </Row>
                                <div>
                                    {item.summary}
                                </div>
                                <br />
                                <Row type="flex" justify="space-between">
                                    <Col>
                                        <span>{formatTime(item.created_date)} on <a>{item.group}</a></span>
                                    </Col>
                                    <Col>
                                        {item.comment_count
                                            ? <Typography.Text><Icon type="message" />  {item.comment_count}</Typography.Text>
                                            : 'no responses'}
                                    </Col>
                                </Row>
                            </a>
                        </div>
                    ) : (
                        <div className="activity-box">
                            Not Posts
                        </div>
                    ))
                }
            </div>
        </GeneralSettingsBody>
    ) : null;
}

const mapStoP = (state: any) => ({
    bio: state.user.bio,
    posts: state.user.posts
});

const Actions = {
    fetchUserPosts,
}

export default connect(mapStoP, Actions)(GeneralActivityFeed);
