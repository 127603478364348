
import ServiceManager from "./manager";
import ApiService from "./handler";
import PostService from "./_Post";
import CritiqueService from './_Critique'
import UserService from './_User'
import AuthService from './_Auth';
import SubscriptionService from './_Subscription';
import GroupService from './_Group';
import FeedService from './_Feed';
import AlgoliaService from './_Algolia';
import AppService from './_App';
import NotificationService from './_Notifications';
import CourseService from './_Course';
import PracticeService from './_Practice'

let serviceManagerInstance;
let BASE_URL = process.env.REACT_APP_TWP_API_DOMAIN;

export const register = (serviceManager = new ServiceManager()) => {

    serviceManager.register('ApiService', () => {
        return new ApiService(BASE_URL);
    });
    
    serviceManager.register('AppService', (serviceManager) => {
        let api = serviceManager.get('ApiService');
        return new AppService(api);
    });

    serviceManager.register('PostService', (serviceManager) => {
        let api = serviceManager.get('ApiService');
        return new PostService(api);
    });

    serviceManager.register('CritiqueService', (serviceManager) => {
        let api = serviceManager.get('ApiService');
        return new CritiqueService(api);
    });

    serviceManager.register('UserService', (serviceManager) => {
        let api = serviceManager.get('ApiService');
        return new UserService(api);
    });

    serviceManager.register('AuthService', (serviceManager) => {
        let api = serviceManager.get('ApiService');
        return new AuthService(api);
    });

    serviceManager.register('SubscriptionService', (serviceManager) => {
        let api = serviceManager.get('ApiService');
        return new SubscriptionService(api);
    });

    serviceManager.register('GroupService', (serviceManager) => {
        let api = serviceManager.get('ApiService');
        return new GroupService(api);
    });

    serviceManager.register('FeedService', (serviceManager) => {
        let api = serviceManager.get('ApiService');
        return new FeedService(api);
    });

    serviceManager.register('AlgoliaService', (serviceManager) => {
        let api = serviceManager.get('ApiService');
        return new AlgoliaService(api);
    });

    serviceManager.register('NotificationService', (serviceManager) => {
        let api = serviceManager.get('ApiService');
        return new NotificationService(api);
    });

    serviceManager.register('CourseService', (serviceManager) => {
        let api = serviceManager.get('ApiService');
        return new CourseService(api);
    });

    serviceManager.register('PracticeService', (serviceManager) => {
        let api = serviceManager.get('ApiService');
        return new PracticeService(api);
    });

    serviceManagerInstance = serviceManager;
    return serviceManagerInstance;
};