import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory, Link, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Alert,
  Button,
  Row,
  Icon,
  Modal,
  message,
  Col,
  Tabs,
  Result
} from "antd";

import { Map } from "immutable";

// Sagas
import { practiceSignUp } from "../../../actions/auth";
import { verifyPromoCode } from "../../../actions/user";
import { signIn } from "../../../actions/auth";

const { TabPane } = Tabs;

interface SignUpProps {
  token?: string;
  form: any;
  practiceSignUp?: (account: SignUpForm) => any;
  signIn: (account: SignInForm) => any;

  // verifyPromoCode: (promo_code: string) => any;
  // highlights?: (text: boolean) => void;
}

type SignInForm = {
  email: string;
  password: string;
  [key: string]: any;
};

type SignUpForm = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  [key: string]: any;
};

const emailrule = [
  { required: true, message: "Please input email" },
  { type: "email", message: "Enter valid email" },
];
const rule = [{ required: true, message: "This field cannot be empty" }];

function PracticeRegister(props: SignUpProps) {
  const history = useHistory();
  const [errors, setErrors] = useState<Array<string>>([]);
  const [visible, setVisible] = useState(true);
  const [successVisible, setSuccessVisible] = useState(false);
  const [loading, toggleLoading] = useState(false);
  const [linkPromoCode, setPromoCode] = useState("");
  const location = useLocation();
  let isCourseLink;
  var courseLink;

  React.useEffect(() => {
    isCourseLink = location.search; //capture the query params
    courseLink = new URLSearchParams(isCourseLink).get("source") || "";
    courseLink.slice(0, 9)

    setPromoCode(courseLink)
  }, [isCourseLink]);

  const onSignUp = () => {
    setErrors([]); //Reset validation to validate again.
    let errs: Array<string> = [];

    props.form.validateFields((err: any, vals: any) => {

      //Checks for whitespace
      if (vals.first_name.length < 2) {
        errs.push("Input fields cannot be empty");
      }

      if (!err) {
        const {
          email,
          password,
          first_name,
          last_name,
        } = vals

        if (props.practiceSignUp) props.practiceSignUp(vals).then((res) => {
          if (res && res.success) {
            localStorage.setItem("twp-token", res.data.access_token);

            setVisible(false);
            setSuccessVisible(true)

            // set errors
            setErrors(errs);
            // return validation
            if (errs.length < 1) {
              return true;
            } else {
              return false;
            }

          }
          else {
            message.warning(res.message || "");
          }
        })
      }
    });
  };

  const onSignIn = () => {
    props.form.validateFields((err: any, vals: any) => {
      if (!err) {
        toggleLoading(true);
        const { email, password } = vals;
        props
          .signIn({
            email,
            password,
          })
          .then((res) => {
            if (res && res.success) {

              //TWP TOKEN
              localStorage.setItem("twp-token", res.data.access_token);
              localStorage.setItem("subscription-setup", res.data.data.isSubscriptionSetup);
              localStorage.setItem("home-tour-id", res.data.firsttime_logins[0].tour_id);
              localStorage.setItem("home-tour", res.data.firsttime_logins[0].isHome);

              //Checks if there is a sub plan in place
              if (res.data.data.isSubscriptionSetup === "false") {

                //  <----CODE FOR REVAMP -----> 
                const thinificURL = `https://thewritepractice.com/the-write-practice-pro-sales-page?first_name=${res.data.first_name}&last_name=${res.data.last_name}&email=${res.data.email}`
                var win = window.open(thinificURL, '_self');
                //<----END ----->

              } else {
                if (linkPromoCode) {
                  const thinificURL = `https://thewritepractice.thinkific.com/api/sso/v2/sso/jwt?jwt=${res.data.thinkific_token}&return_to=${linkPromoCode}`
                  var win = window.open(thinificURL, '_self');

                } else {
                  history.push("/");
                }
              }

              message.success(res.message);

            } else {
              message.warning(res.message || "");
            }
            toggleLoading(false);
          });
      }
    });
  };

  function tabsCallBack(key) {
    console.log(key);
  }
  const handleCancel = () => {
    setVisible(false);
  };


  const { getFieldDecorator } = props.form;


  return (
    // <div className="bg-c1">
    <>
      <Modal title="Join Pro"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        maskClosable={false}
      >

        <Tabs defaultActiveKey="1" onChange={tabsCallBack}>
          <TabPane tab="Register" key="1">
            <div className="container inner">
              <Form>
                <div className="section">
                  <Row className="t-center">
                    <h2>Create your Pro Write Practice account</h2>
                    <p>Review your account details and create a password.</p>
                  </Row>
                  <div className="register-form auth-box">
                    <Form.Item label="" style={{ marginBottom: 5 }}>
                      {getFieldDecorator("first_name", {
                        rules: rule,
                      })(
                        <div className="register-input">
                          <Input
                            type="text"
                            prefix={<Icon type="user" />}
                            placeholder="First Name"
                            id="first_name"
                          />
                        </div>
                      )}
                    </Form.Item>
                    <Form.Item label="" style={{ marginBottom: 5 }}>
                      {getFieldDecorator("last_name", {
                        rules: rule,
                      })(
                        <div className="register-input">
                          <Input
                            type="text"
                            prefix={<Icon type="user" />}
                            placeholder="Last Name"
                            id="last_name"
                          />
                        </div>
                      )}
                    </Form.Item>
                    <Form.Item className="imp">
                      {getFieldDecorator("email", {
                        rules: emailrule,
                      })(
                        <div className="register-input">
                          <Input
                            type="email"
                            prefix={<Icon type="mail" />}
                            placeholder="Email"
                            id="email"
                          />
                        </div>
                      )}
                    </Form.Item>
                    <Form.Item className="imp">
                      {getFieldDecorator("password", {
                        rules: [{ required: true, message: "Please input password" }, { min: 5, message: 'Password must be more than 6 characters.' }],
                      })(
                        <div className="register-input">
                          <Input.Password
                            placeholder="Password"
                            prefix={<Icon type="lock" />}
                          />
                        </div>
                      )}
                    </Form.Item>
                    {errors.length > 0 ? (
                      <Row className="inner">
                        {errors.map((err, i) => (
                          <div key={`err_${i}`} style={{ color: "red" }}>
                            <Alert message={err} type="error" showIcon />
                            {/* <b>{err}</b> */}
                          </div>
                        ))}
                      </Row>
                    ) : null}
                    <div className="inner">
                      <Button
                        type="primary"
                        className="btn-c1"
                        size="large"
                        onClick={onSignUp}
                      >
                        Get Started
                      </Button>
                    </div>
                    {/* <div>
              <span>Already a user?</span>
              <Link to="/auth/login"> Login</Link>
            </div> */}
                  </div>
                </div>
              </Form>
            </div>
          </TabPane>
          {/* Login */}
          <TabPane tab="Login" key="2">
            <div className="container inner">
              <Form>
                <div className="section">
                  <Row className="t-center">
                    <h2>Login to your Pro Write Practice account</h2>
                    {/* <p>Review your account details and create a password.</p> */}
                  </Row>
                  <div>
          <Form.Item hasFeedback className="imp">
            {getFieldDecorator("email", {
              rules: emailrule,
            })(
              <Input
                type="email"
                prefix={<Icon type="mail" />}
                placeholder="Email"
                id="email"
              />
            )}
          </Form.Item>
          <Form.Item className="imp">
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Please input password" }],
            })(
              <Input.Password
                placeholder="Password"
                prefix={<Icon type="lock" />}
              />
            )}
          </Form.Item>
          <div className="inner"></div>
          <Row type="flex" justify="space-between" align="middle">
            <Button
              loading={loading}
              type="primary"
              className="btn-c1"
              size="large"
              onClick={onSignIn}
            >
              Sign In
            </Button>
            <Link to="/auth/forgot-password">Forgot your password? </Link>
          </Row>
        </div>
                </div>
              </Form>
            </div>
          </TabPane>
        </Tabs>
      </Modal>

      <Modal
        title={"Welcome to Pro"}
        visible={successVisible}
        onCancel={() => setSuccessVisible(false)}
        onOk={() => setSuccessVisible(false)}
        footer={null}
        closable={false}
        maskClosable={false}
      >
        <Row style={{ paddingBottom: 10 }}>
          <div>
            <Col>
              <Result
                status="success"
                title="Welcome to Pro Write Practice!"
                subTitle="Press Proceed to share your writing in our members only community"
                extra={[
                  <Button type="primary" key="console" onClick={() => setSuccessVisible(false)}>
                    Proceed
                  </Button>,
                ]}
              />
            </Col>
          </div>
        </Row>
      </Modal>
    </>
  );
}

const mapStateToProps = (state: { errors: Map<string, any>; auth: Map<string, any>; }) =>
({
  token: "", //state.auth.get('token'),
  // highlight: true
});

const Actions = {
  practiceSignUp,
  verifyPromoCode,
  signIn
};

const _Form = connect(mapStateToProps, Actions)(PracticeRegister)

// export default connect(mapStateToProps, Actions)(PracticeRegister);

 export default Form.create({ name: "practice-register" })(_Form);
