export default class CritiqueService {
    /**
     * @param {ApiService} apiService
     */
    constructor(apiService) {
        this.api = apiService;
    }

    updateCritique(critique) {
        return this.api.post('update-comment', critique);
    }
    addCritique(critique) {
        return this.api.post('create-comment', critique)
    }
    deleteCritique(id) {
        return this.api.delete('delete-comment', { comment_id: id})
    }
    getCritique(post_id) {
        return this.api.get(`comments/${post_id}`);
    }
}