import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Icon, Typography, Row, Col, Button, Skeleton, Spin, Tabs } from "antd";
import SettingsBody from "../../shared/SettingsBody";
import { formatTime } from "../../../utils/helpers";
import { fetchUserPosts } from "../../../actions/user";
import { fetchUserPracticePosts } from "../../../actions/practice";

interface Bio {
  badges: any;
  bio: string;
  books_published: any;
  comments_count: number;
  email: string;
  first_name: string;
  genre: any;
  id: number;
  member_since: string;
  last_name: string;
  posts_count: number;
  profile_pic: any;
  subscription_plan: string;
  user_groups: any;
  user_role: string;
  writing_goal: any;
  website: string;
}

interface Props {
  loading: boolean;
  posts: Array<any>;
  bio: Bio;
  fetchUserPosts: (id: number, offset: number, limit: number) => any;
  fetchUserPracticePosts: (id: number, offset: number, limit: number) => any;
}

const { Text } = Typography;
const { TabPane } = Tabs;

function ActivityFeed(props: Props) {
  const { posts, bio } = props;
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [counter, setCounter] = useState(0);
  const [hide, setHide] = useState(false);
  const [pract, setPract] = React.useState<Array<any>>();

  const limit = 10;


  useEffect(() => {
    setLoading(true);
    if (counter === 0) {
      setHide(true);
    }
    if (bio && bio.id) invokeActivityFeed(0);
    if (bio && bio.id) invokePracticeFeed(0);
  }, []);

  //Handles Load More button
  const handleLoadMore = () => {
    if (hasMore) {
      invokeActivityFeed(offset + 1).then(() => setOffset(offset + 1));
      setCounter(counter + 1);
      if (counter === 0) {
        setHide(false);
      }
    }
  };

  //Handles previous button to load previous feed
  const handlePrevious = () => {
    if (hasMore) {
      invokeActivityFeed(offset - 1).then(() => setOffset(offset - 1));
      setCounter(counter - 1);
      if (counter === 1) {
        setHide(true);
      }
    }
  };

  //Invokes activity Feed
  const invokeActivityFeed = async (offset) => {
    setLoading(true);
    return await props.fetchUserPosts(bio.id, offset, limit).then((res) => {
      setLoading(false);
      const { posts } = res ? res.data[0] : ""; // prevents error when there are no posts in user feed
      const psts = posts;

      if (psts && psts.length > limit) {
        setHasMore(false);
      }

    });
  };

  // Invokes practice feed
  const invokePracticeFeed = async (offset) => {
    setLoading(true);
    return await props.fetchUserPracticePosts(bio.id, offset, limit).then((res) => {
      if (res) {
        setLoading(false);
        const psts = res.data[0].posts;
        if (psts) {
          setPract(psts);
        }

        if (psts && psts.length > limit) {
          setHasMore(false);
        }

      }
    });
  };

  const postEl =
    posts.length > 0
      ? posts.map((item) =>
      (
        <div className="activity-box">
          <Row>
            <a href={`/post/${item.post_id}`}>
              <h3 className="post-title">{item.title}</h3>
            </a>
          </Row>
          <div>{item.summary}</div>
          <br />
          <Row type="flex" justify="space-between">
            <Col>
              <span>
                {formatTime(item.created_date)} on <a>{item.group}</a>
              </span>
            </Col>
            <Col>
              {item.comments.length > 0 ? (
                <Typography.Text>
                  <Icon type="message" /> {item.comments.length}
                </Typography.Text>
              ) : (
                "no response"
              )}
            </Col>
          </Row>
        </div>
      )
        // : (
        //   // <div className="activity-box">No Posts</div>
        //   <h2 style={{ textAlign: "center" }}>No posts published yet</h2>
        // )
      )
      :
      <div style={{ textAlign: "center" }}>
        <Text mark>This is your activity feed. Post a writing piece or message in any group, and then come back to find all your posts here. Go get em, writer.</Text>
      </div>

  const practiceEl = pract ? pract.map((item) => (
    <div className="activity-box">
      <Row>
        <a href={`/practice/post/${item.post_id}`}>
          <h3 className="post-title">{item.title}d</h3>
        </a>
      </Row>
      <div>{item.summary}</div>
      <br />
      <Row type="flex" justify="space-between">
        <Col>
          <span>
            {formatTime(item.created_date)} on <a>{item.group}</a>
          </span>
        </Col>
        <Col>
          {item.comments.length > 0 ? (
            <Typography.Text>
              <Icon type="message" /> {item.comments.length}
            </Typography.Text>
          ) : (
            "no response"
          )}
        </Col>
      </Row>
    </div>
  )) : null

  return (
    <SettingsBody active="activity-feed">
      <div className="twp-wrapper inner-l">
        <Row className="reader">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Posts" key="1">
              <div>{postEl}</div>
            </TabPane>
            {pract && pract.length > 0 &&
              <TabPane tab="Practice" key="2">
                <div>{practiceEl}</div>
              </TabPane>
            }
          </Tabs>
          <br />
          {props.loading ? (
            <div className="loading-spin">
              <Skeleton active className="area" />
            </div>
          ) : (
            <Row type="flex" justify="center">
              <Col>
                {hide === true ? (
                  <Button style={{ display: "none" }} onClick={handlePrevious}>
                    Previous
                  </Button>
                ) : (
                  <Button onClick={handlePrevious}>Previous</Button>
                )}
              </Col>
              <Col style={{ paddingRight: 10 }}>
                <Button onClick={handleLoadMore}>Load more</Button>
              </Col>

            </Row>
          )}
        </Row>
      </div>
    </SettingsBody>
  );
}


const mapStoP = (state: any) => ({
  bio: state.user.bio,
  posts: state.user.posts,
  loading: state.posts.loading,
});

const Actions = {
  fetchUserPosts,
  fetchUserPracticePosts
};

export default connect(mapStoP, Actions)(ActivityFeed);
