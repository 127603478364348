import React, { useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { connect } from "react-redux";
import { siteWalkThrough } from "../../../actions/app";
import { TOUR_STEPS } from "./steps"


// Define the state
const INITIAL_STATE = {
    key: new Date(),
    run: false,
    showProgress: true,
    continuous: true,
    loading: false,
    stepIndex: 0,
    steps: TOUR_STEPS,
};

interface TourProps {
    start?: Boolean;
    highlights?: (text: string) => any;
    siteWalkThrough: (responseBody: any) => any;
}

// Set up the reducer function
const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case "START":
            return { ...state, run: true };
        case "RESET":
            return { ...state, stepIndex: 0 };
        case "STOP":
            return { ...state, run: false };
        case "NEXT_OR_PREV":
            return { ...state, ...action.payload };
        case "RESTART":
            return {
                ...state,
                stepIndex: 0,
                run: true,
                loading: true,
                // lifecycle: 'beacon',
                key: new Date(),
            };
        default:
            return state;
    }
};

const Tour = (props: TourProps) => {
    const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
    const tourId = localStorage.getItem("home-tour-id");
    const isHome = localStorage.getItem("home-tour")?.toString();
    const r = 1;
    const asdf = r.toString();

    useEffect(() => {

        if (tourId && isHome === asdf) {
            dispatch({ type: "START" });
            // const params = {
            //     tour_id: parseInt(tourId),
            //     isHome: false, // after completing the tour - it should be false
            //     isEditor: true, //if not complete true
            //     isGroups: true
            // }
            // console.log("Tour params", params);

            // const updateTour = props.siteWalkThrough(params)
            // localStorage.setItem("home-tour", "false");
        }

        if (props.start === true) {
            dispatch({ type: "START" });
        }
    }, []);

    const callback = (data) => {
        const { action, index, type, status } = data;
        if (
            action === ACTIONS.CLOSE ||
            (status === STATUS.SKIPPED && tourState.run) ||
            status === STATUS.FINISHED
        ) {
            dispatch({ type: "STOP" });
            const params = {
                tour_id: tourId,
                isHome: false,
                isEditor: true,
                isGroups: true
            }

            const updateTour = props.siteWalkThrough(params)
            if (props.highlights) { props.highlights("false") }
            localStorage.setItem("home-tour", "false");
        } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
            dispatch({
                type: "NEXT_OR_PREV",
                payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
            });
        }
    };

    return (
        <>
            <JoyRide
                {...tourState}
                callback={callback}
                continuous={true}
                showSkipButton={true}
                // disableBeacon={true}
                debug={true}
                // spotlightClicks={true}
                disableOverlayClose={true}
                disableScrolling={true}
                locale={{
                    last: "End Tour",
                    skip: "Skip Tour"
                }}
                styles={{
                    options: {
                        arrowColor: '#e3ffeb',
                        // backgroundColor: '#e3ffeb',
                        // overlayColor: 'rgba(79, 26, 0, 0.4)',
                        // primaryColor: '#000',
                        // textColor: '#004a14',
                        // width: 900,
                        // zIndex: 1000,
                    },
                    tooltipContainer: {
                        textAlign: "left"
                    },
                    buttonNext: {
                        backgroundColor: "#E15A00"
                    },
                    buttonBack: {
                        marginRight: 10
                    }
                }}
            />
        </>
    );
}

const Action = {
    siteWalkThrough
}

export default connect(null, Action)(Tour);