import React, { useState } from 'react'
import { Tabs } from 'antd'
import SettingsBody from '../../shared/SettingsBody'
import ChangePasswordContainer from './ChangePassword'
import ChangeSubscriptionContainer from './Subscriptions/ChangeSubscription'
const { TabPane } = Tabs;

const tabList = [
    {
        key: 'change_password',
        tab: 'Change Password',
    }
];

const contentList = {
    change_password: <ChangePasswordContainer />,
    subscription: <ChangeSubscriptionContainer />,
    project: <p>project content</p>,
};


function SettingContainer() {
    const [tab, switchTab] = useState('change_password');

    return (
        <SettingsBody active="settings">
            <Tabs
                tabPosition="top"
                className="settings-tab"
                defaultActiveKey={tab}
                onChange={key => {
                    switchTab(key);
                }}
            >
                {tabList.map((t) => (
                    <TabPane tab={t.tab} key={t.key}>
                        {contentList[t.key]}
                    </TabPane>
                ))}
            </Tabs>
        </SettingsBody>
    )
}

export default SettingContainer