import React, { useState } from "react";
import {
  Form,
  Icon,
  Button,
  Row,
  Card,
  Col,
  Typography,
  Select,
  Table,
  Modal,
} from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchSubscriptions,
  updateSubscription,
  fetchUserSubscription,
} from "../../../../actions/user";

const { Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;

interface Bio {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
}

interface Packages {
  subscription_id: number;
  password: string;
  token: string;
}

interface Props {
  requestChangeSubscription: (newSub: newSubscription) => any;
  packages?: Packages;
  fetchUserSubscription: () => void;
  bio: Bio;
}

type newSubscription = {
  password: string;
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const columns = [
  {
    title: "Name",
    dataIndex: "plan",
  },
  {
    title: "Period",
    dataIndex: "period",
    // render: () => "Yes",
  },
  {
    title: "Monthly Pricing",
    dataIndex: "charge",
    render: (text) => "$" + text,
  },

];

const ChangeSubscription = (props: any) => {
  const history = useHistory();
  const [subscriptions, setSubscriptions] = useState<Array<any>>([]);
  const [change, toggleChange] = useState(false);
  const [loading, toggleLoading] = useState(true);
  const currentVal = 1;
  const promoCode = 7
  const [updateSubscription, setUpdateSubscription] = useState<any>();
  const [userSubscription, setUserSubscription] = useState({
    id: "",
    isExpired: "",
    isValid: "",
    plan: "",
    price: "",
    subscribed_date: "",
    subscription_period: "",
    user_id: "",
    user_subscription_id: "",
  });


  function handleSubmit(e: any) {
    e.preventDefault();

    if (updateSubscription === 2 || updateSubscription === 3) {
      window.location.href = `https://thewritepractice.samcart.com/products/becoming-writer-monthly/?first_name=${props.bio.first_name}&last_name=${props.bio.last_name}&email=${props.bio.email}`;
    } 
    
    if (updateSubscription === 4 || updateSubscription === 5) {
      window.location.href = `https://thewritepractice.samcart.com/products/becoming-writer-premium-monthly/?first_name=${props.bio.first_name}&last_name=${props.bio.last_name}&email=${props.bio.email}`;
    } 

  }

  //fetch Subscription API
  const getSubscriptions = async () => {
    const subs = await props.fetchSubscriptions();
    setSubscriptions(subs);
    toggleLoading(false);
    return subs;
  };

  React.useEffect(() => {
    getSubscriptions();
  }, []);

  //get all Subscriptions
  const getUserSubscriptions = async () => {
    const subs = await props.fetchUserSubscription();
    setUserSubscription(subs);
    return subs;
  };

  React.useEffect(() => {
    getUserSubscriptions();
  }, []);

  function doChange() {
    toggleChange(true);
  }

  const { getFieldDecorator } = props.form;

  return (
    <div>
      {change && subscriptions.length > 0 ? (
        <Form
          {...formItemLayout}
          layout="vertical"
          onSubmit={handleSubmit}
          className="inner"
        >
          <Row>
            <Row className="inner">
              <Row type="flex" justify="space-between">
                <h4>Package Information</h4>
              </Row>
              {
                <Table
                  pagination={false}
                  columns={columns}
                  dataSource={subscriptions}
                />
              }
            </Row>
            <Form.Item label="Select Package">
              {getFieldDecorator("subscription")(
                <Select
                  defaultActiveFirstOption={true}
                  onChange={(v) =>
                    setUpdateSubscription(parseInt(v.toString()))
                  }
                >
                  {subscriptions.map((s) => (
                    <Option
                      key={s.subscription_charge_id + "_package"}
                      value={s.subscription_charge_id}
                      disabled={promoCode === s.subscription_charge_id} //{currentVal === s.charge} 
                    >
                      {s.plan} - {s.period}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Row>
          <Form.Item>
            <Row gutter={8} type="flex">
              <Col>
                <Button className="btn-c1" type="primary" htmlType="submit">
                  Confirm
                </Button>
              </Col>
              <Col>
                <Button className="btn-c1" onClick={() => toggleChange(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      ) : (
          <Row className="inner">
            <Card
              type="inner"
              title="Subscription"
              extra={
                loading ? (
                  <Icon type="loading" spin={true} />
                ) : (
                    <a onClick={doChange}>Change</a>
                  )
              }
            >
              <p>Your subscription package:</p>
              <h1>
                <Text type="secondary">{userSubscription.plan}</Text>
              </h1>
            </Card>
          </Row>
        )}
    </div>
  );
};

const Actions = {
  fetchSubscriptions,
  updateSubscription,
  fetchUserSubscription,
};

const mapStoP = (state: any) => ({
  bio: state.user.bio,
});

//Connect to redux store
const FPStore = connect(mapStoP, Actions)(ChangeSubscription);

//Connect to Form and export
export default Form.create({ name: "change_subscription" })(FPStore);
