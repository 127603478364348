
import Feed from './screens/Feed'
import Post from './screens/Post/Post'
import Listing from './screens/Listing'
import CreatePost from './screens/Post/CreatePost'
import UserProfileListing from './screens/UserProfileListing'
import GeneralActivityFeedContainer from './screens/GeneralActivityFeed'

//Settings
import Profile from './screens/Profile'
import Settings from './screens/Settings'
import SubscriptionSettings from './screens/SubscriptionSettings'
import CreateCourse from './screens/CreateCourse'
import Courses from './screens/Courses'
import Team from './screens/Settings/Team'

import Notifications from './screens/Notifications'
import ActivityFeed from './screens/Settings/ActivityFeed'
import Assignments from './screens/Settings/Assignments'
import Drafts from './screens/Settings/Drafts'

import CancelSubscription from './screens/CancelSubscription'
import Groups from './screens/Groups'
import GroupsListing from './screens/GroupListing'
import DraftsContainer from './screens/Drafts'
//Auth
import Login from './screens/Auth/Login'
import Register from './screens/Auth/Register'
import VerifyEmail from './screens/Auth/VerifyEmail'
import ForgotPassword from './screens/Auth/ForgotPassword'
import RestorePassword from './screens/Auth/RestorePassword'
import Subscription from './screens/Auth/Subscription'
import ActiveSubscriptions from './screens/Settings/ActiveSubscription'
import GeneralProfile from './screens/GeneralProfile'

import PracticeGroup from './screens/Practice/PracticeGroup'
import Articles from './screens/Practice/Articles'
import PracticeCreatePostContainer from './screens/Practice/PracticeCreatePostContainer'
import PracticePostContainer from './screens/Practice/PracticePostContainer'

const Routes = [
    {
        shared: false,
        path: '/',
        component: Feed,
        exact: true
    },
    {
        shared: false,
        path: '/post/:post_id',
        component: Post,
        exact: true
    },
    {
        shared: false,
        path: '/posts/group/:id',
        component: Listing,
        exact: true
    },
    {
        shared: false,
        path: '/group/:id',
        component: Groups,
        exact: true
    },
    {
        shared: false,
        path: '/create-post',
        component: CreatePost,
        exact: true
    },
    {
        shared: false,
        path: '/profile',
        component: Profile,
        exact: true
    },
    {
        shared: false,
        path: '/user/profile/:id',
        component: GeneralProfile,
        exact: true
    },
    {
        shared: false,
        path: '/general-activity-feed',
        component: GeneralActivityFeedContainer,
        exact: true
    },
    {
        shared: false,
        path: '/drafts',
        component: Drafts,
        exact: true
    },
    {
        shared: false,
        path: '/settings',
        component: Settings,
        exact: true
    },
    {
        shared: false,
        path: '/user-subscriptions',
        component: SubscriptionSettings,
        exact: true
    },
    // {
    //     shared: false,
    //     path: '/new-group',
    //     component: NewGroup,
    //     exact: true
    // },
    {
        shared: false,
        path: '/groups',
        component: GroupsListing,
        exact: true
    },
    {
        shared: false,
        path: '/courses',
        component: CreateCourse,
        exact: true
    },
    {
        shared: false,
        path: '/course/:id',
        component: Courses,
        exact: true
    },
    {
        shared: false,
        path: '/team',
        component: Team,
        exact: true
    },
    {
        shared: false,
        path: '/active-subscription',
        component: ActiveSubscriptions,
        exact: true
    },
    {
        shared: false,
        path: '/notifications',
        component: Notifications,
        exact: true
    },
    {
        shared: false,
        path: '/activity-feed',
        component: ActivityFeed,
        exact: true
    },
    {
        shared: false,
        path: '/assignments',
        component: Assignments,
        exact: true
    },
    {
        shared: true,
        path: '/auth/verify-email/:token',
        component: VerifyEmail,
        exact: true
    },
    {
        shared: true,
        path: '/auth/login',
        component: Login,
        exact: true
    },
    {
        shared: true,
        path: '/auth/register/:promo_code?',
        component: Register,
        exact: true
    },
    {
        shared: true,
        path: '/subscription',
        component: Subscription,
        exact: true
    },
    {
        shared: true,
        path: '/auth/restore-password/:token',
        component: RestorePassword,
        exact: true
    },
    {
        shared: true,
        path: '/auth/forgot-password',
        component: ForgotPassword,
        exact: true
    },
    {
        shared: true,
        path: '/cancel-plan',
        component: CancelSubscription,
        exact: true
    },
    {
        shared: true,
        path: '/drafts',
        component: DraftsContainer,
        exact: true
    },
    {
        shared: true,
        path: '/search/:keyword', //search/`{$searchname}
        component: UserProfileListing,
        exact: true
    },
    {
        practice: true,
        shared: true,
        path: '/practice',
        component: PracticeCreatePostContainer,
        exact: true
    },
    {
        shared: false,
        path: '/articles',
        component: Articles,
        exact: true
    },
    {
        shared: false,
        path: '/practice/:id',
        component: PracticeGroup,
        exact: true
    },
    {
        shared: false,
        path: '/practice/post/:post_id',
        component: PracticePostContainer,
        exact: true
    }
]

export default Routes;