import {
    ADD_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION,
    DELETE_SUBSCRIPTION
} from "./_types";


// const addSubscription = () => ({ type: ADD_SUBSCRIPTION})
const changeSubscription = subscription => ({ type: UPDATE_SUBSCRIPTION, payload: subscription })
const deleteSubscription = subscription => ({ type: DELETE_SUBSCRIPTION, payload: subscription })


// export const requestAddSubscription = (subscription) => (dispatch, getState, services) => {
//     const params = {
//         plan: subscription.plan,
//         price: subscription.price,
//         token: localStorage.getItem('twp-token')
//     }
//     let subscription_service = services.get('SubscriptionService');
//     subscription_service.addSubscription(subscription)
//         .then((res) => {
//             dispatch(addSubscription(res.data))
//             // return res
//         })
//         .catch((err) => console.log({ err }))
// }

export const requestChangeSubscription = (subscription) => (dispatch, getState, services) => {
    let subscription_service = services.get('SubscriptionService');
    return subscription_service.changeSubscription(subscription)
        .then((res) => {
            dispatch(changeSubscription(res.data))
        })
        .catch((err) => console.log({ err }))
}

export const requestDeleteSubscription = (id) => (dispatch, getState, services) => {
    let subscription_service = services.get('SubscriptionService');
    subscription_service.deleteSubscription(id)
        .then((res) => {
            dispatch(deleteSubscription(id))
        })
        .catch((err) => console.log({ err }))
}


export const requestFreeSubscription = (charge_id) => (dispatch, getState, services) => {
   
    const params = {
        subscription_charge_id : charge_id
    }

    let subscription_service = services.get('SubscriptionService');
    return subscription_service.addFreeSubscriptionPlan(params)
    .then((res) => {
        return res;
    })
    .catch((err) => console.log({ err }))
}