export default class FeedService {

    /**
     * @param {ApiService} apiService
     */
    constructor(apiService) {
        this.api = apiService;
    }

    getFeed(){
        return this.api.get(`user-feed`);
    }
}