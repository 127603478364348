import React, { useContext } from 'react'
import {AppContext} from '../../utils/context'

interface ResponsiveProps {
    children?: any,
    in: 'xs' | 'sm' | 'md' | 'lg' | 'sm-h'
}

export function Hide(props: ResponsiveProps) {
    const {responsive} = useContext(AppContext);
    const match = doMatch(responsive, props.in);

    return <div>{match ? null : props.children}</div>
}

export function Show(props: ResponsiveProps) {
    const {responsive} = useContext(AppContext);
    const match = doMatch(responsive, props.in);

    return <div>{!match ? null : props.children}</div>
}


function doMatch(width: Number, size: any) {
    switch(size) {
        case 'xs':
            return width < 521
        case 'sm':
            return width < 1025
        case 'md':
            return width < 1367
        case 'sm-h':
            return width < 1025 && width > 520
        case 'md-h':
            return width < 1367 && width > 1024
        case 'lg': 
            return width > 1366
      }
}

export default {
    Hide,
    Show
};
