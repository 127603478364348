import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Form,
  Col,
  Row,
  Select,
  Input,
  Avatar,
  Button,
  Icon,
  Card,
  notification,
  message
} from "antd";

import { updateGroup } from "../../../actions/groups";
import ImageUpload from "../../snippets/ImageUpload/ImageUpload";
import { assignmentTypeID } from "../../../utils/statics";
import { groupCategoryID } from "../../../utils/statics";
import { groupTypeID } from "../../../utils/statics";
import { editGroupSubPlans } from "../../../actions/user";
import { fetchGroupsInit } from "../../../actions/groups";

const { Option } = Select;
const { TextArea } = Input;

interface Props {
  handleClose: () => any;
  updateGroup: (params: any) => any;
  form: any;
  loading: boolean;
  groups;
  editGroupSubPlans: () => any;
  fetchGroupsInit: (id: number) => any;
}

const initGroup = {
  description: "",
  group_category: "",
  group_category_id: "",
  group_id: "",
  group_name: "",
  group_types: [
    {
      group_type: "",
      group_type_id: ""
    }
  ],
  icon: "",
  no_of_followers: "",
  subscription_plans: [
    {
      plan: "",
      subscription_id: ""
    }
  ],
  user_joined: "false",
  assignment_type_id: ""
};


function _Form(props: Props) {
  const { id } = useParams();
  const parsedId = parseInt(id ? id : "");
  // let group = find(props.groups, { id: parsedId });
  // group = group ? group : initGroup;
  const [group, setGroup] = useState(initGroup)

  const [imgurl, setImgurl] = useState(group.icon);
  const [groupCategory, setGroupCategory] = useState<any>();
  const [groupType, setGroupType] = useState();
  const [assignmentType, setAssignmentType] = useState<any>();
  const [groupTypes, setGroupTypes] = useState<Array<any>>([]);
  const [updateSubscription, setUpdateSubscription] = useState<Array<any>>([]);
  const [subscriptions, setSubscriptions] = useState<Array<any>>([]);
  const promoCode = 7

  //Subscription plan API fetch
  const getSubscriptions = async () => {
    const subs = await props.editGroupSubPlans();
    setSubscriptions(subs);
    return subs;
  };

  React.useEffect(() => {
    getSubscriptions();
  }, []);


  //Updated group notification
  const openNotification = () => {
    notification.open({
      message: "Success",
      description: "Group Updated!",
      icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
    });
  };


  React.useEffect(() => {
    getSingleGroupDetails();
    setImgurl(group.icon)
  }, [parsedId]);

  React.useEffect(() => {
    setImgurl(group.icon)
  }, [group]);

  //single group
  const getSingleGroupDetails = async () => {
    const subs = await props.fetchGroupsInit(parsedId);
    setGroup(subs);
    return subs;
  };

  function handler(e) {
    props.form.validateFields((err: any, vals: any) => {

      //Done to assign int values to the string key values of the api
      if (vals.group_category_id === "Free") {
        vals.group_category_id = 1;
      } else if (vals.group_category_id === "Public") {
        vals.group_category_id = 2;
      } else if (vals.group_category_id === "Invite Only") {
        vals.group_category_id = 3;
      }

      if (vals.assignment_type_id === "Matches") {
        vals.assignment_type_id = 1;
      } else if (vals.assignment_type_id === "Free Choice") {
        vals.assignment_type_id = 2;
      } else if (vals.assignment_type_id === "none") {
        vals.assignment_type_id = 3;
      }


      if (vals.group_types) {

        if (vals.group_types.includes("WP")) {
          var indexWP = vals.group_types.indexOf("WP");
          vals.group_types[indexWP] = 1;
        }

        if (vals.group_types.includes("Message")) {
          const indexMessage = vals.group_types.findIndex(x => x === "Message")
          vals.group_types[indexMessage] = 2
        }

      }

      if (vals.subscription_ids) {

        if (vals.subscription_ids.includes("Free")) {
          var indexFree = vals.subscription_ids.findIndex(x => x === "Free")
          vals.subscription_ids[indexFree] = 1;

        }

        if (vals.subscription_ids.includes("The Write Practice Pro")) {
          var indexPro = vals.subscription_ids.findIndex(x => x === "The Write Practice Pro")
          vals.subscription_ids[indexPro] = 2;

        }

        if (vals.subscription_ids.includes("The Write Practice Pro Premium")) {
          var indexPremium = vals.subscription_ids.findIndex(x => x === "The Write Practice Pro Premium")
          vals.subscription_ids[indexPremium] = 3;
        }

      }

      if (!err) {
        if (!vals.subscription_ids) {
          const params = {
            ...vals,
            icon: imgurl,
            no_of_followers: group.no_of_followers,
            group_id: parsedId,
            group_types: vals.group_types,
            subscription_ids: "",
          };
          const submit = props.updateGroup(params);
          submit.then((res) => {
            if (res.success === true) {
              openNotification();
              setTimeout(function () {
                window.location.reload();
              }, 1500);
            } else {
              message.success("Group Not Updated");
            }
          });
        } else {
          const params = {
            ...vals,
            icon: imgurl,
            no_of_followers: group.no_of_followers,
            group_id: parsedId,
            group_types: vals.group_types,
            subscription_ids: vals.subscription_ids,
          };
          const submit = props.updateGroup(params);
          submit.then((res) => {
            if (res.success === true) {
              openNotification();
              setTimeout(function () {
                window.location.reload();
              }, 1500);
            } else {
              message.success("Group Not Updated");
            }
          });
        }



      }
    });
  }

  const { getFieldDecorator } = props.form;
  return (
    <Card size="small" style={{ width: 600 }}>
      <div className="area">
        <Form layout="vertical">
          <Row gutter={16} type="flex" align="middle" justify="center">
            <Avatar size={120} src={imgurl} />
          </Row>
          <Row
            className="inner"
            gutter={16}
            type="flex"
            align="middle"
            justify="center"
          >
              <div style={{ marginLeft: 12 }}>
              <ImageUpload
                id="profile_pic"
                folder="images"
                height={800}
                width={800}
                onFileUpload={(imageUrl) => {
                  setImgurl(imageUrl);
                }}
              />
            </div>            
          </Row>
          <Row gutter={8}>
            <Form.Item label="Group Name" htmlFor="group_name">
              {getFieldDecorator("group_name", {
                initialValue: group.group_name,
                rules: [
                  {
                    message: "Can't leave this empty",
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Row>

          <Form.Item label="Group Description" htmlFor="description">
            {getFieldDecorator("description", {
              initialValue: group.description,
            })(<TextArea rows={5}></TextArea>)}
          </Form.Item>

          <Row gutter={8}>
            <Form.Item label="Group Category" htmlFor="group_category_id">
              {getFieldDecorator("group_category_id", {
                initialValue: group.group_category,
                rules: [
                  {
                    required: true,
                    message: "Please Select category!",
                  },
                ],
              })(
                <Select
                  placeholder={group.group_category}
                  onChange={(v) => setGroupCategory((v))}
                >
                  {groupCategoryID.groupCategoryID.map((s) => (
                    <Option
                      key={s.id + "_group_category"}
                      value={s.groupCategory}
                    >
                      {s.groupCategory}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Row>

          <Form.Item htmlFor="group_types">
            {getFieldDecorator("group_types", {
              initialValue: group.group_types.map(d => `${d.group_type}`),
              rules: [
                {
                  required: true,
                  message: "Please Select",
                },
              ],
            })(
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Select Group Type"
                onChange={(v) => setGroupTypes([v])}
              >
                {groupTypeID.groupTypeID.map((s) => (
                  <Option key={s.group_type_id + "_group_types"} value={s.group_type_id}>
                    {s.group_type}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Assignment Type" htmlFor="assignment_type_id">
            {getFieldDecorator("assignment_type_id", {
              initialValue: group.assignment_type_id,
              rules: [
                {
                  required: true,
                  message: "Please Select",
                },
              ],
            })(
              <Select
                placeholder={group.assignment_type_id}
                onChange={(v) => setAssignmentType((v))}
              >
                {assignmentTypeID.assignmentTypeID.map((s) => (
                  <Option key={s.id + "_grouptype"} value={s.id}>
                    {s.assignmentType}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {/* {group.group_category_id === "3" ? ( */}
            <Form.Item label="Subscription Plan" htmlFor="subscription_plans">
              {getFieldDecorator("subscription_ids", {
                initialValue: group.subscription_plans.map(d => `${d.plan}`),
                // rules: [
                //   {
                //     required: true,
                //     message: "Please Select",
                //   },
                // ],
                // valuePropName: 'option',
              })(
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Select Package"
                  onChange={(v) => setUpdateSubscription([v])}
                >
                  {subscriptions.map((s) => (
                    <Option key={s.subscription_id + "_package"}
                      value={s.subscription_id}
                      disabled={promoCode === s.subscription_charge_id} //{currentVal === s.charge} 
                    >
                      {s.plan}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          {/* ) : null} */}

          <Row gutter={8} type="flex">
            <Col>
              <Button onClick={handler} type="primary">
                Confirm changes
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Card>
  );
}

const EditGroupContainer = Form.create({ name: "edit_group" })(_Form);

const mapStoP = (state: any) => ({
  groups: state.groups.list,
  loading: state.groups.loading,
});

const Actions = {
  updateGroup,
  editGroupSubPlans,
  fetchGroupsInit
};

export default connect(mapStoP, Actions)(EditGroupContainer);
