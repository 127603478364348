import React, { useState } from "react";
import { Row, List, Icon, Button, Skeleton, notification, Empty, Typography } from "antd";
import { fetchFeedPosts } from "../../../actions/posts";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import FeedBlock from "../../snippets/listing/FeedBlock";
import FeedCreatePostContainer from "../Posts/FeedCreatePostContainer";
import { fetchUserSubscription } from "../../../actions/user";

import { find } from "lodash";
import Tour from '../Tour/Tour';

interface Bio {
  first_name: string;
  last_name: string;
  subscription_plan: Array<any>;
}

interface Props {
  fetchFeedPosts: (offset: number, limit: number) => any;
  feed: Array<any>;
  groups: Array<any>;
  loading: Boolean;
  bio: Bio;
  fetchUserSubscription: () => any;
}

const { Text } = Typography;


function FeedContainer(props: Props) {
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10;
  const history = useHistory();
  const tourId = localStorage.getItem("home-tour-id");
  const isHome = localStorage.getItem("home-tour")?.toString();
  const r = 1;
  const isHomeNumber = r.toString();

  const [userBio, setUserBio] = useState({
    period: "",
    plan: "",
    price: "",
    subscription_id: "",
  });

  const [userSubscription, setUserSubscription] = useState({
    id: "",
    isExpired: "",
    isValid: "",
    plan: "",
    price: "",
    subscribed_date: "",
    subscription_period: "",
    user_id: "",
    user_subscription_id: "",
  });

  React.useEffect(() => {
    if (props.bio.subscription_plan) {
      let p = find(props.bio.subscription_plan);
      if (p) {
        setUserBio(p);
      }
    }
  }, [props.bio.subscription_plan]);

  React.useEffect(() => {
    if (props.feed.length < 1) {
      invokeFetchFeed(0);
    }
  }, []);

  //Handles Load More button
  const handleLoadMore = () => {
    if (hasMore) {
      invokeFetchFeed(offset + 1).then(() => setOffset(offset + 1));
    }
  };

  //Invokes activity Feed
  const invokeFetchFeed = async (offset) => {
    setLoading(true);
    return await props.fetchFeedPosts(offset, limit).then((res) => {
      setLoading(false);
      if (res.data && res.data.length < limit) {
        setHasMore(false);
      }
      if (res.message === "Expired token. Please login to get a new token") {
        openNotification()
        window.location.reload();
        //Log out when the token expires
        localStorage.removeItem("twp-token");
        history.push("/auth/login");
      }

    });
  };

  const openNotification = () => {
    notification.open({
      message: "Token Expired",
      description: "Login Again",
      icon: <Icon type="exclamation" style={{ color: "#108ee9" }} />,
    });
  };

  //get all Subscriptions
  const getUserSubscriptions = async () => {
    const subs = await props.fetchUserSubscription();
    setUserSubscription(subs);
    return subs;
  };

  React.useEffect(() => {
    getUserSubscriptions();
  }, []);


  return (
    <div className="feed-wrapper">
      <div className="twp-wrapper">
        <Row className="reader">
          {tourId && isHome === isHomeNumber ? <Tour /> : null}
          <div className="area mini-create">
            {userSubscription.isExpired === "true" ? (
              <FeedCreatePostContainer groupId={""} groupName={""} subcriptionExpired={userSubscription.isExpired} />
            ) : (
              <FeedCreatePostContainer groupId={""} groupName={""} />

            )}
          </div>
        </Row>
        <div className="inner"></div>
        <Row className="reader">
          <div className="feed">
            <h3>All posts</h3>
            {props.feed.length > 0 ? (
              <>
                <List dataSource={props.feed} renderItem={FeedBlock} />
                <Skeleton loading={loading} active className="area" />
                {props.feed.length > 0 ? (
                  <>
                    <Row type="flex" justify="center">
                      <Button onClick={handleLoadMore}>Load more</Button>
                    </Row>
                  </>
                ) : null}
              </>
            ) : (
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                  height: 60,
                }}
                description={
                  <Text style={{ fontSize: 12 }} type="secondary"> Follow groups to view posts on your feed</Text>
                }
              >
              </Empty>
            )}
          </div>
        </Row>
      </div>
    </div>
  );
}

const Actions = {
  fetchFeedPosts,
  fetchUserSubscription
};

const mapStateToProps = (state: any) => ({
  feed: state.posts.feed,
  groups: state.user.groups,
  loading: state.posts.loading,
  bio: state.user.bio,
});

export default connect(mapStateToProps, Actions)(FeedContainer);
