import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";

import { Form, Input, Icon, Button, Row, message } from "antd";
import AuthLayout from "../../shared/AuthLayout";

import { signIn } from "../../../actions/auth";

const emailrule = [
  { required: true, message: "Please input email" },
  { type: "email", message: "Enter valid email" },
];

interface SignInProp {
  token: string;
  form: any;
  signIn: (account: SignInForm) => any;
}

type SignInForm = {
  email: string;
  password: string;
  [key: string]: any;
};

function SignIn(props: SignInProp) {
  const location = useLocation();
  const [loading, toggleLoading] = useState(false);
  const history = useHistory();
  let isCourseLink;
  var courseLink;
  const [linkPromoCode, setPromoCode] = useState("")

  React.useEffect(() => {
    isCourseLink = location.search; //capture the query params
    courseLink = new URLSearchParams(isCourseLink).get("source") || "";
    courseLink.slice(0, 9)

    setPromoCode(courseLink)
    //   if (promoCode) {
    //     setVisible(true);
    //     toggleLoading(true)
    //     // validatePromoCode(promoCode)
    //   }
  }, [isCourseLink]);

  const onSignIn = () => {
    props.form.validateFields((err: any, vals: any) => {
      if (!err) {
        toggleLoading(true);
        const { email, password } = vals;
        props
          .signIn({
            email,
            password,
          })
          .then((res) => {
            if (res && res.success) {

              //TWP TOKEN
              localStorage.setItem("twp-token", res.data.access_token);
              localStorage.setItem("subscription-setup", res.data.data.isSubscriptionSetup);
              localStorage.setItem("home-tour-id", res.data.firsttime_logins[0].tour_id);
              localStorage.setItem("home-tour", res.data.firsttime_logins[0].isHome);

              //sent to the Subscription Container
              // const responseBody = {
              //   first_name: res.data.first_name,
              //   last_name: res.data.last_name,
              //   email: res.data.email,
              // };

              //Checks if there is a sub plan in place
              if (res.data.data.isSubscriptionSetup === "false") {

                //  <----CODE FOR REVAMP -----> 
                const thinificURL = `https://thewritepractice.com/the-write-practice-pro-sales-page?first_name=${res.data.first_name}&last_name=${res.data.last_name}&email=${res.data.email}`
                var win = window.open(thinificURL, '_self');
                //<----END ----->

              } else {
                if (linkPromoCode) {
                  const thinificURL = `https://thewritepractice.thinkific.com/api/sso/v2/sso/jwt?jwt=${res.data.thinkific_token}&return_to=${linkPromoCode}`

                  var win = window.open(thinificURL, '_self');
                } else {
                  history.push("/");
                }
              }

              message.success(res.message);

            } else {
              message.warning(res.message || "");
            }
            toggleLoading(false);
          });
      }
    });
  };

  const { getFieldDecorator } = props.form;

  return (
    <AuthLayout>
      <Form>
        <div className="inner">
          <h1 className="title">Login</h1>
        </div>
        <div>
          <Form.Item hasFeedback className="imp">
            {getFieldDecorator("email", {
              rules: emailrule,
            })(
              <Input
                type="email"
                prefix={<Icon type="mail" />}
                placeholder="Email"
                id="email"
              />
            )}
          </Form.Item>
          <Form.Item className="imp">
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Please input password" }],
            })(
              <Input.Password
                placeholder="Password"
                prefix={<Icon type="lock" />}
              />
            )}
          </Form.Item>
          <div className="inner"></div>
          <Row type="flex" justify="space-between" align="middle">
            <Button
              loading={loading}
              type="primary"
              className="btn-c1"
              size="large"
              onClick={onSignIn}
            >
              Sign In
            </Button>
            <Link to="/auth/forgot-password">Forgot your password? </Link>
          </Row>
        </div>
      </Form>
    </AuthLayout>
  );
}

const mapStateToProps = (state: {
  errors: Map<string, any>;
  auth: Map<string, any>;
}) => ({
  token: "", // state.auth.get('token'),
});

const Actions = {
  signIn,
};


const _Form = connect(mapStateToProps, Actions)(SignIn);

export default Form.create({ name: "signIn" })(_Form);


