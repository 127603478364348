import {useEffect, useContext} from 'react'
import {AppContext} from '../../utils/context'

function WithSidebar({children} : {children: any}) {
    const {toggleSidebar} = useContext(AppContext);

    useEffect(() => {
        toggleSidebar(true)
        return () => {
            toggleSidebar(false)
        }
    }, []);

    return children
}

export default WithSidebar
