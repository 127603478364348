import { FETCH_NOTIFICATIONS, FETCH_GROUP_FAILED, FETCH_POST_INITIATE_SUCCESS } from "./_types";

const setNotifications =(notifications) => ({type: FETCH_NOTIFICATIONS, payload: notifications });
const fetchGroupFailed = (err) => ({ type: FETCH_GROUP_FAILED, err });
const postApiInvokeSuccess = () => ({ type: FETCH_POST_INITIATE_SUCCESS });

 export const fetchNotifications = () => (dispatch, getState, services) => {
     const notification_services = services.get("NotificationService")
     return notification_services.getAllNotifications()
     .then((res) => {
         //tracked in the AppContainer to validate and redirect to the login page
         if(res.message === "Expired token. Please login to get a new token") {
             localStorage.setItem("twp-token-is-expired", res.success);
         }
         dispatch(setNotifications(res.data.notifications && res.data.notifications.length > 0 ? res.data : []))
     })
     .catch((err) => {
        dispatch(fetchGroupFailed(err));
      });
 }

export const updateNotificationStatus = (params) => (dispatch, getState, services) => {
    const notification_services = services.get("NotificationService")
    notification_services.updateNotificationStatus(params).then((res) => {
        dispatch(setNotifications(res.data.notifications && res.data.notifications.length > 0 ? res.data : []))
        }).catch((err) => console.log({ err }))
}

export const fetchNotificationSettings = () => (dispatch, getState, services) => {
    const notification_services = services.get("NotificationService")
    return notification_services.notificationSettings()
        .then((res) => {
            return res.data
        }).catch((err) => console.log({ err }))
}

export const saveNotificationSettings = (params) => (dispatch, getState, services) => {
    const notification_services = services.get("NotificationService")
    return notification_services.saveUserNotificationSettings(params)
    .then((res) => {
        // dispatch(postApiInvokeSuccess());
        return res;
    }).catch((err) => console.log({ err }))
}

export const fetchUserSettings = () => (dispatch, getState, services) => {
    const notification_services = services.get("NotificationService")
    return notification_services.fetchUserNotificationSettings()
        .then((res) => {
            return res.data
        }).catch((err) => console.log({ err }))
}