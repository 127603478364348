import React from 'react';
import { Row, Breadcrumb } from "antd";
import { useHistory, Link } from "react-router-dom";

function PracticePath(props: any) {
    const history = useHistory();

    return (
        <div className="area" style={{ maxWidth: '43em' }}>
            <Row style={{ background: "white", padding: 10 }}>
                <Breadcrumb> 
                    <Breadcrumb.Item><Link to={`/articles`}>Articles</Link></Breadcrumb.Item>
                    {props.blogPost ? (
                        <Breadcrumb.Item><span>{props.blogPost}</span>
                        {/* <Link to={""} onClick={history.goBack}>{props.blogPost}</Link> */}
                        </Breadcrumb.Item>
                    ) : null}
                    {props.PracticeName ? (
                        <Breadcrumb.Item>
                           <span>{props.PracticeName}</span>
                        </Breadcrumb.Item>
                    ) : null}
                </Breadcrumb>
            </Row>
        </div>
    );
}

export default PracticePath;
