import React from 'react'
import VerifyEmailContainer from '../../components/containers/Auth/VerifyEmail'
import WithoutHeader from '../../components/shared/WithoutHeader';

const Subscription = () => {
    return (
        <WithoutHeader>
            <VerifyEmailContainer/>
        </WithoutHeader>
    )
};

export default Subscription;