import {merge} from 'lodash';
import {notification} from 'antd';

const environment = process.env.NODE_ENV

export default class ApiService {
    constructor(baseUrl) {
        this._baseUrl = baseUrl;
    }

    warn(msg, desc) {
        if(environment === "development"){
            notification.error({
                message: msg,
                description: desc || "",
                duration: 4,
            })
        }
    }

    get(endpoint, query = {}, options = {}) {
        return this._fetch('GET', endpoint, {}, query, options);
    }

    public(endpoint, query = {}, options = {}) {
        return this._fetchPublic('GET', endpoint, {}, query, options);
    }

    put(endpoint, body = {}, query = {}, options = {}) {
        return this._fetch('PUT', endpoint, body, query, options);
    }

    post(endpoint, body = {}, query = {}, options = {}) {
        return this._fetch('POST', endpoint, body, query, options);
    }

    delete(endpoint, body = {}, query = {}, options = {}) {
        return this._fetch('DELETE', endpoint, body, query = {}, options = {});
    }

    _fetch(method, endpoint, body = {}, query = {}, options = {}) {
        if (Object.keys(query).length > 0) {
            endpoint = `${endpoint}?${this.serialize(query)}`;
        }

        options = merge(
            {},
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('twp-token')
                }
            },
            options,
            {
                method
            }
        );

        if (Object.keys(body).length > 0) {
            options.body = typeof body === 'object' ? JSON.stringify(body) : body;
        }

        return fetch(`${this._baseUrl}${endpoint}`, options)
            .catch((error) => {
                if(navigator.onLine){
                    this.warn('Connection Error') 
                }
            })
            .then((response) => {
                if (response.status >= 500) {
                    // notification.error("Server Error")
                    this.warn(`Server Error`, `${endpoint} : ${response.status} ${response.statusText}`)
                    
                }

                if (response.status >= 400) {
                    this.warn(`Network Error`, `${endpoint} : ${response.status} ${response.statusText}`)
                }
                return response.json();
            });
    }

    _fetchPublic(method, endpoint, body = {}, query = {}, options = {}) {
        if (Object.keys(query).length > 0) {
            endpoint = `${endpoint}?${this.serialize(query)}`;
        }

        return fetch(`${this._baseUrl}${endpoint}`)
            .catch((error) => {
                if(navigator.onLine){
                    this.warn('Connection Error') 
                }
            })
            .then((response) => {
                if (response.status >= 500) {
                    // notification.error("Server Error")
                    this.warn(`Server Error`, `${endpoint} : ${response.status} ${response.statusText}`)
                }

                if (response.status >= 400) {
                    this.warn(`Network Error`, `${endpoint} : ${response.status} ${response.statusText}`)
                }
                return response.json();
            });
    }

    serialize = (obj, prefix) => {
        let str = [];
        for (let [param, value] of obj) {

            let key = prefix ? `${prefix}[${param}]` : param;

            if (value !== null && typeof value === "object") {
                str.push(this.serialize(value, key));
                continue;
            }

            str.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
        return str.join("&");
    };
}

