import React, { useState } from "react";
import { Row, Col, List, Button, Icon, Modal, message } from "antd";
import { Link } from "react-router-dom";
import { filter } from "lodash";

const { confirm } = Modal;

function DraftsContainer() {
  const [drafts, setDrafts] = useState<any>([]);
  const [commentsDrafts, setCommentsDrafts] = useState<any>([]);
  const [unpostedDrafts, setUnPostedDrafts] = useState<any>([]);

  React.useEffect(() => {
    const userId = localStorage.getItem("user-id")

    //Get saved drafts
    const d = localStorage.getItem("twp-drafts") || "";
    const parsed = d ? JSON.parse(d) : [];
    const userDrafts = parsed.filter((a: any) => a.user_id === userId);
    setDrafts(userDrafts);

    // Get saved comment drafts
    const savedComments = localStorage.getItem("twp-comment-drafts") || "";
    const parsedCommentsDradts = savedComments ? JSON.parse(savedComments) : [];
    const userCommentsDrafts = parsedCommentsDradts.filter((a: any) => a.user_id === userId);

    setCommentsDrafts(userCommentsDrafts);

  }, []);

  //Confirmation modal to delete drafts
  const showDeleteConfirm = (id, draftType) => {
    confirm({
      title: "Are you sure you want to delete your draft permanently?",
      icon: <Icon type="exclamation-circle" />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        if (draftType === "wp") {

          const drfts = JSON.parse(localStorage.getItem("twp-drafts") || "")
          const deleteDrafts = filter(drfts, (a) => a.id !== id);

          const parsed = deleteDrafts ? JSON.stringify(deleteDrafts || "") : "";
          localStorage.setItem("twp-drafts", parsed);

          message.success("Draft Deleted");
          setTimeout(function () {
            window.location.reload();
          }, 1000);

        } else {

          const drfts = JSON.parse(localStorage.getItem("twp-comment-drafts") || "")
          const deleteDrafts = filter(drfts, (a) => a.id !== id);

          const parsed = deleteDrafts ? JSON.stringify(deleteDrafts || "") : "";
          localStorage.setItem("twp-comment-drafts", parsed);

          message.success("Draft Deleted");
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        }
      },
    });
  };

  return (
    <Row className="bg-primary bg-c1">
      <div className="twp-wrapper">
        <div className="container">
          <div className="section">
            <Col>
              <h1 className="title">Drafts</h1>
            </Col>
            {unpostedDrafts.length > 0 ? (
              <List
                itemLayout="horizontal"
                dataSource={unpostedDrafts}
                renderItem={(item: any) => (
                  <div className="activity-box">
                    <List.Item>
                      <Link
                        to={{
                          pathname: `/create-post`,
                          state: { draftDetails: item },
                        }}
                      > {item.title === "" ? (
                        <h3>{item.post.replace(/<\/?[^>]+(>|$)/g, "")}</h3>

                      ) : (
                        <h3>{item.title}</h3>

                      )}
                      </Link>
                    </List.Item>
                  </div>
                )}
              />
            ) : (
              <>
                {drafts.length > 0 ? (
                  <List
                    itemLayout="horizontal"
                    dataSource={drafts}
                    renderItem={(item: any) => (
                      <div className="activity-box">
                        <List.Item>
                          <Link
                            to={{
                              pathname: `/create-post`,
                              state: { draftDetails: item },
                            }}
                          >
                            {item.title === "" ? (
                              <h3>{item.post.replace(/<\/?[^>]+(>|$)/g, "")}</h3>

                            ) : (
                              <h3>{item.title}</h3>

                            )}
                          </Link>
                          <div style={{ textAlign: "right" }} onClick={() => showDeleteConfirm(item.id, "wp")}>
                            <Button>
                              <Icon type="delete" />
                            </Button>
                          </div>
                        </List.Item>
                      </div>
                    )}
                  />
                ) : null}
                {commentsDrafts.length > 0 ? (
                  <List
                    itemLayout="horizontal"
                    dataSource={commentsDrafts}
                    renderItem={(item: any) => (
                      <div className="activity-box">
                        <List.Item>
                          <h3>{item.comment}</h3>
                          <div style={{ textAlign: "right" }} onClick={() => showDeleteConfirm(item.id, "comment")}>
                            <Button>
                              <Icon type="delete" />
                            </Button>
                          </div>
                        </List.Item>
                      </div>
                    )}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </Row>
  );
}

export default DraftsContainer;
