export default class PracticeService {
    
    /**
     * @param {ApiService} apiService
     */
    constructor(apiService) {
        this.api = apiService;
    }

    getAllArticles() {
        return this.api.get(`practice/all-articles`)
    }
    getSingleArticle(id) {
        return this.api.public(`practice/article/${id}`)
    }
    getPrivateSingleArticle(id) {
        return this.api.get(`practice/article/${id}`)
    }
    updateArticle(posts) {
        return this.api.put(`practice/edit-article`, posts)
    }
    createPracticePost(posts) {
        return this.api.post(`practice/add-post`, posts);
    }
    getPracticePost(id) {
        return this.api.get(`practice/post/${id}`)
    }
    getPracticePostsByGroup(id,offset, limit) {
        return this.api.get(`practice/posts/group/${id}?offset=${offset}&limit=${limit}`);
    }
    updatePracticePost(posts) {
        return this.api.post(`practice/edit-post`, posts);
    }
    deletePracticePost(post_id) {
        return this.api.delete(`practice/delete-post`, post_id);
    }
    getCommentsByPostId(id) {
        return this.api.get(`practice/comments/${id}`);
    }
    addPracticeComment(critique) {
        return this.api.post('practice/add-comment', critique)
    }
    editPracticeComment(critique) {
        return this.api.post('practice/edit-comment', critique)
    }
    deletePracticeComment(id) {
        return this.api.delete('practice/delete-comment', { comment_id: id})
    }
    getUserPracticePosts(id,offset, limit) {
        return this.api.get(`practice/posts/user/${id}?offset=${offset}&limit=${limit}`);
    }
    getPracticeTempPost(id) {
        return this.api.get(`practice/temp-post/${id}`);
    }
}
