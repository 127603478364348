import {
    FETCH_GROUP,
    FETCH_GROUP_FAILED,
    FETCH_GROUP_SUCCESS,
    TOGGLE_FOLLOW_GROUP,
    FETCH_SINGLE_GROUP
} from "../actions/_types";

import {unionBy} from 'lodash'

const initial_state = {
    loading: false,
    list: [],
    singlegroups: []
}

const fetchGroup = (state) => ({
    ...state,
    loading: true
});

const fetchGroupSuccess = (state, groups) => ({
    ...state,
    loading: false,
    list: groups
});

// const fetchSingleGroupSuccess = (state, singlegroups) => ({
//     ...state,
//     loading: false,
//     singlegroups: singlegroups
// });

const fetchGroupFailed = (state) => ({
    ...state,
    loading: false,
});

const followGroup = (state, groups) => ({
    ...state,
    loading: false,
    list: groups
});

const insertGroup = (state, groups) => {
    let list = unionBy(state.list, groups, 'group_id');
    return {
        ...state,
        loading: false,
        list
    }
};

const groupsReducer = (state = initial_state, action) => {
    switch (action.type) {
        case FETCH_GROUP:
            return fetchGroup(state);
        case FETCH_GROUP_SUCCESS:
            return fetchGroupSuccess(state, action.payload);
        case FETCH_SINGLE_GROUP:
            return insertGroup(state, action.payload);
        case FETCH_GROUP_FAILED:
            return fetchGroupFailed(state);
        case TOGGLE_FOLLOW_GROUP:
            return followGroup(state, action.payload)
        default:
            return state;
    }
};



export default groupsReducer;