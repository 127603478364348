import React from 'react';
import { connect } from "react-redux";

import { Row, Col, Avatar, Modal, Select, Icon, Button, List, message, notification } from 'antd'
import {
    followGroups,
    fetchAllGroups,
    fetchGroupsInit,
    fetchGroupFollowers,
    searchGroupFollowers,
    addRemoveFollowersToGroups,
    deleteGroup
} from "../../../actions/groups";

const { confirm } = Modal;
const { Option } = Select;

interface Props {
    title: any,
    subtitle?: any,
    groupSubtitle?: any,
    numFollowers?: any,
    img?: string,
    handler?: () => any,
    size: number
    groupId?: any
    fetchGroupsInit: (id: number) => any;
    fetchGroupFollowers: (id: number) => any;
    searchGroupFollowers: (responseBody: any) => any;
    addRemoveFollowersToGroups: (responseBody: any) => any;
    responsive? : any;
}

function GroupAvatar(props: Props) {
    const { title, numFollowers, img, handler, size, groupId } = props;
    const [visible, setVisible] = React.useState(false);
    const [searchFollowers, setSearchFollowers] = React.useState<Array<any>>([]);
    const [followerID, setFollowerID] = React.useState<any>();
    const [followers, setFollowers] = React.useState<any>([]);
    // const [group, setGroup] = React.useState(initGroup)

    //Shows Modal
    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    //Search User to Add to Group
    function onChange(value) {
        setFollowerID(value);
    }

    //List of followers to search through
    const getSearchFollowers = async () => {
        const params = {
            group_id: groupId,
        };
        const subs = await props.searchGroupFollowers(params);
        setSearchFollowers(subs);
        return subs;
    };

    React.useEffect(() => {
        // getSingleGroupDetails();
        getGroupFollowersData();
        getSearchFollowers();
    }, [groupId]);

    //List of followers to search through
    //   const getSingleGroupDetails = async () => {
    //     const subs = await props.fetchGroupsInit(groupId);
    //     if (subs) {
    //       setGroup(subs);
    //     }
    //     return subs;
    //   };

    //Gets List of followers on the Group
    const getGroupFollowersData = async () => {
        const subs = await props.fetchGroupFollowers(groupId);
        if (subs) {
            setFollowers(subs);
        }
        return subs;
    };

    //Adds to Add to Group
    const addFollowersToGroup = () => {
        const params = {
            group_id: groupId,
            user_id: followerID,
        };

        const submit = props.addRemoveFollowersToGroups(params)
        submit.then((data: any) => {
            if (data) {
                openNotification()
                setTimeout(function () {
                    window.location.reload();
                }, 1500);
            } else message.info(`Couldn't save group`);;
        });
    };


    //Delete User from Group
    function showDeleteConfirm(value) {
        confirm({
            title: `Are you sure you want to remove user?`,
            icon: <Icon type="exclamation-circle" />,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {

                const params = {
                    group_id: groupId,
                    user_id: value,
                };
                const submit = props.addRemoveFollowersToGroups(params)
                submit.then((res: any) => {
                    if (res.success === true) {
                        message.info(`User Removed from Group`)
                        setTimeout(function () {
                            window.location.reload();
                        }, 1500);
                    } else message.info(`Couldn't save group`);;
                });
            },
        });
    };


    //User Added Success Message
    const openNotification = () => {
        notification.open({
            message: "Success",
            description: "User Added!",
            icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
        });
    };

    return (
        props.responsive === true ? (
            <Row type="flex" align="middle">
            <Col span={5} onClick={handler}>
                <Avatar src={img} size={size}>{img ? "" : title && title.length > 0 ? title[0] : ""}</Avatar>
            </Col>
            <Col  span={17} className="twp-profile" style={{ marginLeft: size / 4 }}>
                <Row>
                    <p className="twp-profile-title" style={{ fontSize: 15, wordWrap: "break-word", overflow: "break-word" }} dangerouslySetInnerHTML={{ __html: title }}></p>
                </Row>
                <p className="group-avatar-subtitle" dangerouslySetInnerHTML={{ __html: numFollowers }} onClick={showModal}></p>
            </Col>
            <Col className="twp-profile" style={{ marginLeft: size / 4 }}>
            </Col>
            <Modal
                title={`${title}` + " followers"}
                visible={visible}
                onCancel={handleCancel}
            >
                <Row style={{ paddingBottom: 10 }}>
                    <div>
                        <Col span={18}>
                            <Select
                                showSearch
                                style={{ width: 300, paddingLeft: 10 }}
                                placeholder="Add followers to the group"
                                optionFilterProp="children"
                                onChange={onChange}
                            >
                                {searchFollowers.map((s) => (
                                    <Option
                                        key={s.user_id + "search_follower"}
                                        value={s.user_id}
                                    >
                                        {s.first_name} {s.last_name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        {followerID ? (
                            <Col span={5}>
                                <Button type="primary" onClick={addFollowersToGroup}>
                                    Add User
                      <Icon type="plus" />
                                </Button>
                            </Col>
                        ) : (
                            <Col span={5}>
                                <Button type="primary" onClick={addFollowersToGroup} disabled>
                                    Add User
                      <Icon type="plus" />
                                </Button>
                            </Col>
                        )}

                    </div>
                </Row>

                <Row>
                    <List
                        // key={index + "_followers"}
                        // id={`follower_list_${d.group_id}`}
                        itemLayout="horizontal"
                        dataSource={followers.followers}
                        renderItem={(a: any, index) => (
                            <List.Item key={index + "_followers"}>
                                <List.Item.Meta
                                    avatar={<Avatar src={a.profile_pic} />}
                                    title={
                                        <a href={`/user/profile/${a.user_id}`}>{a.user}</a>
                                    }
                                />
                                <div>
                                    <Button onClick={() => showDeleteConfirm(a.user_id)}>
                                        {" "}
                                        <Icon type="delete" />
                                    </Button>
                                </div>
                            </List.Item>
                        )}
                    />
                </Row>
            </Modal>
        </Row>

        ):(

            <Row type="flex" align="middle">
            <Col onClick={handler}>
                <Avatar src={img} size={size}>{img ? "" : title && title.length > 0 ? title[0] : ""}</Avatar>
            </Col>
            <Col className="twp-profile" style={{ marginLeft: size / 4 }}>
                <Row>
                    <p className="twp-profile-title" style={{ fontSize: 15, wordWrap: "break-word", overflow: "break-word" }} dangerouslySetInnerHTML={{ __html: title }}></p>
                </Row>
                <p className="group-avatar-subtitle" dangerouslySetInnerHTML={{ __html: numFollowers }} onClick={showModal}></p>
            </Col>
            <Col className="twp-profile" style={{ marginLeft: size / 4 }}>
            </Col>
            <Modal
                title={`${title}` + " followers"}
                visible={visible}
                onCancel={handleCancel}
            >
                <Row style={{ paddingBottom: 10 }}>
                    <div>
                        <Col span={18}>
                            <Select
                                showSearch
                                style={{ width: 300, paddingLeft: 10 }}
                                placeholder="Add followers to the group"
                                optionFilterProp="children"
                                onChange={onChange}
                            >
                                {searchFollowers.map((s) => (
                                    <Option
                                        key={s.user_id + "search_follower"}
                                        value={s.user_id}
                                    >
                                        {s.first_name} {s.last_name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        {followerID ? (
                            <Col span={5}>
                                <Button type="primary" onClick={addFollowersToGroup}>
                                    Add User
                      <Icon type="plus" />
                                </Button>
                            </Col>
                        ) : (
                            <Col span={5}>
                                <Button type="primary" onClick={addFollowersToGroup} disabled>
                                    Add User
                      <Icon type="plus" />
                                </Button>
                            </Col>
                        )}

                    </div>
                </Row>

                <Row>
                    <List
                        // key={index + "_followers"}
                        // id={`follower_list_${d.group_id}`}
                        itemLayout="horizontal"
                        dataSource={followers.followers}
                        renderItem={(a: any, index) => (
                            <List.Item key={index + "_followers"}>
                                <List.Item.Meta
                                    avatar={<Avatar src={a.profile_pic} />}
                                    title={
                                        <a href={`/user/profile/${a.user_id}`}>{a.user}</a>
                                    }
                                />
                                <div>
                                    <Button onClick={() => showDeleteConfirm(a.user_id)}>
                                        {" "}
                                        <Icon type="delete" />
                                    </Button>
                                </div>
                            </List.Item>
                        )}
                    />
                </Row>
            </Modal>
        </Row>
        )
    )
}

const Actions = {
    followGroups,
    fetchAllGroups,
    fetchGroupsInit,
    fetchGroupFollowers,
    searchGroupFollowers,
    addRemoveFollowersToGroups,
    deleteGroup,
};

const mapStateToProps = (state: any) => ({
    groups: state.groups.list,
    posts: state.posts.list,
    loading: state.posts.loading,
    // subs: state.subscription.sub
});

export default connect(mapStateToProps, Actions)(GroupAvatar);

