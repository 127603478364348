import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { filter, sortBy } from "lodash";
import {
  Menu,
  Dropdown,
  Icon,
  Avatar,
  Badge,
  List,
  Tooltip,
  Row,
  Col,
} from "antd";
import {
  fetchNotifications,
  updateNotificationStatus,
} from "../../../../actions/notification";
import { formatTime } from "../../../../utils/helpers";

import { Link } from "react-router-dom";

interface Notification {
  resource_id: number;
  resource_link: string;
  created_at: string;
  message: string;
  isRead: boolean;
  ids_array: string;
}

interface NotificationProps {
  fetchNotifications: () => any;
  updateNotificationStatus: (params: any) => any;
  notifications: any;
  onClick?;
  timeStamp?;
}

function NotificationContainer(props: NotificationProps) {
  const history = useHistory();
  const [count, setCount] = useState(0);
  const [toggleNotifications, setToggleNotifications] =
    useState<boolean>(false);
  const [unRead, setUnread] = useState(0);
  const [sortedNotifications, setSortedNotifications] = useState<any>([]);

  useEffect(() => {
    const initializeInter = setInterval(() => {
      setCount((count) => count + 1);
    }, 60000);

    return () => clearInterval(initializeInter);
  }, []);

  useEffect(() => {
    props.fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (props?.notifications?.notifications) {
      readFirst();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.notifications?.notifications]);

  const handler = (e, a) => {
    const params = { id_array: e };
    setToggleNotifications(!toggleNotifications);

    props.updateNotificationStatus(params);

    history.push(`${a}`);

    setTimeout(function () {
      window.location.reload();
    }, 1000);

    if (a.includes("/post/")) {
      setTimeout(
        () =>
          history.push({
            pathname: `${a}`,
            state: "commentNotification",
          }),
        1000
      );
    } else {
      history.push(`${a}`);
      setTimeout(function () {
        window.location.reload();
      }, 500);
    }
  };

  // function to get the total unread count and sorts the notifications to show the unread notifications first
  const readFirst = async () => {
    const countUnread = filter(
      props.notifications.notifications,
      (c) => c.isRead === "false"
    );
    setUnread(countUnread.length);

    const unreadInitial = sortBy(props.notifications.notifications, [
      function (o) {
        return o.isRead === "true";
      },
    ]);
    setSortedNotifications(unreadInitial);
  };

  //Mark All Notifications As Read
  const readAllNotifications = () => {
    const notIdArray: string[] = [];

    const countUnread = filter(
      props.notifications.notifications,
      (c) => c.isRead === "false"
    );
    let result = countUnread.map(({ id_array }) => id_array);

    result.map((a) => {
      notIdArray.push(...a);
    });

    const params = {
      id_array: notIdArray,
    };

    props.updateNotificationStatus(params);
  };

  return props.notifications ? (
    <Dropdown
      overlay={
        <Menu className="header-dropdown">
          <div className="notification-header">
            <Row>
              <Col span={16}>
                <h2>
                  {" "}
                  Notifications<p>hello</p>
                </h2>
              </Col>
              <Col span={8}>
                <h4
                  style={{ marginTop: 5, color: "#E15A00", cursor: "pointer" }}
                  onClick={() => readAllNotifications()}
                >
                  Mark all as read
                </h4>
              </Col>
            </Row>
          </div>
          <div className="twp-notifications">
            <List
              id={`notification_list_` + 1}
              dataSource={sortedNotifications}
              renderItem={(grp: any, i) =>
                // grp.message && grp.resource_id ? (
                grp && grp.message ? (
                  <List.Item key={i + "_notifications"}>
                    {grp.isRead === "true" ? (
                      <div className="read-notifications link-a">
                        <a
                          className="link-a imp"
                          onClick={() =>
                            handler(grp.id_array, grp.resource_link)
                          }
                        >
                          {" "}
                          {grp.message}
                        </a>
                        <br />
                        <label className="twp-profile-subtitle">
                          {formatTime(grp.created_at)}
                        </label>
                      </div>
                    ) : (
                      <div className="un-read-notifications">
                        <Badge className="read-badge" color="#E15A00" />
                        <a
                          className="link-a imp"
                          onClick={() =>
                            handler(grp.id_array, grp.resource_link)
                          }
                        >
                          {" "}
                          {grp.message}
                        </a>
                        <br />
                        <label className="twp-profile-subtitle">
                          {formatTime(grp.created_at)}
                        </label>
                      </div>
                    )}
                  </List.Item>
                ) : (
                  <></>
                )
              }
            ></List>
          </div>
        </Menu>
      }
      trigger={["click"]}
    >
      <Badge count={props.notifications.notification_count}>
        <Tooltip title="Notifications">
          <Avatar
            className="notif-btn"
            size={32}
            shape="square"
            icon={<Icon type="bell" />}
          />
        </Tooltip>
      </Badge>
    </Dropdown>
  ) : (
    <Dropdown
      overlay={
        <Menu className="header-dropdown">
          <div className="notification-header">
            <div className="inner">
              <p>No Notifications</p>
            </div>
          </div>
        </Menu>
      }
      trigger={["click"]}
    >
      <Badge count={0}>
        <Avatar
          className="notif-btn"
          size={32}
          shape="square"
          icon={<Icon type="bell" />}
        />
      </Badge>
    </Dropdown>
  );
}

const Actions = {
  fetchNotifications,
  updateNotificationStatus,
};

const mapStP = (state: any) => ({
  notifications: state.nots.notifications,
});

export default connect(mapStP, Actions)(NotificationContainer);
