import {
    FETCH_COURSE,
    FETCH_COURSE_SUCCESS,
    FETCH_COURSE_FAILED
} from "../actions/_types";

const initial_state = {
    loading: false,
    list: []
}


const fetchCourse = (state) => ({
    ...state,
    loading: true
});

const fetchCoursesSuccess = (state, courses) => ({
    ...state,
    loading: false,
    list: courses
});


const fetchCoursesFailed = (state) => ({
    ...state,
    loading: false
});

const courseReducer = (state = initial_state, action) => {
    switch (action.type) {
        case FETCH_COURSE:
            return fetchCourse(state);
        case FETCH_COURSE_SUCCESS:
            return fetchCoursesSuccess(state, action.payload);
        case FETCH_COURSE_FAILED:
            return fetchCoursesFailed(state);
        default:
            return state;
    }
};

export default courseReducer;