import React from 'react'
import SubscriptionContainer from '../../components/containers/Auth/SubscriptionContainer'
import WithoutHeader from '../../components/shared/WithoutHeader';

const Subscription = () => {
    return (
        <WithoutHeader>
            <SubscriptionContainer/>
        </WithoutHeader>
    )
};

export default Subscription;