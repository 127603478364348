export default class NotificationService {

    /**
     * @param {ApiService} apiService
     */
    constructor(apiService) {
        this.api = apiService;
    }

    getAllNotifications(){
        return this.api.get(`notifications`);
    }

    updateNotificationStatus(badge){
        return this.api.put(`update-notification-status`, badge)
    }
    notificationSettings(){
        return this.api.get(`notification-settings`)
    }
    saveUserNotificationSettings(settings) {
        return this.api.post(`save-user-notification-settings`, settings)
    }
    fetchUserNotificationSettings(){
        return this.api.get(`user-notification-settings`)
    }
    
}       
