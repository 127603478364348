import React from "react";
import { Input, Alert, Form } from "antd";
import { Editor } from "react-draft-wysiwyg";

import {
  EditorState,
  ContentState,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const { TextArea } = Input;

const initMessagePst = {
  post: "",
};

interface Message {
  post: string;
}

interface Props {
  onChange: ({ }: Message) => void;
  post: Message;
}

function EditableMessageEditor(props: Props) {
  const { post } = props;
  const [obj, setObj] = React.useState<Message>(post || initMessagePst);
  const [editor, setEditor] = React.useState(EditorState.createEmpty());

  console.log("editingggg", post)

  React.useEffect(() => {
    if (props.post && props.post.post) {
      const parsed = htmlToDraft(props.post.post.toString());
      const conversion = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          parsed.contentBlocks,
          parsed.entityMap
        )
      );
      console.log("setEditor", conversion)

      setEditor(conversion);
    }
  }, [post]);

  React.useEffect(() => {
    const text = convertToRaw(editor.getCurrentContent());
    const html = draftToHtml(text);

    //checks length
    const s = text.blocks[0]
      ? text.blocks[0].text.split(" ").splice(0, 40).join(" ").toString()
      : "";
    const t = text.blocks.reduce((acc, r) => `${r.text} ${acc}`, "");
    const f = t.trim().split(/\s+/).length;

    setObj({
      ...obj,
      post: html,
    });

  }, [editor]);

  
  React.useEffect(() => {
    props.onChange(obj);
  }, [obj]);

  return (
    <>
      <Form name="writing-piece-form">
        <Form.Item>
          {/* <TextArea
            value={obj.title}
            onChange={(e) => setObj({ ...obj, title: e.target.value })}
            rows={6}
            placeholder="Write your message here"
          /> */}
      <Editor
        handlePastedText={() => false}
        editorState={editor}
        onEditorStateChange={setEditor}
        toolbar={{
          options: ["inline", "link", "list"],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          list: {
            // className: "post-container",
            options: ["unordered", "ordered"],
          },
        }}
        wrapperClassName="twp-editor-wrapper"
        editorClassName="twp-editor"
      />
        </Form.Item>
      </Form>
    </>
  );
}

export default EditableMessageEditor;
