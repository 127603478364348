import React from 'react'
import { SearchProvider, SearchBox } from "@elastic/react-search-ui";
import { connector } from "../../utils/teamElasticSearch";
import { connect } from "react-redux";
import {
    Col,
    Input,
    Row,
    Icon,
    Avatar,
    notification,
    Modal
} from "antd";
import { updateUserRole } from "../../actions/user";

const { confirm } = Modal;

function TeamElasticSearch(props: any) {

    function addUserRoles(user_id, user_name) {
        var htmlFreePost = user_name.replace(/<\/?[^>]+(>|$)/g, "");

        confirm({
            title: `Add ${htmlFreePost} as an admin?`,
            icon: <Icon type="exclamation-circle" />,
            onOk() {
                const params = {
                    user_id: user_id,
                    user_role_id: 2,
                };
                props.updateUserRole(params).then((r) => {
                    openNotification();
                    setTimeout(function () {
                        window.location.reload();
                    }, 1000);
                });
            },
            onCancel() {
            },
        });
    }

    //user added notification
    const openNotification = () => {
        notification.open({
            message: "Success",
            description: "User Added!",
            icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
        });
    };


    const JobsSearchHeader = ({ }) => {
        return (
            <div>
                <SearchBox
                    autocompleteResults={{
                        sectionTitle: "Suggested Results",
                        titleField: "first_name",
                        // urlField: "id"
                    }}
                    autocompleteSuggestions={true}
                    inputView={({ getAutocomplete, getInputProps, getButtonProps, searchTerm }) => (
                        <>
                            <div>
                                <Col span={18}>
                                    <Input
                                        size="large"
                                        style={{ width: 300 }}
                                        {...getInputProps({
                                            placeholder: "Search Here"
                                        })}
                                    />
                                </Col>
                                {getAutocomplete()}
                            </div>
                        </>
                    )}
                    autocompleteView={({ autocompletedResults, getItemProps }) => (
                        <div className="elastic-search-dropdown">
                            {autocompletedResults.map((result, i) => (
                                <Row>
                                    <div
                                        {...getItemProps({
                                            key: result.id.raw,
                                            item: result
                                        })}
                                        style={{
                                            padding: 2,
                                            background: "white",
                                            color: "black",
                                            borderBlockColor: "black",
                                            borderStyle: "solid",
                                            borderColor: "white",
                                            borderRadius: 2,
                                            width: 300,
                                        }}
                                    >
                                        <div>
                                            <div className="search-con" style={{
                                                // width: 300,
                                                background: "whitesmoke",
                                                height: 60,
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                paddingTop: 10,
                                            }}>
                                                <Row type="flex" justify="space-between">
                                                    <Col span={2}>
                                                        <Avatar size={32} src={result.profile_pic.snippet}>
                                                            {result.first_name.snippet.charAt(0)}
                                                        </Avatar>
                                                    </Col>
                                                    <Col span={16} onClick={() => addUserRoles(result.id.raw, result.first_name.snippet)}>
                                                        <span
                                                            className="sui-result__value"
                                                            dangerouslySetInnerHTML={{
                                                                __html: result.first_name.snippet
                                                            }}
                                                        /> {" "}
                                                        <span
                                                            className="sui-result__value"
                                                            dangerouslySetInnerHTML={{
                                                                __html: result.last_name.snippet
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            ))}
                        </div>
                    )}
                />
            </div>
        )
    }

    return (
        <div>
            <SearchProvider
                config={{
                    apiConnector: connector,
                    alwaysSearchOnInitialLoad: true,
                    searchAsYouType: true,
                    debounceLength: 0,
                    autocompleteMinimumCharacters: 3,
                    autocompleteQuery: {
                        // Customize the query for autocompleteResults
                        results: {
                            result_fields: {
                                // Add snippet highlighting
                                first_name: { snippet: { size: 100, fallback: true } },
                                last_name: { snippet: { size: 100, fallback: true } },
                                profile_pic: { snippet: { size: 100, fallback: true } },
                                id: { raw: {} }
                            }
                        },
                        // Customize the query for autocompleteSuggestions
                        suggestions: {
                            types: {
                                // Limit query to only suggest based on "title" field
                                documents: { fields: ["first_name"]["last_name"] }
                            },
                            // Limit the number of suggestions returned from the server
                            size: 10
                        }
                    },
                }}
            >
                <JobsSearchHeader />
            </SearchProvider>

        </div>
    )
}

const Actions = {
    updateUserRole
};

const mapStoP = (state: any) => ({});

export default connect(mapStoP, Actions)(TeamElasticSearch);

