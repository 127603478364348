import React, { useState } from "react";
import { connect } from "react-redux";
import { Icon, List, Typography, Row } from "antd";
import { unionBy } from "lodash";
import { Link } from "react-router-dom";

import SettingsBody from "../../shared/SettingsBody";
import { invokeFetchAssignments } from "../../../actions/app";

const { Text } = Typography;

interface AssignmentContainerProps {
  invokeFetchAssignments: () => any;
  assignments: any;
}

function ActivityFeed(props: AssignmentContainerProps) {
  const [groups, setGroups] = useState<any>([]);
  const [assignments, setAssignments] = useState<any>([]);
  const [completed, setCompleted] = useState<any>([]);

  React.useEffect(() => {
    props.invokeFetchAssignments();
  }, []);

  React.useEffect(() => {
    let as = [];
    const grps = props.assignments
      ? props.assignments.map((a) => {
          const ss = a.Assignments || [];
          as = unionBy(as, ss, "assignment_id");
          return {
            assignments: ss,
            ...a,
          };
        })
      : [];

    const com = as.filter((a: any) => a.completed === "true");

    setCompleted(com);
    setAssignments(as);
    setGroups(grps);
  }, [props.assignments]);

  return assignments.length > 0 ? (
    <SettingsBody active="assignments">
      <div>
        {groups.map((d) =>
          d.group_assignment_type !== "none" ? (
            <div className="twp-assignemnts activity-box">
              <List
                id={`assignment_list_${d.post_id}`}
                header={
                  <div>
                    <h3 className="imp">
                      <Link className="link-a" to={`/post/${d.post_id}`}>
                        {d.title}
                      </Link>
                    </h3>
                    <label>
                      <Link to={`/group/${d.group_id}`}>{d.group_name}</Link>
                    </label>
                    <br />
                    <label>
                      {d.group_assignment_type === "Free Choice" ? (
                        <Text type="secondary">Free Choice</Text>
                      ) : null}
                    </label>
                  </div>
                }
                dataSource={d.assignments}
                renderItem={(a: any, index) =>
                  a.name && a.assignment_post_id ? (
                    <List.Item key={a.assignment_id + "_assignments"}>
                      <div className={a.completed === "true" ? "active" : ""}>
                        {a.completed === "true" ? (
                          <Icon type="check-circle" />
                        ) : null}
                        <a
                          className="link-a"
                          href={`/post/${a.assignment_post_id}`}
                        >
                          {a.title}
                        </a>{" "}
                        by <a className="link-a">{a.name}</a> in{" "}
                        <a className="link-a" href={`/group/${a.group_id}`}>
                          {a.group_name}
                        </a>
                      </div>
                    </List.Item>
                  ) : (
                    <></>
                  )
                }
              ></List>
            </div>
          ) 
          // : groups.length < 10 ? (
          //   <h2 style={{ textAlign: "center" }}>Not Found</h2>
          // ) 
          : null
        )}
      </div>
    </SettingsBody>
  ) : (
    <SettingsBody active="assignments">
      <div className="twp-wrapper inner-l">
        <Row className="reader">
          <h2 style={{ textAlign: "center" }}>No Assignments yet</h2>
        </Row>
      </div>
    </SettingsBody>
  );
}

const Actions = {
  invokeFetchAssignments,
};

const mapStP = (state: any) => ({
  assignments: state.app.assignments,
});

export default connect(mapStP, Actions)(ActivityFeed);
