import React, { useState, useContext } from "react";
import { connect } from "react-redux";
import { Row, List, Col, Result, Button, Icon, Dropdown, Menu, Card, Modal, Select, Input, message, Skeleton } from "antd";
import { useParams, useHistory, Link } from "react-router-dom";
import { filter } from "lodash";

import { fetchPostsByGroupId, fetchFeedPosts } from "../../../actions/posts";
import { invokeCreatePost } from "../../../actions/posts";
import { fetchUserSubscription } from "../../../actions/user";
import { AppContext } from "../../../utils/context";
import { followGroups, fetchAllGroups, fetchGroupsInit, fetchGroupFollowers, searchGroupFollowers, addRemoveFollowersToGroups, deleteGroup} from "../../../actions/groups";
import EditGroupContainer from "./EditGroupContainer";

import PostBlock from "../../snippets/listing/PostBlock";
import ListingSkeleton from "../../snippets/skeleton/Listing";
import MiniCreatePostContainer from "../Posts/MiniCreatePostContainer";
import GroupAvatar from "../../snippets/Avatars/GroupAvatar";

const { confirm } = Modal;

interface Props {
  fetchPostsByGroupId: (id: number, offset: number, limit: number) => any;
  followGroups: (group_id: number) => any;
  invokeCreatePost: (responseBody: any) => any;
  fetchAllGroups: () => any;
  fetchFeedPosts: (offset: number, limit: number) => any;
  fetchGroupsInit: (id: number) => any;
  // fetchGroupFollowers: (id: number) => any;
  // searchGroupFollowers: (responseBody: any) => any;
  // addRemoveFollowersToGroups: (responseBody: any) => any;
  deleteGroup: (responseBody: any) => any;
  groups: any;
  loading: boolean;
  posts: any;
  fetchUserSubscription: () => any;
}

const initGroup = {
  description: "",
  group_category: "",
  group_category_id: "",
  group_id: "",
  group_name: "",
  group_types: [
    {
      group_type: "",
      group_type_id: ""
    }
  ],
  icon: "",
  no_of_followers: "",
  subscription_plans: [
    {
      plan: "",
      subscription_id: ""
    }
  ],
  user_joined: "false"

};


function GroupContainer(props: Props) {

  const limit = 10;
  const { id } = useParams();
  const history = useHistory();
  const { responsive } = useContext(AppContext);

  const parsedId = parseInt(id ? id : "");
  let posts = filter(props.posts, { group: { group_id: parsedId } });

  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [editor, toggleEditor] = React.useState(false);
  const [userSubscription, setUserSubscription] = useState({
    id: "",
    isExpired: "",
    isValid: "",
    plan: "",
    price: "",
    subscribed_date: "",
    subscription_period: "",
    user_id: "",
    user_subscription_id: "",
  });
  const [group, setGroup] = useState(initGroup)
  const [modalvisible, setModalVisible] = useState(false);
  const userRole = localStorage.getItem("twp-user-role");
  const [follow, setFollow] = useState("");

  const helpDesk = `https://thewritepractice.zendesk.com/hc/en-us`
  // var helpDesk = window.open(contactSupport, '_blank');

  React.useEffect(() => {
    if (posts.length < 1) {
      invokeGroupFeed(0);
    }
  }, [parsedId]);

  const handleLoadMore = () => {
    if (hasMore) {
      invokeGroupFeed(offset + 1).then(() => setOffset(offset + 1));
    }
  };

  React.useEffect(() => {
    getSingleGroupDetails();
  }, [parsedId]);


  //List of followers to search through
  const getSingleGroupDetails = async () => {
    const subs = await props.fetchGroupsInit(parsedId);
    if (subs) {
      setGroup(subs);
    }
    return subs;
  };


  React.useEffect(() => {
    toggleEditor(false);

    //set user_joined value to follow variable
    setFollow(group.user_joined)
  }, [group]);


  //Deletes the Group
  function showDeleteGroupConfirm() {
    confirm({
      title: `Are you sure you want to remove the group?`,
      icon: <Icon type="exclamation-circle" />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        props.deleteGroup(parseInt(id ? id : ""));
        message.success("Group Deleted");
        setTimeout(() => history.push("/"), 500);
        setTimeout(function () {
          window.location.reload();
        }, 1500);

        // setTimeout(() => history.push("/"), 3000);
      },
    });
  };

  //Show Group Feed
  const invokeGroupFeed = async (offset) => {

    setLoading(true);
    return await props
      .fetchPostsByGroupId(parseInt(id ? id : ""), offset, limit)
      .then((res) => {
        setLoading(false);
        const posts =
          res.data &&
          res.data.map((item) => ({
            ...item,
            group: item.group,
            user: item.user,
          }));
        if (posts && posts.length < limit) {
          setHasMore(false);
        }
      });
  };

  //Follow Groups
  const followGroup = () => {
    props.followGroups(parsedId).then((r) => {
      props.fetchAllGroups();
      props.fetchFeedPosts(offset, limit);

      if (r.message === "unfollowed successfully") {
        setFollow("false");
        setLoading(true);
      } else {
        setFollow("true");
        setLoading(true);
      }

      //Commented to prevent window reloads on follow
      // setTimeout(() => history.push("/"), 3000);
      // setTimeout(function () {
      //   window.location.reload();
      // }, 1000);
    });
  };

  //get all Subscriptions
  const getUserSubscriptions = async () => {
    const subs = await props.fetchUserSubscription();
    if (subs) {
      setUserSubscription(subs);
    }
    return subs;
  };

  React.useEffect(() => {
    getUserSubscriptions();
  }, []);


  React.useEffect(() => {
    if (props.groups.length > 0 && userRole === "user") {
      checkGroupAccess();
    }
  }, [props.groups]);

  const checkGroupAccess = async () => {
    const grpRestrictions = await props.groups.filter((a: any) => a.id === parsedId)
    if (grpRestrictions === undefined || grpRestrictions.length == 0) {
      setModalVisible(true)
    }
    return grpRestrictions;
  };
  
  // React.useEffect(() => {
  //   if (props.groups.length > 0 && userRole === "user") {
  //     checkGroupAccess();
  //   }
  // }, [props.groups]);


  // const checkGroupAccess = async () => {
  //   const grpRestrictions = await props.groups.filter((a: any) => a.id === parsedId)
  //   if (grpRestrictions === undefined || grpRestrictions.length == 0) {
  //     setModalVisible(true)
  //   }
  //   return grpRestrictions;
  // };
   
   
  return (
    <div className="twp-wrapper">
      <Row className="reader">
        <Col style={{ maxWidth: 600 }}>
          <Row>
            {editor ? (
              <div>
                <Row type="flex" align="middle" gutter={16} className="inner">
                  <Col>
                    <Button onClick={() => toggleEditor(false)}>
                      <Icon type="arrow-left" /> Back
                    </Button>
                  </Col>
                  <Col>
                    <h2 className="imp">Edit Group</h2>
                  </Col>
                </Row>
                <EditGroupContainer />
              </div>
            ) : (
              props.loading ? (
                <Skeleton loading={loading} active className="area" />

              ) : (
                <div className="area">
                  {/* responsive */}
                  {responsive < 541 ? (
                    <Card size="small">
                      <Row>
                        {userRole === "admin" ? (
                          <Col className="inner" span={16}>
                            <GroupAvatar
                              size={50}
                              img={group.icon}
                              title={group.group_name}
                              numFollowers={"<p>" + group.no_of_followers + " members" + "</p>"}
                              groupId={parsedId}
                              responsive={true}
                            />
                          </Col>
                        ) : (
                          <Col className="inner" span={16}>
                            <GroupAvatar
                              size={50}
                              img={group.icon}
                              title={group.group_name}
                              numFollowers={"<p>" + group.no_of_followers + " members" + "</p>"}
                              groupId={parsedId}
                              responsive={true}
                            />
                          </Col>
                        )}
                      </Row>
                      <Row justify="end">
                        {/*following */}
                        <Col className="inner" span={8} style={{ textAlign: "center" }}>
                          <div style={{ marginTop: 8 }}>
                            {follow === "true" ? (
                              <Dropdown.Button
                                trigger={["click"]}
                                overlay={
                                  <Menu>
                                    <Menu.Item
                                      style={{ color: "red" }}
                                      onClick={followGroup}
                                    >
                                      Unfollow
                                    </Menu.Item>
                                  </Menu>
                                }
                              >
                                {" "}
                                Following <Icon type="check" />
                              </Dropdown.Button>
                            ) : (
                              <Button type="primary" onClick={followGroup}>
                                Follow
                                <Icon type="plus" />
                              </Button>
                            )
                            }
                          </div>
                        </Col>

                      </Row>
                      <Row style={{ textAlign: "justify" }}>
                        <h3>{group.description}</h3>
                      </Row>
                      {userRole === "admin" ? (
                        <Row justify="end">
                          {/* <Col span={8}></Col>
                          <Col span={9}></Col> */}
                          <Col span={8} style={{ textAlign: "right" }}>
                            <Button icon="edit" onClick={() => toggleEditor(true)}>Edit</Button>
                          </Col>
                          <Col span={8} style={{ textAlign: "right" }}><Button onClick={() => showDeleteGroupConfirm()}>{" "}<Icon type="delete" /></Button></Col>
                        </Row>
                      ) : null}
                    </Card>

                  ) : (

                    <Card size="small">
                      <Row>
                        {userRole === "admin" ? (
                          <Col className="inner" span={16}>
                            <GroupAvatar
                              size={50}
                              img={group.icon}
                              title={group.group_name}
                              numFollowers={"<p>" + group.no_of_followers + " members" + "</p>"}
                              groupId={parsedId}
                            />
                          </Col>
                        ) : (
                          <Col className="inner" span={16}>
                            <GroupAvatar
                              size={50}
                              img={group.icon}
                              title={group.group_name}
                              numFollowers={"<p>" + group.no_of_followers + " members" + "</p>"}
                              groupId={parsedId}
                            />
                          </Col>
                        )}

                        <Col className="inner" span={8} style={{ textAlign: "right" }}>
                          <div style={{ marginTop: 8 }}>
                            {follow === "true" ? (
                              <Dropdown.Button
                                trigger={["click"]}
                                overlay={
                                  <Menu>
                                    <Menu.Item
                                      style={{ color: "red" }}
                                      onClick={followGroup}
                                    >
                                      Unfollow
                                    </Menu.Item>
                                  </Menu>
                                }
                              >
                                {" "}
                                Following <Icon type="check" />
                              </Dropdown.Button>
                            ) : (
                              <Button type="primary" onClick={followGroup}>
                                Follow
                                <Icon type="plus" />
                              </Button>
                            )
                            }
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ textAlign: "justify" }}>
                        <h3>{group.description}</h3>
                      </Row>
                      {userRole === "admin" ? (
                        <Row justify="end">
                          <Col span={8}></Col>
                          <Col span={9}></Col>
                          <Col span={4} style={{ textAlign: "right" }}>
                            <Button icon="edit" onClick={() => toggleEditor(true)}>Edit</Button>
                          </Col>
                          <Col span={3} style={{ textAlign: "right" }}><Button onClick={() => showDeleteGroupConfirm()}>{" "}<Icon type="delete" /></Button></Col>
                        </Row>
                      ) : null}
                    </Card>
                  )}

                </div>
              )
            )}
            <div className="inner" />

          </Row>
          <div className="area">
            {userSubscription.isExpired === "true" ? (
              <MiniCreatePostContainer
                groupId={parsedId}
                groupType={group.group_types}
                subcriptionExpired={userSubscription.isExpired}
              // grpWordCount={grpWordCount.word_count}
              />
            ) : (
              <MiniCreatePostContainer
                groupId={parsedId}
                groupType={group.group_types}
              // grpWordCount={grpWordCount.word_count}
              />
            )}
          </div>
          <div className="inner" />
          <div>
            {group.group_name ? <h3>{group.group_name}'s Feed</h3> : null}
            {props.loading ? (
              <ListingSkeleton count={2} />
            ) : (
              <>
                <List dataSource={posts} renderItem={PostBlock} />
                {posts.length > 0 ? (
                  <Row type="flex" justify="center">
                    <Button onClick={handleLoadMore}>Load more</Button>
                  </Row>
                ) : null}

              </>
            )}
          </div>
          {/* </Row> */}
        </Col>
      </Row>
      {/* {groupAccess === false ? (/ */}
      <Modal
        visible={modalvisible}
        footer={null}
        maskClosable={false}
        closable={false}
        width={820}
        style={{ top: 200 }}
        bodyStyle={{ height: 400 }}
      >
        <div style={{ paddingTop: 30 }}>
          <Result
            subTitle="Sign up for the appropriate community to view this group."
            extra={[
              <>
                <Col>
                  <Link to="/">Home</Link>
                </Col>
                <Col>
                  <a href={helpDesk} target="_blank" rel="noopener noreferrer">Contact Support</a>
                </Col>
              </>
            ]}
          />
        </div>
      </Modal>
      {/* ) : null} */}
    </div>

  );
}

const Actions = {
  fetchPostsByGroupId,
  followGroups,
  fetchAllGroups,
  invokeCreatePost,
  fetchFeedPosts,
  fetchGroupsInit,
  fetchGroupFollowers,
  searchGroupFollowers,
  addRemoveFollowersToGroups,
  deleteGroup,
  fetchUserSubscription
};

const mapStateToProps = (state: any) => ({
  groups: state.groups.list,
  posts: state.posts.list,
  loading: state.posts.loading,
  // subs: state.subscription.sub
});

export default connect(mapStateToProps, Actions)(GroupContainer);
