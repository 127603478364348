import React from 'react'
import PracticePostContainer from '../../components/containers/Practice/PracticePostContainer'
import WithSidebar from '../../components/shared/WithSidebar'

const PracticeGroup = () => {
    return (
        <WithSidebar>
        <PracticePostContainer />
        </WithSidebar>
    )
};

export default PracticeGroup;