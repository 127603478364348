import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Form,
    Col,
    Row,
    Select,
    Input,
    Avatar,
    Button,
    Icon,
    Card,
    notification,
    message,
    InputNumber
} from "antd";

import { updateArticle } from "../../../actions/practice";
import ImageUpload from "../../snippets/ImageUpload/ImageUpload";
import { assignmentTypeID } from "../../../utils/statics";
import { groupTypeID } from "../../../utils/statics";
import { editGroupSubPlans } from "../../../actions/user";
import { fetchGroupsInit } from "../../../actions/groups";

const { Option } = Select;
const { TextArea } = Input;

interface Props {
    handleClose: () => any;
    updateArticle: (params: any) => any;
    form: any;
    loading: boolean;
    groups;
    articles: Array<any>;
    editGroupSubPlans: () => any;
    fetchGroupsInit: (id: number) => any;
}

const initGroup = {
    description: "",
    group_category: "",
    group_category_id: "",
    group_id: "",
    group_name: "",
    word_count: "",
    group_types: [
        {
            group_type: "",
            group_type_id: ""
        }
    ],
    icon: "",
    no_of_followers: "",
    subscription_plans: [
        {
            plan: "",
            subscription_id: ""
        }
    ],
    user_joined: "false",
    assignment_type_id: ""
};


function _Form(props: Props) {
    const { id } = useParams();
    const parsedId = parseInt(id ? id : "");

    const [group, setGroup] = useState(initGroup)
    const [imgurl, setImgurl] = useState(group.icon);
    const [assignmentType, setAssignmentType] = useState<any>();
    const [groupTypes, setGroupTypes] = useState<Array<any>>([]);


    //Updated group notification
    const openNotification = () => {
        notification.open({
            message: "Success",
            description: "Group Updated!",
            icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
        });
    };


    React.useEffect(() => {
        getSingleGroupDetails();
        setImgurl(group.icon)
    }, [parsedId]);

    React.useEffect(() => {
        setImgurl(group.icon)
    }, [group]);

    //single group
    const getSingleGroupDetails = async () => {
        const subs = await props.fetchGroupsInit(parsedId);
        setGroup(subs);
        return subs;

    };

    function handler(e) {
        props.form.validateFields((err: any, vals: any) => {


            if (vals.assignment_type_id === "Matches") {
                vals.assignment_type_id = 1;
            } else if (vals.assignment_type_id === "Free Choice") {
                vals.assignment_type_id = 2;
            } else if (vals.assignment_type_id === "none") {
                vals.assignment_type_id = 3;
            }


            if (vals.group_types) {

                if (vals.group_types.includes("WP")) {
                    var indexWP = vals.group_types.indexOf("WP");
                    vals.group_types[indexWP] = 1;
                }

                if (vals.group_types.includes("Message")) {
                    const indexMessage = vals.group_types.findIndex(x => x === "Message")
                    vals.group_types[indexMessage] = 2
                }

            }


            if (!err && vals) {

                const params = {
                    group_id: parsedId,
                    group_name: vals.group_name,
                    icon: vals.icon ? vals.icon : "",
                    description: vals.description,
                    word_count: vals.word_count,
                    group_types: vals.group_types,
                }

                const submit = props.updateArticle(params);
                openNotification();
                setTimeout(function () {
                    window.location.reload();
                }, 1500);
            }
        });
    }

    const { getFieldDecorator } = props.form;
    return (
        <Card size="small" style={{ width: 600 }}>
            <div className="area">
                <Form layout="vertical">
                    <Row gutter={16} type="flex" align="middle" justify="center">
                        <Avatar size={120} src={imgurl} />
                    </Row>
                    <Row
                        className="inner"
                        gutter={16}
                        type="flex"
                        align="middle"
                        justify="center"
                    >
                        <div style={{ marginLeft: 12 }}>
                            <ImageUpload
                                id="profile_pic"
                                folder="images"
                                height={800}
                                width={800}
                                onFileUpload={(imageUrl) => {
                                    setImgurl(imageUrl);
                                }}
                            />
                        </div>
                    </Row>
                    <Row gutter={8}>
                        <Form.Item label="Group Name" htmlFor="group_name">
                            {getFieldDecorator("group_name", {
                                initialValue: group.group_name,
                                rules: [
                                    {
                                        message: "Can't leave this empty",
                                    },
                                ],
                            })(<Input />)}
                        </Form.Item>
                    </Row>
                    <Row gutter={8}>
                        <Form.Item label="Word Count" htmlFor="word_count">
                            {getFieldDecorator("word_count", {
                                initialValue: group.word_count,
                                // rules: [
                                //     {
                                //         message: "Can't leave this empty",
                                //     },
                                // ],
                            })(<Input />)}
                        </Form.Item>
                    </Row>
                    <Form.Item label="Group Description" htmlFor="description">
                        {getFieldDecorator("description", {
                            initialValue: group.description,
                        })(<TextArea rows={5}></TextArea>)}
                    </Form.Item>
                    <Form.Item htmlFor="group_types">
                        {getFieldDecorator("group_types", {
                            initialValue: group.group_types.map(d => `${d.group_type}`),
                            rules: [
                                {
                                    required: true,
                                    message: "Please Select",
                                },
                            ],
                        })(
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Select Group Type"
                                onChange={(v) => setGroupTypes([v])}
                            >
                                {groupTypeID.groupTypeID.map((s) => (
                                    <Option key={s.group_type_id + "_group_types"} value={s.group_type_id}>
                                        {s.group_type}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="Assignment Type" htmlFor="assignment_type_id">
                        {getFieldDecorator("assignment_type_id", {
                            initialValue: group.assignment_type_id,
                            rules: [
                                {
                                    required: true,
                                    message: "Please Select",
                                },
                            ],
                        })(
                            <Select
                                placeholder={group.assignment_type_id}
                                onChange={(v) => setAssignmentType((v))}
                            >
                                {assignmentTypeID.assignmentTypeID.map((s) => (
                                    <Option key={s.id + "_grouptype"} value={s.id}>
                                        {s.assignmentType}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Row gutter={8} type="flex">
                        <Col>
                            <Button onClick={handler} type="primary">
                                Confirm changes
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Card>
    );
}

const EditPracticeroupContainer = Form.create({ name: "edit_group" })(_Form);

const mapStoP = (state: any) => ({
    groups: state.groups.list,
    loading: state.groups.loading,
    articles: state.posts.articles,
});

const Actions = {
    updateArticle,
    editGroupSubPlans,
    fetchGroupsInit
};

export default connect(mapStoP, Actions)(EditPracticeroupContainer);
