import React, { useState } from "react";
import { connect } from "react-redux";
import { find } from "lodash";
import { Row, Switch, Table, message, Skeleton } from "antd";
import { fetchNotificationSettings, saveNotificationSettings, fetchUserSettings } from "../../../../actions/notification";
import SettingsBody from "../../../shared/SettingsBody";

interface NotificationProps {
  fetchNotificationSettings: () => any;
  saveNotificationSettings: (responseBody: any) => any;
  fetchUserSettings: () => any;
  notifications: any
  loading: boolean;
}

var notificationSettings = {
  notificationSettings: [{
    notification_settings_id: 1,
    isInSiteNotificationActive: false,
    isEmailNotificationActive: false
  }, {
    notification_settings_id: 2,
    isInSiteNotificationActive: false,
    isEmailNotificationActive: false
  }, {
    notification_settings_id: 3,
    isInSiteNotificationActive: false,
    isEmailNotificationActive: false
  }, {
    notification_settings_id: 4,
    isInSiteNotificationActive: false,
    isEmailNotificationActive: false
  }, {
    notification_settings_id: 5,
    isInSiteNotificationActive: false,
    isEmailNotificationActive: false
  }, {
    notification_settings_id: 6,
    isInSiteNotificationActive: false,
    isEmailNotificationActive: false
  }, {
    notification_settings_id: 7,
    isInSiteNotificationActive: false,
    isEmailNotificationActive: false
  }, {
    notification_settings_id: 8,
    isInSiteNotificationActive: false,
    isEmailNotificationActive: false
  }, {
    notification_settings_id: 9,
    isInSiteNotificationActive: false,
    isEmailNotificationActive: false
  }, {
    notification_settings_id: 10,
    isInSiteNotificationActive: false,
    isEmailNotificationActive: false
  }]
}

function NotificationContainer(props: NotificationProps) {
  // const [form] = Form.useForm();

  const [notSettings, setNotSettings] = useState<Array<any>>([]);
  const [userSettings, setUserSettings] = useState(notificationSettings.notificationSettings);
  const [emailSettings, setEmailSettings] = useState(notificationSettings.notificationSettings);
  const [called, setCalled] = useState(false);
  const [notificationObject, setObject] = React.useState(notSettings);
  const stringToBoolean = (string) => string === "false" ? false : true;
  const someRef = React.useRef(0);

  let timer: any = null;
  const [countOfProgess, setCountOfProgess] = useState(0);
  const [localLoading, setLocalLoading] = useState(props.loading);

  // loader 
  React.useEffect(() => {
    someRef.current = timer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timer = setInterval(() => {
      if (props.loading && countOfProgess > 100) setLocalLoading(true);
      setCountOfProgess(oldProgress => Math.min(oldProgress + 1, 100));
    }, 25);
    return () => clearInterval(timer);
  }, []);
  if (countOfProgess > 100) clearInterval(timer);



  //Fetch Notifcation Settings
  const getSettings = async () => {
    const subs = await props.fetchNotificationSettings();
    setNotSettings(subs);
    return subs;
  };

  //Fetch Saved User Notification Settings
  const getUserSettings = async () => {
    const subs = await props.fetchUserSettings();
    setUserSettings(subs[0].notification_settings);
    return subs;
  };

  React.useEffect(() => {
    getSettings();
  }, []);

  React.useEffect(() => {
    getUserSettings();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [called]);

  React.useEffect(() => {
    combineSettings();
  }, [userSettings]);

  const combineSettings = async () => {
    let arr3 = notSettings.map((item, i) => Object.assign({}, item, userSettings[i]));
    setEmailSettings(arr3)
  };

  React.useEffect(() => {

    var result = userSettings.map(function (obj) {
      return {
        isEmailNotificationActive: stringToBoolean(obj.isEmailNotificationActive),
        isInSiteNotificationActive: stringToBoolean(obj.isInSiteNotificationActive),
        notification_settings_id: obj.notification_settings_id
      };
    });
    setObject(result)
  }, [userSettings]);

  function onChange(checked, record, type, index) {

    //find the object that matches with the changed switch id in the array of objects
    const cmnt = find(notificationObject, { notification_settings_id: record });

    if (cmnt) {
      cmnt[type] = checked; //removed stringToBoolean
    }

    var notificationSettings = {
      notificationSettings: [
        cmnt
      ]
    }

    const submit = props.saveNotificationSettings(notificationSettings);

    submit.then((res: any) => {
      if (res.success === true) {
        message.info(`Saved Settings`);
        setCalled(true)
      } else {
        message.info(`Not Successful`);
      }
    });
  }

  const userSetcolumns = [
    {
      title: "Name & Description",
      dataIndex: "notification_type",
      key: "notification_type",
      render: (text, row, record) => <div><><Row>{text}</Row><Row>{row.description}</Row></></div>
    },
    {
      title: "Insite Notification",
      dataIndex: "insite",
      key: "insite",
      render: (record, row, index) => (
        <div>
          {row.isInSiteNotificationActive === "true" ? (
            <Switch defaultChecked={true} onChange={(v) => onChange(v, row.notification_settings_id, "isInSiteNotificationActive", index)} />
          ) : null}
          {row.isInSiteNotificationActive === "false" ? (
            <Switch defaultChecked={false} onChange={(v) => onChange(v, row.notification_settings_id, "isInSiteNotificationActive", index)} />
          ) : null}
        </div>
      )
    },
    {
      title: "Email Notification",
      dataIndex: "email",
      key: "email",
      render: (record, row, index) => (
        <div>
          {row.isEmailNotificationActive === "true" ? (
            <Switch defaultChecked={true} onChange={(v) => onChange(v, row.notification_settings_id, "isEmailNotificationActive", index)} />
          ) : null}
          {row.isEmailNotificationActive === "false" ? (
            <Switch defaultChecked={false} onChange={(v) => onChange(v, row.notification_settings_id, "isEmailNotificationActive", index)} />
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <SettingsBody active="notifications">
      {countOfProgess < 100 ? (
        <div className="loading-animation-container">
          <Skeleton active />
          {/* <div style={{ width: 170 }}>
          {/* <Progress type="circle" status="active" strokeLinecap="square" percent={countOfProgess} showInfo={false} width={80} /> */}
          {/* </div> */}
        </div>
      ) : countOfProgess === 100 ? (
        <div className="twp-notifications">
          <Table
            pagination={false}
            columns={userSetcolumns}
            dataSource={emailSettings}
          />
        </div>
      ) : null
      }
    </SettingsBody>
  )
}

const Actions = {
  fetchNotificationSettings,
  saveNotificationSettings,
  fetchUserSettings
};

const mapStP = (state: any) => ({
  notifications: state.nots.notifications,
  loading: state.posts.loading,

});

export default connect(mapStP, Actions)(NotificationContainer);
