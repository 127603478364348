import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { Input, Form, Icon, Button, Result, message } from "antd";
import { forgotPassword } from "../../../actions/auth";
import AuthLayout from "../../shared/AuthLayout";
import { Link } from "react-router-dom";

const emailRule = [
  {
    type: "email",
    message: "Input value is an invalid email",
  },
  {
    required: true,
    message: "Email cannot be empty",
  },
];

const _Form = (props: any) => {
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleSubmit(e: any) {
    e.preventDefault();
    props.form.validateFields((err: any, values: any) => {
      if (!err && !loading) {
        setLoading(true);
        props.forgotPassword(values.email).then((res) => {
          if (res.success) setSuccess(true);
          else message.warning(res.message);

          setLoading(false);
        });
      }
    });
  }

  const { getFieldDecorator } = props.form;
  return (
    <AuthLayout>
      {!success ? (
        <Form onSubmit={handleSubmit} className="login-form">
          <div className="section">
            <div>
              <h1 className="title">Forgot password ?</h1>
              <p>
                Enter relevant email and we'll send you a mail with the password
                reset procedure
              </p>
            </div>
            <Form.Item label="Enter Email">
              {getFieldDecorator("email", {
                rules: emailRule,
              })(<Input prefix={<Icon type="mail" />} placeholder="Email" />)}
            </Form.Item>
            <Form.Item>
              <Button
                className="btn-c1"
                size="large"
                type="primary"
                loading={loading}
                htmlType="submit"
              >
                Proceed
              </Button>
            </Form.Item>
          </div>
        </Form>
      ) : (
        <Result
          status="success"
          title="Success"
          subTitle="An email has been sent to you. Please follow the instructions mentioned to continue the password change procedure"
          extra={[
            <Link to="/auth/login"> Login</Link>
          ]}
        />
      )}
    </AuthLayout>
  );
};

const Actions = {
  forgotPassword,
};

//Connect to redux store
const FPStore = connect(null, Actions)(_Form);

//Connect to Form and export
export default Form.create({ name: "forgot_password" })(FPStore);
