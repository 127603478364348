import {
    FETCH_COURSE,
    FETCH_COURSE_SUCCESS,
    FETCH_COURSE_FAILED
} from "./_types";

const fetchCourses = () => ({ type: FETCH_COURSE });
const invokeCourseSuccess = () => ({ type: FETCH_COURSE_SUCCESS });
const fetchCourseFailed = (err) => ({ type: FETCH_COURSE_FAILED, err });
const fetchCourseSuccess = (course) => ({ type: FETCH_COURSE_SUCCESS, payload: course });

export const invokeCreateCourse = (params) => (dispatch, getState, services) => {
    dispatch(fetchCourses());
    let course_service = services.get("CourseService");
    return course_service
        .createCourse(params)
        .then((res) => {
            dispatch(invokeCourseSuccess)
            return res.success;
        })
        .catch((err) => {
            dispatch(fetchCourseFailed())
            return null
        });
}

export const getAllCourses = () => (dispatch, getState, services) => {
    dispatch(fetchCourses());
    let course_service = services.get("CourseService");
    return course_service
        .fetchCourses()
        .then((res) => {
            dispatch(fetchCourseSuccess(res.data))
        })
        .catch((err) => {
            dispatch(fetchCourseFailed(err))
        });
}

export const getSingleCourse = (id) => (dispatch, getState, services) => {
    dispatch(fetchCourses());
    let course_service = services.get("CourseService");
    return course_service
      .fetchSingleCourse(id)
      .then((res) => {
        dispatch(fetchCourseSuccess(res.data));
        return res.data[0]
      })
      .catch((err) => {
        dispatch(fetchCourseFailed(err));
      });
  };

  export const updateCourse = (params) => (dispath, getState, services) => {
    let course_service = services.get("CourseService");
    course_service.updateCourse(params).then((res) => {
        return res.data;
    }).catch((err) => console.log({ err }))
  }
  