import React from 'react'
import { SearchProvider, SearchBox } from "@elastic/react-search-ui";
import { connector } from "../../utils/elasticSearch";
import { Col, Input, Avatar, Row, Icon } from "antd";
import { useHistory } from "react-router-dom";

function ElasticSearchHeader(props: any) {
    let history = useHistory();

    //route to post
    function postLinkClick(param) {
        let postClean = param.substring(param.indexOf("|") + 1);
        history.push(`/post/${postClean}`)
        setTimeout(function () {
            window.location.reload();
        }, 500);

        // const responseBody = {
        //     first_name: res.data.first_name,
        //     last_name: res.data.last_name,
        //     email: res.data.email,
        //   };

        // setTimeout(() => history.push({
        //     pathname: `/post/${postClean}`,
        //     state: responseBody
        //   }), 1000);

    }

    //route to post
    function userLinkClick(param) {
        let userClean = param.substring(param.indexOf("|") + 1);
        history.push(`/user/profile/${userClean}`)
        setTimeout(function () {
            window.location.reload();
        }, 500);
    }

    const JobsSearchHeader = ({ }) => {
        return (
            <div>
                <SearchBox
                    autocompleteResults={{
                        sectionTitle: "Suggested Results",
                        titleField: "title",
                        summaryField: "summary"
                        // urlField: "id"
                    }}
                    autocompleteSuggestions={true}
                    inputView={({ getAutocomplete, getInputProps, getButtonProps, searchTerm }) => (
                        <>
                            <div>
                                <Col span={18}>
                                    {props.size ? (
                                        <Input
                                        size="large"
                                        // className="elastic-search-input"
                                        style={{ width: 90 }}
                                        {...getInputProps({
                                            placeholder: "Search Here"
                                        })}
                                    />
                                    ): (
                                        <Input
                                        size="large"
                                        // className="elastic-search-input"
                                        style={{ width: 290 }}
                                        {...getInputProps({
                                            placeholder: "Search Here"
                                        })}
                                    />
                                    )}
                                    
                                </Col>
                                {getAutocomplete()}
                            </div>
                        </>
                    )}
                    autocompleteView={({ autocompletedResults, getItemProps }) => (
                        props.size ? (
                            //when screen size is below 541
                            <div className="elastic-search-dropdown-responsive">
                            {autocompletedResults.map((result, i) => (
                                <div
                                    {...getItemProps({
                                        key: result.id.raw,
                                        item: result
                                    })}
                                    className="elastic-search-inner"
                                    style={{
                                        padding: 6,
                                        background: "white",
                                        color: "black",
                                        // height: "auto", 
                                        borderBlockColor: "black",
                                        borderStyle: "solid",
                                        borderColor: "white",
                                        borderRadius: 2
                                    }}
                                >
                                    <div className="search-box-hover">
                                        {result._meta.engine === "posts" ? (
                                            <Row type="flex" align="middle" gutter={8}>
                                                    <Col span={3}>
                                                    <Icon type="book" style={{ fontSize: '80%'}} />
                                                    </Col>
                                                
                                                <Col span={15}>
                                                <div className="sui-result__value-responsive">
                                                    <span
                                                        onClick={() =>
                                                            postLinkClick(result.id.raw)
                                                        }
                                                        className="sui-result__value-responsive"
                                                        dangerouslySetInnerHTML={{
                                                            __html: result.title.snippet
                                                        }}
                                                    /> {" "}
                                               by <span
                                                        onClick={() =>
                                                            postLinkClick(result.id.raw)
                                                        }
                                                        className="sui-result__value-responsive"
                                                        dangerouslySetInnerHTML={{
                                                            __html: result.user_name.snippet
                                                        }}
                                                    />
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : null}

                                        {result._meta.engine === "users" ? (
                                            <Row type="flex" align="middle" gutter={12}>
                                                 <Col span={4}>
                                                {result.profile_pic.snippet === null ? (
                                                    <Avatar size={20} src="/assets/twp-logo.jpeg" />
                                                ) : (
                                                        <Avatar size={20} src={result.profile_pic.snippet} />
                                                    )}
                                                    </Col>
                                                <Col>
                                                <div className="sui-result__value-responsive">
                                                    <span
                                                        onClick={() =>
                                                            userLinkClick(result.id.raw)
                                                        }
                                                        className="sui-result__value-responsive"
                                                        dangerouslySetInnerHTML={{
                                                            __html: result.first_name.snippet
                                                        }}
                                                    /> {" "}
                                                    <span
                                                        onClick={() =>
                                                            userLinkClick(result.id.raw)
                                                        }
                                                        className="sui-result__value-responsive"
                                                        dangerouslySetInnerHTML={{
                                                            __html: result.last_name.snippet
                                                        }}
                                                    />
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : null}

                                    </div>
                                </div>
                            ))}
                        </div>

                        ):(
                            <div className="elastic-search-dropdown">
                            {autocompletedResults.map((result, i) => (
                                <div
                                    {...getItemProps({
                                        key: result.id.raw,
                                        item: result
                                    })}
                                    className="elastic-search-inner"
                                    style={{
                                        padding: 6,
                                        background: "white",
                                        color: "black",
                                        // height: "auto", 
                                        borderBlockColor: "black",
                                        borderStyle: "solid",
                                        borderColor: "white",
                                        borderRadius: 2
                                    }}
                                >
                                    <div className="search-box-hover">
                                        {result._meta.engine === "posts" ? (
                                            <Row type="flex" align="middle" gutter={12}>
                                                    <Col>
                                                    <Icon type="book" className="search-icon-responsive" />
                                                </Col>
                                                
                                                <Col style={{ width: 250 }}>
                                                    <span
                                                        onClick={() =>
                                                            postLinkClick(result.id.raw)
                                                        }
                                                        className="sui-result__value"
                                                        dangerouslySetInnerHTML={{
                                                            __html: result.title.snippet
                                                        }}
                                                    /> {" "}
                                               by <span
                                                        onClick={() =>
                                                            postLinkClick(result.id.raw)
                                                        }
                                                        className="sui-result__value"
                                                        dangerouslySetInnerHTML={{
                                                            __html: result.user_name.snippet
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        ) : null}

                                        {result._meta.engine === "users" ? (
                                            <Row type="flex" align="middle" gutter={12}>
                                                {result.profile_pic.snippet === null ? (
                                                    <Avatar size={32} src="/assets/twp-logo.jpeg" />
                                                ) : (
                                                        <Avatar size={32} src={result.profile_pic.snippet} />
                                                    )}
                                                <Col style={{ width: 250 }}>
                                                    <span
                                                        onClick={() =>
                                                            userLinkClick(result.id.raw)
                                                        }
                                                        className="sui-result__value"
                                                        dangerouslySetInnerHTML={{
                                                            __html: result.first_name.snippet
                                                        }}
                                                    /> {" "}
                                                    <span
                                                        onClick={() =>
                                                            userLinkClick(result.id.raw)
                                                        }
                                                        className="sui-result__value"
                                                        dangerouslySetInnerHTML={{
                                                            __html: result.last_name.snippet
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        ) : null}

                                    </div>
                                </div>
                            ))}
                        </div>
                        )
                        
                    )}
                />
            </div>
        )
    }

    return (
        <div>

            <SearchProvider
                config={{
                    apiConnector: connector,
                    alwaysSearchOnInitialLoad: true,
                    searchAsYouType: true,
                    debounceLength: 0,
                    autocompleteMinimumCharacters: 3,
                    autocompleteQuery: {
                        // Customize the query for autocompleteResults
                        results: {
                            result_fields: {
                                // Add snippet highlighting
                                title: { snippet: { size: 100, fallback: true } },
                                summary: { snippet: { size: 100, fallback: true } },
                                user_name: { snippet: { size: 100, fallback: true } },
                                first_name: { snippet: { size: 100, fallback: true } },
                                last_name: { snippet: { size: 100, fallback: true } },
                                profile_pic: { snippet: { size: 100, fallback: true } },
                                user_id: { snippet: { size: 100, fallback: true } },
                                id: { raw: {} }
                            }
                        },
                        // Customize the query for autocompleteSuggestions
                        suggestions: {
                            types: {
                                // Limit query to only suggest based on "title" field
                                documents: { fields: ["summary"]["first_name"] }
                            },
                            // Limit the number of suggestions returned from the server
                            size: 10
                        }
                    },
                    initialState: {
                        filters: [
                            {
                                field: 'title',
                                values: ['true'],
                                type: 'all',
                            }
                        ]
                    }
                }}
            >
                <JobsSearchHeader />
            </SearchProvider>

        </div>
    )
}

export default ElasticSearchHeader