export default class AppService {
    /**
    * @param {ApiService} apiService
    */

    constructor(apiService) {
        this.api = apiService;
    }

    fetchAssignments() {
        return this.api.get('assignments');
    }
    getUserProfileDetails(id) {
        return this.api.get(`user/profile/${id}`);
    }
    followUserProfile(user) {
        return this.api.post(`user/follow`, user);
    }
    removeAssignments(id) {
        return this.api.put('clear-assignments', id)
    }
    updateTourStatus(params) {
        return this.api.put(`update-tour-status`, params)
    }
    checkActiveSubscription(email) {
        return this.api.get(`check-active-subscriptions/${email}`, );
    }

}