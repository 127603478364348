import React, { useState, useEffect } from "react";
import { Tabs, Button, Row, notification, Modal } from "antd";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import PracticeEditor from "../../snippets/editors/PracticeEditor";

import { invokeCreatePracticePost } from "../../../actions/practice";
import { getGenre } from "../../../actions/posts"
import { fetchUserSubscription } from "../../../actions/user";

const { TabPane } = Tabs;

const initPost = {
  title: "",
  post: "",
  // feedback: "",
  summary: "",
  // genre_id: 1,
  word_count: ""
};

interface CreatePostProps {
  invokeCreatePracticePost: (responseBody: any) => any;
  group_name: any;
  loading: boolean;
  groupId: any;
  groupType: any;
  subcriptionExpired?;
  type?: any
  pwa_post?: string;
  grpWordCount?: any;
  getGenre: () => any;
  fetchUserSubscription: () => any;
}

function PracticeCreatePostContainer(props: CreatePostProps) {
  const history = useHistory();
  const [tab, setTab] = React.useState("1");
  const [wp, setWp] = React.useState(initPost);
  const [msg, setMsg] = React.useState("");
  const [errors, setErrors] = useState<Array<string>>([]);
  const [wordCount, setWordCount] = useState("");

  const handleSubmit = (e: any) => {

      let params =
        tab == "1"
          ? {
            title: wp.title,
            post: wp.post,
            group_id: props.groupId,
            summary: wp.summary,
            post_type_id: 1,
            word_count: wp.word_count
          }
          : {
            title: msg,
            post: "",
            group_id: props.groupId,
            summary: wp.summary,
            post_type_id: 2,
            word_count: wp.word_count
          };


      const isvalid = validate();

      if (isvalid && !props.loading) {
        const submit = props.invokeCreatePracticePost(params)
        submit.then((post_id: any) => {

          if (post_id || post_id !== null) {
            if (tab === "1") {
              setTimeout(() => assignementsNotifiction("info"), 1500);
            }
            history.push(`/practice/post/${post_id}`);
          } else {
            Modal.error({
              title: "Couldn't save post",
              content: (
                <div>
                  <h4>Please make sure your piece does not contain emojis. Also try copy-and-pasting from a plain text word processor which may remove formatting that interferes with our editor. If you continue to have issues, contact our help desk.</h4>
                  <a target="_blank" href='https://thewritepractice.zendesk.com/hc/en-us/requests/newhttps://www.atticus.io/troubleshooting-synching-errors/' rel="noopener noreferrer">
                    https://thewritepractice.zendesk.com/hc/en-us/requests/new
                  </a>
                </div>
              )
            });
          }
        });
      }
  };

  const validate = () => {
    setErrors([]); //Reset validation to validate again.

    let errs: Array<string> = [];
    const text = wp.post.replace(/<[^>]*>/g, "");

    //VALIDATE
    if (tab == "1") {

      if (!wp.title)
        errs.push("You need to enter a title for your writing piece");

      if (text.trim().split(/\s+/).length < 2) errs.push("Your writing piece is empty");

      if (text.trim().split(/\s+/).length > parseInt(wordCount))
        errs.push(
          "Your writing piece is too long. You cannot post anymore than " +
          wordCount +
          " words"
        );

    } else {
      if (!msg) errs.push("You cannot leave message as empty");
      if (msg.length < 2) errs.push("Your message is empty");

    }
    // Set errors
    setErrors(errs);

    // Return validation
    if (errs.length < 1) return true;

    return false;
  };

 
  useEffect(() => {
        setWordCount(props.grpWordCount);
  }, [props.grpWordCount]);


  //Assignment Alert
  const assignementsNotifiction = (type) => {
    notification[type]({
      message: "New Assignment",
      description:
        "Now that you’ve posted your writing piece, it’s time to critique three pieces by other writers. Check the assignment box for your critique assignment. Happy critiquing!",
        duration: 15,
    });
  };


  return (
    <div>
          <Row type="flex" justify="space-between" align="middle">
          <h3>Get started here</h3>
          </Row>
          <Tabs
            defaultActiveKey="1"
            animated={false}
            onChange={setTab}
            onTabClick={() => setErrors([])}
          >
            <div></div>
            <TabPane tab="Writing piece" key="1">
              <PracticeEditor
                onChange={setWp}
                mini={true}
                draftPost={false}
                word_count={props.grpWordCount}
                post={props.group_name}
              />
            </TabPane>
          </Tabs>
          {errors.length > 0 ? (
            <Row className="inner">
              {errors.map((err, i) => (
                <div key={`err_${i}`} style={{ color: "red" }}>
                  <b>* {err}</b>
                </div>
              ))}
            </Row>
          ) : null}
          <br />
          <Row type="flex">
            <Button
              size="large"
              type="primary"
              onClick={handleSubmit}
              loading={props.loading}
            >
              Submit
            </Button>
          </Row>
        </div>
  );
}

const mapStP = (state: any) => ({
  loading: state.posts.loading
});

const Actions = {
  invokeCreatePracticePost,
  getGenre,
  fetchUserSubscription,
  //   signUp
};

// const _Form = connect(mapStP, Actions)(SignUp);

export default connect(mapStP, Actions)(PracticeCreatePostContainer);
