import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { connect } from "react-redux";

import { Row, Result, Icon } from "antd";

import { verifyEmail } from "../../../actions/auth";

interface Props {
  verifyEmail: (token: string) => any;
}
const VerifyEmail = (props: Props) => {
  const [loading, toggleLoading] = React.useState(false);
  const [state, toggleState] = React.useState(false);
  const { token } = useParams();

  async function submit() {
    if (token) {
      toggleLoading(true);
      const call = props.verifyEmail(token).then((r) => {
        if (r.message === "no verification token exists") {
          toggleState(false);
        } else {
          toggleState(true);
        }
        toggleLoading(false);
      });
    }
  }

  useEffect(() => {
    submit();
  }, []);

  return (
    <Row className="bg-c1" type="flex" align="middle" justify="center">
      {loading ? (
        <h2>
          <Icon type="loading" spin={true} /> Verifying your email
        </h2>
      ) : state ? (
        <Result
          status="success"
          title="Your email is verified!"
          subTitle="If you're logged in, logout and login again to see changes"
          extra={[<Link to="/">Take me home</Link>]}
        />
      ) : (
        <Result
          status="warning"
          title="Email couldn't be verified"
          subTitle="Either the link is expired or the url entered is invalid"
          extra={[<Link to="/">Take me home</Link>]}
        />
      )}
    </Row>
  );
};

const Actions = {
  verifyEmail,
};

export default connect(null, Actions)(VerifyEmail);
