import {useEffect, useContext} from 'react'
import {AppContext} from '../../utils/context'

function WithoutHeader({children} : {children: any}) {
    const {toggleHeader} = useContext(AppContext);

    useEffect(() => {
        toggleHeader(false)
        return () => {
            toggleHeader(true)
        }
    }, []);

    return children
}

export default WithoutHeader
