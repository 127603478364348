import React from 'react'
import WithoutHeader from '../../components/shared/WithoutHeader'
import RegisterContainer from '../../components/containers/Auth/Register'

function Register() {
    return (
        <WithoutHeader>
            <RegisterContainer />
        </WithoutHeader>
    )
}

export default Register;
