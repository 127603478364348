import React from 'react';
import { connect } from "react-redux";

import { Row, Col, Avatar } from 'antd'
interface Props {
    title: any;
    url?: any;
    subtitle?: any;
    description: any;
    groupSubtitle?: any,
    numFollowers?: any;
    img?: string;
    handler?: () => any;
    size: number;
    groupId?: any;
    responsive?: any;
    author?: any;
}

function PracticeGroupAvatar(props: Props) {
    const { title, description, img, handler, size, groupId, author } = props;
    let blogURL = "";

    if (props.url && props.url.substring(0, 4) === "http") {
        blogURL = `${props.url}`
    } else {
        blogURL = `https://thewritepractice.com/${props.url}`
    }

    return (
        props.responsive === true ? (
            <Row type="flex" align="middle" >
                <Col span={4} order={1} onClick={handler}>
                    <Avatar shape="square" src={img} size={size}>{img ? "" : title && title.length > 0 ? title[0] : ""}</Avatar>
                </Col>
                <Col span={16} order={2} className="twp-profile" style={{ marginLeft: size / 4 }}>
                    <Row>
                        <a target="_blank" href={props.url !== undefined ? blogURL : ""} className="twp-profile-title" style={{ fontSize: 15, wordWrap: "break-word", overflow: "break-word", color: "#E15A00" }} dangerouslySetInnerHTML={{ __html: title }} rel="noopener noreferrer"></a>
                        <p className="group-avatar-author" dangerouslySetInnerHTML={{ __html: "by " + author }}></p>
                        <br />
                        <p className="group-avatar-subtitle" style={{ wordWrap: "break-word", overflow: "break-word" }} dangerouslySetInnerHTML={{ __html: description }}></p>
                    </Row>
                </Col>
            </Row>

        ) : (
            <>
                <Row type="flex" align="middle">
                    <Col span={12}>
                        <Avatar shape="square" src={img} size={size}>{img ? "" : title && title.length > 0 ? title[0] : ""}</Avatar>
                    </Col>
                    <Col span={12}>
                        <p className="twp-profile-title" style={{ fontSize: 15, wordWrap: "break-word", overflow: "break-word" }} dangerouslySetInnerHTML={{ __html: title }}></p>
                        <p style={{ fontSize: 12, fontStyle: "italic", wordWrap: "break-word", overflow: "break-word" }} dangerouslySetInnerHTML={{ __html: description }}></p>
                    </Col>
                </Row>
            </>
        )
    )
}

const Actions = {
};

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, Actions)(PracticeGroupAvatar);