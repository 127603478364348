import React, { useState } from "react";
import { Typography } from 'antd'
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { find, partition, filter, unionBy } from "lodash";

import Response from "../../snippets/listing/Comment";
import { requestAddCritique, requestDeleteCritique, requestUpdateCritique } from "../../../actions/critiques";

const { Text } = Typography;


interface Props {
    requestDeleteCritique: (id: number) => void;
    requestAddCritique: (obj: any) => any;
    requestUpdateCritique: (obj: any) => void;
    comments: Critique;
    posts: Array<any>
    completed?: string;
    highlights: (text: string) => any;
}

interface Critique {
    comment: string;
    created_at: any;
    level: number;
    id: number,
    user: {
        user_id: number,
        user_name: string,
        profile_pic: any
    }
    parent_id: number;
    highlighted_text: string;
    name: string;
}
const loopComments = (arrs, ttl) => {

    return arrs.reduce((a, v) => {
        const r = filter(ttl, (c) => c.parent_id === v.id);

        if (r.length > 0) {
            a.push(...unionBy(loopComments(r, ttl), r, 'id'))
        }
        return a;
    }, []);
}

function PostCommentsContainer(props: Props) {
    const { post_id } = useParams();
    const parsedId = parseInt(post_id ? post_id : "");
    const user_id = localStorage.getItem('twp-user-id')
    const parsedUserId = parseInt(user_id ? user_id : "");
    const { comments } = props;
    const [grpId, setGrpId] = useState(1);

    const [post, setPost] = useState({
        title: "",
        post: "",
        name: "",
        user: {
            user_id: "",
            user_name: "",
        },
        group: {
            group_type: "",
            assignment_type: ""
        },
        post_type: ""
    });

    const [commentOpen, setComment] = useState({
        comment: "",
        created_at: "",
        level: "",
        id: "",
        user: {
            user_id: "",
            user_name: "",
            profile_pic: ""
        },
        parent_id: "",
        highlighted_text: "",
        name: "",
    })

    React.useEffect(() => {
        if (props.posts.length > 0) {
            let p = find(props.posts, { post_id: parsedId }); //see if post already exist
            if (p) {
                setPost(p);
            }
        }
    }, [props.posts]);

    // React.useEffect(() => {
    //     if (props.comments ) {
    //         let p = find(props.comments); //see if post already exist
    //         console.log("p", p)
    //         if (p) {
    //             setComment(p);
    //         }
    //     }

    // }, [props.comments]);


    function handleOnDelete(id: number) {
        let obj = find(comments, { id });
        if (obj) {
            props.requestDeleteCritique(id);
        }
    }

    function handleEditComment(id: number, comment: string) {

        const params = {
            comment,
            comment_id: id
        }
        props.requestUpdateCritique(params);
    }


    function handleReplyToComment(parent_id: number, text: string) {
        let p = find(props.comments, { id: parent_id });
        let firstLevelComment;

        if(p) {
            firstLevelComment = find(props.comments, { id: p.parent_id})
        }

        if (p?.level === 2) {
            let params = {
                comment: text,
                highlighted_text: "",
                parent_id: parent_id,
                post_id: parsedId,
                group_id: grpId,
                level: 3,
                comments_array: [post.user.user_id,firstLevelComment.user.user_id, p.user.user_id] //author id, first level comment user_id, second level comment user_id
                // comments_array: [post.user.user_id, p.user.user_id, authorComment.user.user_id] //author id, second level comment user_id, third level comment user_id
            };

             props.requestAddCritique(params);

        } else if(p?.level === 3) {

            let params = {
                comment: text,
                highlighted_text: "",
                parent_id: parent_id,
                post_id: parsedId,
                group_id: grpId,
                level: 3,
                comments_array: [post.user.user_id,firstLevelComment.user.user_id, p.user.user_id] //author id, first level comment user_id, second level comment user_id
                // comments_array: [post.user.user_id, p.user.user_id, authorComment.user.user_id] //author id, second level comment user_id, third level comment user_id
            };

            props.requestAddCritique(params)

        }  else {

            let params = {
                comment: text,
                highlighted_text: "",
                parent_id: parent_id,
                post_id: parsedId,
                group_id: grpId,
                level: 2,
                comments_array: [post.user.user_id, p.user.user_id]
            };

            props.requestAddCritique(params);
        }  
    }

    // const findReplier = (cmnts, id) => {
    //     const cmnt = find(cmnts, { id: id })
    //     return cmnt && cmnt.user ? cmnt.user.user_name : '';
    // }

    function highlightCallBack(highlight) {
        props.highlights(highlight)
    }


    const NestedResponse = ({ comments, id }: {
        comments: Array<Critique>
        id: number
    }) => {

        const cmnts = comments.map(c => 
            ({
            ...c,
            comment: c.parent_id === id ? c.comment : c.comment
            // comment: c.parent_id === id ? c.comment : findReplier(comments, c.parent_id) + " " + c.comment          
           
        }));
        
        const cs = filter(cmnts, (c) => c.parent_id === id);
        const cts = loopComments(cs, cmnts);
        const allcts = unionBy(cs, cts, 'id').slice().sort((a, b) => new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf());

        const Renderer = () => (
            <>
                {allcts.length > 0 && allcts.map((c) => (
                    <Response
                        key={`critique_${c.id}`}
                        comment={c}
                        editable={parsedUserId === c.user.user_id}
                        onDelete={handleOnDelete}
                        onReply={handleReplyToComment}
                        onEdit={handleEditComment}
                        posts={post.post}
                        highlights={highlightCallBack}
                    />
                ))}
            </>
        );

        return allcts && allcts.length > 0 ? <Renderer /> : null;
    }

    const [nested, main] = partition(comments, (o) => o.parent_id);

    React.useEffect(() => {
        const post = find(props.posts, ['post_id', parsedId]);
        setGrpId(post.group.group_id)
    }, []);

    return (
        <div>
            {props.completed === "true" && user_id == post.user.user_id && post.post_type != "Message" ? (
                main && main.map((c) => (
                    <Response
                        key={`critique_${c.id}`}
                        editable={parsedUserId === c.user.user_id}
                        comment={c}
                        onDelete={handleOnDelete}
                        onReply={handleReplyToComment}
                        onEdit={handleEditComment}
                        posts={post.post}
                        highlights={highlightCallBack}
                    >
                            <NestedResponse
                            id={c.id}
                            comments={nested}
                        />
                    </Response>
                ))

            ) : post.post_type === "Message" ? (
                main && main.map((c) => (
                    <Response
                        key={`critique_${c.id}`}
                        editable={parsedUserId === c.user.user_id}
                        comment={c}
                        onDelete={handleOnDelete}
                        onReply={handleReplyToComment}
                        onEdit={handleEditComment}
                        posts={post.post}
                        highlights={highlightCallBack}
                    >
                            <NestedResponse
                            id={c.id}
                            comments={nested}
                        />
                    </Response>
                ))

            ) : post.group.assignment_type === "Free Choice" && user_id == post.user.user_id ? (
                <Text underline>Critique any 3 Writing Pieces of the same group to unlock critiques on your own writing piece!</Text>
            ) : props.completed === "false" && user_id == post.user.user_id ? (
                <Text underline>Complete your assignments to unlock critiques</Text>
            ) : user_id != post.user.user_id ? (

                main && main.map((c) => (
                    <Response
                        key={`critique_${c.id}`}
                        editable={parsedUserId === c.user.user_id}
                        comment={c}
                        onDelete={handleOnDelete}
                        onReply={handleReplyToComment}
                        onEdit={handleEditComment}
                        posts={post.post}
                        highlights={highlightCallBack}
                    >
                            <NestedResponse
                            id={c.id}
                            comments={nested}
                        />
                    </Response>
                ))
            ) : null
            }
        </div>
    )
}

const Actions = {
    requestAddCritique,
    requestDeleteCritique,
    requestUpdateCritique
};

const mapToState = (state: any) => ({
    comments: state.critiques.list,
    posts: state.posts.list
});

export default connect(mapToState, Actions)(PostCommentsContainer);
