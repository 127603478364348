import React, { useState } from "react";
import {
  Tabs,
  Button,
  Row,
  Col,
  Select,
  Modal,
  Popover,
  Typography,
  notification,
} from "antd";
import { filter } from "lodash";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import MessageEditor from "../../snippets/editors/MessageEditor";
import WritingPieceEditor from "../../snippets/editors/WritingPieceEditor";
import { invokeCreatePost } from "../../../actions/posts";
import { fetchAllGroups } from "../../../actions/groups";

const { TabPane } = Tabs;
const { Option } = Select;
const { Text } = Typography;

interface CreatePostProps {
  invokeCreatePost: (body: any) => any;
  fetchAllGroups: any;
  loading: boolean;
  groups: any;
  groupId: any;
  groupName: any;
  subcriptionExpired?;
}

const initMessage = {
  post: "",
};

function FeedCreatePostContainer(props: CreatePostProps) {
  const [tab, setTab] = React.useState("1"); // '1' : Writing Piece, '2' : Message
  const [msg, setMsg] = React.useState("");
  const history = useHistory();
  const [group, setGroup] = useState<any>();
  const [errors, setErrors] = React.useState<Array<string>>([]);
  const [wordCount, setWordCount] = useState("");
  const [message, setMessage] = React.useState(initMessage);
  const [wp, setWp] = React.useState({
    title: "",
    post: "",
    feedback: "",
    summary: "",
    genre_id: 1,
    word_count: "",
  });

  React.useEffect(() => {
    const cs = filter(props.groups, (c) => c.id === group);
    cs.map((a) => {
      setWordCount(a.word_count);
    });
  });

  const validate = () => {
    setErrors([]); //Reset validation to validate again.
    let errs: Array<string> = [];
    const text = wp.post.replace(/<[^>]*>/g, "");

    //VALIDATE
    if (tab === "1") {
      if (!wp.title) errs.push("You need enter a title for your writing piece");

      if (!wp.post)
        errs.push("You need to enter a post body to your writing piece");

      if (text.trim().split(/\s+/).length < 2)
        errs.push("You're writing piece is empty");

      if (!group) errs.push("Select a group to push the post to");

      if (text.trim().split(/\s+/).length > parseInt(wordCount))
        errs.push(
          "You're writing piece is too long. You cannot post anymore than " +
            wordCount +
            " words"
        );

      let validateWPGroup = filter(props.groups, { id: group });
      validateWPGroup.map((x) => {
        if (x.group_types.length != 2) {
          x.group_types.map((y) => {
            if (y.group_type != "WP") {
              errs.push(
                "Writing Pieces cannot be posted in the selected group"
              );
            }
          });
        }
      });
    } else {
      if (!message.post) errs.push("you cannot leave message as empty");
      if (!group) errs.push("Select a group to push the post to");
      if (message.post.length < 2) errs.push("Your message is empty");
      let validateMessageGroup = filter(props.groups, { id: group });
      validateMessageGroup.map((x) => {
        x.group_types.map((y) => {
          if (x.group_types.length != 2) {
            if (y.group_type != "Message") {
              errs.push("Messages cannot be posted in the selected group");
            }
          }
        });
      });
    }

    setErrors(errs); // set errors

    // return validation
    if (errs.length < 1) return true;
    return false;
  };

  const handleSubmit = (e: any) => {
    console.log("handleSubmit", e)
    console.log("tab", tab)
    let params =
      tab === "1"
        ? {
            title: wp.title,
            post: wp.post,
            summary: wp.summary,
            group_id: group,
            genre_id: wp.genre_id,
            feedback: wp.feedback,
            post_type_id: 1,
            word_count: wp.word_count,
            // pwa_post: wp.pwa_post
          }
        : {
            title: message.post,
            post: "",
            summary: "",
            feedback: "",
            group_id: group,
            post_type_id: 2,
          };

    const isvalid = validate();

    console.log("params", params);
    if (isvalid) {
      const submit = props.invokeCreatePost(params);
      submit.then((id: any) => {
        if (id) {
          if (tab === "1") {
            setTimeout(() => assignementsNotifiction("info"), 2000);
          }
          history.push(`/post/${id}`);
        } else {
          Modal.error({
            title: "Couldn't save post",
            content: (
              <div>
                <h4>Please make sure your piece does not contain emojis. Also try copy-and-pasting from a plain text word processor which may remove formatting that interferes with our editor. If you continue to have issues, contact our help desk.</h4>
                <a target="_blank" href='https://thewritepractice.zendesk.com/hc/en-us/requests/newhttps://www.atticus.io/troubleshooting-synching-errors/' rel="noopener noreferrer">
                  https://thewritepractice.zendesk.com/hc/en-us/requests/new
                </a>
              </div>
            )
          });
        }
      });
    }
  };

  //Subscription Expired Popover over Submit Button
  const content = (
    <div>
      <Text mark>Please renew your subscription</Text>
    </div>
  );

  //Assignment Alert
  const assignementsNotifiction = (type) => {
    const secondsToGo = 15;
    notification[type]({
      message: "New Assignment",
      description:
        "Now that you’ve posted your writing piece, it’s time to critique three pieces by other writers. Check the assignment box for your critique assignment. Happy critiquing!",
    });

    setTimeout(() => {
      notification.destroy();
    }, secondsToGo * 1000);
  };
  return (
    <div>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <h3>Get started here</h3>
        </Col>
        <Col>
          {props.subcriptionExpired === "true" ? (
            <Popover
              content={content}
              title="Subscription Expired"
              trigger="hover"
            >
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={props.loading}
                disabled
              >
                Submit
              </Button>
            </Popover>
          ) : (
            <Button
              className="mini-create-submit"
              type="primary"
              onClick={handleSubmit}
              loading={props.loading}
            >
              Submit
            </Button>
          )}
        </Col>
      </Row>
      {errors.length > 0 ? (
        <Row className="inner">
          {errors.map((err, i) => (
            <div key={`err_${i}`} style={{ color: "red" }}>
              <b>* {err}</b>
            </div>
          ))}
        </Row>
      ) : null}
      <Tabs
        defaultActiveKey="1"
        animated={false}
        onChange={setTab}
        onTabClick={() => setErrors([])}
        tabBarExtraContent={
          !props.groupId ? (
            <Select
              style={{ minWidth: 150 }}
              onChange={(v) => setGroup(parseInt(v.toString()))}
              placeholder="Select a group"
            >
              {props.groups.map((grp, index) =>
                tab === "1"
                  ? grp.group_types.map((a, i) =>
                      a.group_type === "WP" ? (
                        <Select.Option key={"_grp " + index} value={grp.id}>
                          {grp.group_name}
                        </Select.Option>
                      ) : null
                    )
                  : grp.group_types.map((a, i) =>
                      a.group_type === "Message" ? (
                        <Select.Option key={"_grp " + index} value={grp.id}>
                          {grp.group_name}
                        </Select.Option>
                      ) : null
                    )
              )}
            </Select>
          ) : (
            <Select
              style={{ minWidth: 150 }}
              placeholder="Select a group"
              defaultValue="jack"
            >
              <Option value="jack" disabled>
                {props.groupId}
              </Option>
            </Select>
          )
        }
      >
        <TabPane tab="Writing piece" key="1" className="writing-piece-tab">
          <WritingPieceEditor
            onChange={setWp}
            mini={true}
            word_count={wordCount}
          />
        </TabPane>
        <TabPane tab="Message" key="2">
          {/* <MessageEditor value={msg} onChange={setMsg} /> */}
          <MessageEditor onChange={setMessage}  post={message}/>
        </TabPane>
      </Tabs>
    </div>
  );
}

const mapStP = (state: any) => ({
  loading: state.posts.loading,
  groups: state.groups.list,
});

const Actions = {
  invokeCreatePost,
  fetchAllGroups,
};

export default connect(mapStP, Actions)(FeedCreatePostContainer);
