import {
    FETCH_POST_INITIATE,
    FETCH_POST_INITIATE_FAILED,
    FETCH_POST_INITIATE_SUCCESS,
    FETCH_SINGLE_POST,
    UPDATE_POST_INITIATE,
    FETCH_FEED_SUCCESS,
    FETCH_ARTICLES_SUCCESS,
    FETCH_PRACTICE_POST_SUCCESS,
    UPDATE_PRACTICE_POST
} from "./_types";

const postApiInvoke = () => ({ type: FETCH_POST_INITIATE });

const postApiUpdate = (response) => ({
    type: UPDATE_POST_INITIATE,
    payload: response,
});

const postApiInvokeSuccess = () => ({ type: FETCH_POST_INITIATE_SUCCESS });

const postApiInvokeFailed = () => ({ type: FETCH_POST_INITIATE_FAILED });

const fetchPostSuccess = (post) => ({ type: FETCH_SINGLE_POST, payload: post });

const setFeedPosts = (posts) => ({ type: FETCH_FEED_SUCCESS, payload: posts });

const setArticleFeed = (articles) => ({ type: FETCH_ARTICLES_SUCCESS, payload: articles});

const setPracticeFeed = (practices) => ({ type: FETCH_PRACTICE_POST_SUCCESS, payload: practices});

const practiceUpdate = (response) => ({ type: UPDATE_PRACTICE_POST, payload: response});

export const invokeCreatePost = (params) => (dispatch, getState, services) => {
    dispatch(postApiInvoke());
    let post_service = services.get("PostService");
    return post_service
        .createPost(params)
        .then((res) => {
            dispatch(postApiInvokeSuccess());
            return res.data[0].post_id;
        })
        .catch((err) => {
            dispatch(postApiInvokeFailed())
            return null
        });
};

export const fetchPost = (post_id) => (dispatch, getState, services) => {
    dispatch(postApiInvoke());
    let post_service = services.get("PostService");
    return post_service
        .getPost(post_id)
        .then((res) => {
                const p = res.data[0];
                const post = {
                    ...p,
                    user: p.user,
                    group: p.group,
                    badges: p.badges,
                };
                dispatch(postApiInvokeSuccess());
                dispatch(fetchPostSuccess([post]));
                return res;
        })
        .catch((err) => dispatch(postApiInvokeFailed()));
};

export const fetchFeedPosts = (offset, limit) => (dispatch, getState, services) => {
    dispatch(postApiInvoke());
    let post_service = services.get("PostService");
    return post_service
        .getFeedPosts(offset, limit)
        .then((res) => {
            dispatch(postApiInvokeSuccess());
            dispatch(setFeedPosts(res.data && res.data.length > 0 ? res.data : []));
            return res;
        })
        .catch((err) => dispatch(postApiInvokeFailed()));
};

export const fetchPostsByGroupId = (id, offset, limit) => (dispatch, getState, services) => {
    dispatch(postApiInvoke());
    let post_service = services.get("PostService");
    return post_service
        .getPostsByGroup(id, offset, limit)
        .then((res) => {
            const posts = res.data && res.data.map((item) => ({
                ...item,
                group: item.group,
                user: item.user,
            }));
            dispatch(fetchPostSuccess(posts));
            dispatch(postApiInvokeSuccess());
            return res;

        })
        .catch((err) => dispatch(postApiInvokeFailed()));
};

export const updatePost = (response) => (dispatch, getState, services) => {
    // dispatch(postApiInvoke());
    let post_service = services.get("PostService");
    post_service
        .updatePost(response)
        .then((res) => {
            dispatch(postApiUpdate(response));
            // dispatch(postApiInvokeSuccess());
            // return res;
        })
        .catch((err) => dispatch(postApiInvokeFailed()));
};

export const deletePost = (post_id) => (dispatch, getState, services) => {
    dispatch(postApiInvoke());
    let post_service = services.get("PostService");
    post_service
        .deletePost({ post_id })
        .then((res) => dispatch(postApiInvokeSuccess()))
        .catch((err) => dispatch(postApiInvokeFailed()));
};


export const getGenre = () => (dispatch, getState, services) => {
    const post_service = services.get("PostService")
    return post_service.getGenre()
        .then((res) => {
            return res.data;
        }).catch((err) => console.log({ err }))
}

export const getPWA = (params) => (dispatch, getState, services) => {
    const post_service = services.get("PostService")
    return post_service.getPWA(params)
        .then((res) => {
            return res.data[0];
        }).catch((err) => console.log({ err }))
}


