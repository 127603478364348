import React from 'react'
import PNF from '../components/snippets/fallbacks/PageNotFound'
import WithoutHeader from '../components/shared/WithoutHeader'

function PageNotFound() {
    return (
        <WithoutHeader>
            <PNF />
        </WithoutHeader>
    )
}

export default PageNotFound;
