import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Form,
  Col,
  Row,
  Select,
  Input,
  Button,
  Icon,
  notification,
} from "antd";
import { find } from "lodash";
import { useParams } from "react-router-dom";

import { updateCourse, getAllCourses } from "../../../../actions/courses";
import { fetchGroupAccessPeriods } from "../../../../actions/groups";
import { fetchSubscriptionPeriods } from "../../../../actions/user";

const { Option } = Select;
const { TextArea } = Input;

interface Props {
  handleClose: () => any;
  updateCourse: (params: any) => any;
  getAllCourses: () => any;
  fetchGroupAccessPeriods: () => any;
  fetchSubscriptionPeriods: () => any;
  form: any;
  loading: boolean;
  courses: Array<any>;
  groups: Array<any>;
}

const initCourse = {
  access_period: "",
  access_period_id: "",
  course_code: "",
  course_id: "",
  description: "",
  group_id: "",
  group_name: "",
  period: "",
  period_id: "",
  price: "",
  title: "",
  url: "",
  word_count: "",
};

function _Form(props: Props) {
  const { id } = useParams();
  const parsedId = parseInt(id ? id : "");
  let course = find(props.courses);
  course = course ? course : initCourse;

  const [groupCategory, setGroupCategory] = useState<any>();
  const [groupType, setGroupType] = useState<any>();
  const [period, setPeriod] = useState<any>();
  const [groupAccess, setGroupAccessPeriod] = useState<any>([]);
  const [subscriptionPeriods, setSubscriptionPeriods] = useState<any>([]);

  //Group Acess API fetching
  const getGroupAccessPeriods = async () => {
    const subs = await props.fetchGroupAccessPeriods();
    setGroupAccessPeriod(subs);
    return subs;
  };

  //Subscription Period API fetching
  const getSubscriptionPeriods = async () => {
    const subs = await props.fetchSubscriptionPeriods();
    setSubscriptionPeriods(subs);
    return subs;
  };

  React.useEffect(() => {
    getGroupAccessPeriods();
    getSubscriptionPeriods();
  }, []);

  //Group updated successful notification
  const successNotification = () => {
    notification.open({
      message: "Success",
      description: "Group Updated!",
      icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
    });
  };

  //Group updated unsuccessful notification
  const unsuccessfulNotification = () => {
    notification.open({
      message: "Oops",
      description: "Group did not update!",
      icon: <Icon type="frown" style={{ color: "#108ee9" }} />,
    });
  };

  function handler(e) {
    props.form.validateFields((err: any, vals: any) => {
      if (!err) {
        let a = find(props.groups, { group_name: vals.group_id });

        if (vals.group_access_period_id === "2 months") {
          vals.group_access_period_id = 1;
        } else if (vals.group_access_period_id === "6 months") {
          vals.group_access_period_id = 2;
        } else if (vals.group_access_period_id === "12 months") {
          vals.group_access_period_id = 3;
        } else if (vals.group_access_period_id === "forever") {
          vals.group_access_period_id = 4;
        }

        if (vals.period_id === "Monthly") {
          vals.period_id = 1;
        } else if (vals.period_id === "Annually") {
          vals.period_id = 2;
        }

        const params = {
          ...vals,
          id: course.course_id,
          group_id: a.id,
        };

        const submit = props.updateCourse(params);
        successNotification();
        setTimeout(function () {
          window.location.reload();
        }, 1500);
      }
    });
  }

  const { getFieldDecorator, setFieldsValue } = props.form;

  return (
    <Form>
      <Row className="inner">
        <Form.Item label="Title" id="title">
          {getFieldDecorator("title", {
            initialValue: course.title,
            rules: [
              {
                required: true,
                message: "Can't leave this empty",
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Course Description" id="description">
          {getFieldDecorator("description", {
            initialValue: course.description,
            rules: [
              {
                required: true,
                message: "Can't leave this empty",
              },
            ],
          })(<TextArea rows={5}></TextArea>)}
        </Form.Item>
        <Form.Item label="Word Count" id="word_count">
          {getFieldDecorator("word_count", {
            initialValue: course.word_count,
            rules: [
              {
                required: true,
                message: "Can't leave this empty",
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Group Access Period" id="group_access_period_id">
          {getFieldDecorator("group_access_period_id", {
            initialValue: course.access_period,
            rules: [
              {
                required: true,
                message: "Please select a value",
              },
            ],
          })(
            <Select onChange={(v) => setGroupCategory(v)}>
              {groupAccess.map((s) => (
                <Option key={s.id + "group_category_id"} value={s.id}>
                  {s.access_period}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Course Code" id="course_code">
          {getFieldDecorator("course_code", {
            initialValue: course.course_code,
            rules: [
              {
                required: true,
                message: "Can't leave this empty",
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Price" id="price">
          {getFieldDecorator("price", {
            initialValue: course.price,
            rules: [
              {
                required: true,
                message: "Can't leave this empty",
              },
            ],
          })(<Input />)}
        </Form.Item>

        <Form.Item label="Period" id="period_id">
          {getFieldDecorator("period_id", {
            initialValue: course.period,
            rules: [
              {
                required: true,
                message: "Can't leave this empty",
              },
            ],
          })(
            <Select onChange={(v) => setPeriod(v)}>
              {subscriptionPeriods.map((s) => (
                <Option key={s.id + "_group_id"} value={s.id}>
                  {s.period}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Group" id="group_id">
          {getFieldDecorator("group_id", {
            initialValue: course.group_name,
            rules: [
              {
                required: true,
                message: "Please select a value",
              },
            ],
          })(
            <Select onChange={(v) => setGroupType(v)}>
              {props.groups.map((s) => (
                <Option key={s.id + "_group_id"} value={s.id}>
                  {s.group_name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="URL" id="url">
          {getFieldDecorator("url", {
            initialValue: course.url,
            rules: [
              {
                required: true,
                message: "Can't leave this empty",
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Row gutter={8} type="flex">
          <Col>
            <Button onClick={handler} type="primary">
              Confirm changes
            </Button>
          </Col>
        </Row>
      </Row>
    </Form>
  );
}

const EditCourseContainer = Form.create({ name: "edit_course" })(_Form);

const mapStoP = (state: any) => ({
  courses: state.courses.list,
  loading: state.groups.loading,
  groups: state.groups.list,
});

const Actions = {
  updateCourse,
  getAllCourses,
  fetchGroupAccessPeriods,
  fetchSubscriptionPeriods,
};

export default connect(mapStoP, Actions)(EditCourseContainer);
