import React from "react";
import { Row, Typography, Col, Icon } from "antd";
import { Link } from "react-router-dom";
import { formatTime } from "../../../utils/helpers";

const MAX_LENGTH = 40;

function GeneralPracticeUserBlock(props: any) {

    const link = `/practice/post/${props.post_id}`;
    let splitTitle = props.title.substring(0, 150);

    return (
        <div className="post-block" key={props.index + "_post"}>
            {props ? (
                <div>
                    <br />
                        {props.title.length > MAX_LENGTH ? (
                            <Link to={link} className="post-title">{splitTitle}</Link>
                        ) : <Link to={link}><h2 className="post-title">{props.title}</h2></Link>
                        }
                        {/* <a href={link}><h2 className="post-title">{props.title}</h2></a> */}
                        <div className="post-description">

                            {props.summary === null ? (
                                <p></p>
                            ) : (
                                <div>
                                    {props.summary.length > MAX_LENGTH ? (
                                        <p className="">{props.summary}<Link className="link" to={link}> read more</Link></p>
                                    ) :
                                        <p>{props.summary}</p>
                                    }
                                </div>
                            )}
                        </div>
                    <br />
                    <Row type="flex" justify="space-between">
                    <Col>
                          <span>
                            {formatTime(props.created_date)} on{" "}
                            <a href={`/group/${props.group_id}`}>{props.group}</a>
                          </span>
                        </Col>
                        <Col>
                            {props.comments && props.comments.length > 0  ? (
                                <Typography.Text>
                                    <Icon type="message" /> {props.comments.length}
                                </Typography.Text>
                            ) : (
                                "no response"
                            )}
                        </Col>
                    </Row>
                </div>
            ) : (
                <div className="activity-box">Not Found</div>
            )}
        </div>
    );
}

export default GeneralPracticeUserBlock;
