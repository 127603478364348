import React from "react";
import { Button, Tooltip } from "antd";

const img = process.env.REACT_APP_LOGIN_BG_IMAGE;

function AuthLayout({ children }: { children: any }) {
  return (
    <div className="auth-wrapper">
      <div className="twp-wrapper">
        <div className="section auth-box">{children}</div>
      </div>
      <div
        className="auth-floater auth-img"
        style={{ backgroundImage: img ? `url(${img})` : "" }}
      >
        <div className="overlay"></div>
        <div className="inner">
          <div className="inner">
            <h1 className="title shadow-text">Welcome</h1>
            <p className="shadow-text">
              The Write Practice Pro is an online writing community dedicated to
              helping writers polish and publish their work.
              <br />
              If you already have a Pro account, log in. If you don't, you can
              register for free below. Learn more about our community{" "}
              {
                <a
                  target="_blank"
                  href="https://thewritepractice.com/terms-and-conditions/"
                  rel="noopener noreferrer"
                >
                  here
                </a>
              }
              .
            </p>
            <br />
            <br />
            <br />
            <Tooltip title="Registrations are temporarily closed. Please check back later. Thanks">
              <Button
                className="btn-c1"
                size="large"
                onClick={() =>
                  (window.location.href = "https://thewritepractice.com/join/")
                }
                disabled
              >
                Register now
              </Button>
            </Tooltip>
            {/* <Button className="btn-c1" size="large" onClick={() => history.push('/auth/register')}>Register now</Button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;
