import React, {createContext, useState} from 'react'
import {throttle} from 'lodash'

interface ContextProps {
    header: Boolean,
    sidebar: Boolean,
    responsive: Number,
    toggleHeader: (show?: Boolean) => void
    toggleSidebar: (show?: Boolean) => void
}

const delay = process.env.REACT_APP_RESPONSIVE_SMOOTHNESS || 500 //Get delay from env

const initState : ContextProps = {
    header: true,
    sidebar: false,
    responsive: window.innerWidth,
    toggleHeader: () => null,
    toggleSidebar: () => null
}

export const AppContext = createContext<ContextProps>(initState);

export default function AppProvider ({children}: {children: any}) {
    const [header, toggleHeader] = useState<Boolean>(initState.header);
    const [sidebar, toggleSidebar] = useState<Boolean>(initState.sidebar)
    const [responsive, setWinWidth] = useState<Number>(initState.responsive);

    function onResize () {
        setWinWidth(window.innerWidth);
    }

    React.useEffect(() => {
        //throttle the listener by 0.5s so that it will fire in set intervals -- helps reduce load
        window.addEventListener('resize', throttle(onResize, +delay))
        return () => {
            window.removeEventListener('resize', () => setWinWidth(initState.responsive))
        }
    }, [])

    return (
        <AppContext.Provider 
            value={{ 
                header, 
                responsive, 
                sidebar, 
                toggleHeader: (t: Boolean = !header) => toggleHeader(t), 
                toggleSidebar: (t: Boolean = !sidebar) => toggleSidebar(t) 
            }}
        >
            {children}
        </AppContext.Provider>
    )
}