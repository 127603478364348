import React from 'react'
import GroupContainer from '../components/containers/Groups/GroupContainer'
import WithSidebar from '../components/shared/WithSidebar';

const Group = () => {
    return (
        <WithSidebar>
            <GroupContainer />
        </WithSidebar>
    )
};

export default Group;