import React, { useState } from "react";
import { Row, List, Icon, Button, Skeleton, notification, Empty, Typography } from "antd";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { fetchAllArticles } from "../../../actions/practice";
import ArticleBlock from "../../snippets/listing/ArticleBlock";

interface Props {
  fetchAllArticles: (offset: number, limit: number) => any;
  articles: Array<any>;
}

const { Text } = Typography;


function PracticeContainer(props: Props) {
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10;
  const history = useHistory();

  React.useEffect(() => {
    if (props.articles.length < 1) {
      invokeFetchFeed(0);
    }
  });

  //Handles Load More button
  const handleLoadMore = () => {
    if (hasMore) {
      invokeFetchFeed(offset + 1).then(() => setOffset(offset + 1));
    }
  };

  //Invokes activity Feed
  const invokeFetchFeed = async (offset) => {
    setLoading(true);
    return await props.fetchAllArticles(offset, limit).then((res) => {
      setLoading(false);
      if (res.data && res.data.length < limit) {
        setHasMore(false);
      }
      if (res.message === "Expired token. Please login to get a new token") {
        openNotification()
        window.location.reload();
        //Log out when the token expires
        localStorage.removeItem("twp-token");
        history.push("/auth/login");
      }
    });
  };

  const openNotification = () => {
    notification.open({
      message: "Token Expired",
      description: "Login Again",
      icon: <Icon type="exclamation" style={{ color: "#108ee9" }} />,
    });
  };

  return (
    <div className="twp-wrapper">
      <div className="inner"></div>
      <Row className="reader">
        <h3>Blog posts</h3>
        {props.articles.length > 0 ? (
          <>
            <List dataSource={props.articles} renderItem={ArticleBlock} />
            <Skeleton loading={loading} active className="area" />
            {props.articles.length > 0 ? (
              <>
                <Row type="flex" justify="center">
                  <Button onClick={handleLoadMore}>Load more</Button>
                </Row>
              </>
            ) : null}
          </>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={
              <Text style={{ fontSize: 12 }} type="secondary"> Follow groups to view posts on your feed</Text>
            }
          >
          </Empty>
        )}

      </Row>
    </div>
  );
}

const Actions = {
  fetchAllArticles
};

const mapStateToProps = (state: any) => ({
  articles: state.posts.articles,
});

export default connect(mapStateToProps, Actions)(PracticeContainer);
