import React from 'react'
import DraftContainer from '../../components/containers/Settings/DraftsContainer'

function Drafts() {
    return (
        <DraftContainer />
    )
}

export default Drafts
