export default class PostService {
    
    /**
     * @param {ApiService} apiService
     */
    constructor(apiService) {
        this.api = apiService;
    }
    getFeedPosts(offset, limit) {
        return this.api.get(`user-feed?offset=${offset}&limit=${limit}`);
    }
    getPostsByGroup(id,offset, limit) {
        return this.api.get(`posts/group/${id}?offset=${offset}&limit=${limit}`);
    }
    getPost(post_id) {
        return this.api.get(`post/${post_id}`);
    } 
    createPost(posts) {
        return this.api.post(`create-post`, posts) 
    }
    updatePost(posts) {
        return this.api.post(`edit-post`, posts);
    }
    deletePost(post) {
        return this.api.delete(`delete-post`, post);
    }
    getGenre() {
        return this.api.get(`genre`);
    } 
    getPWA(post) {
        return this.api.post(`pwa`, post);
    }
    getAllArticles() {
        return this.api.get(`practice/all-articles`)
    }
    getSingleArticle(id) {
        return this.api.get(`group/article/${id}`)
    }
    createPracticePost(posts) {
        return this.api.post(`practice/add-post`, posts);
    }
    getPracticePost(id) {
        return this.api.get(`practice/post/${id}`)
    }
    getPracticePostsByGroup(id,offset, limit) {
        return this.api.get(`practice/posts/group/${id}?offset=${offset}&limit=${limit}`);
    }
    updatePracticePost(posts) {
        return this.api.post(`practice/edit-post`, posts);
    }
    deletePracticePost(post_id) {
        return this.api.delete(`practice/delete-post`, post_id);
    }
}
