import {
    ADD_CRITIQUE,
    DELETE_CRITIQUE,
    UPDATE_CRITIQUE,
    FETCH_CRITIQUES,
    FETCH_PRACTICE_CRITIQUES
} from "../actions/_types";
import { unionWith, reject } from 'lodash'

const initial_state = {
    total: [],
    list: [],
}

const fetchCritiques = (state, critiques) => {
    const total = unionWith(critiques, state.list);
    return {
        ...state,
        list: critiques,
        total
    }
};


const addToCritiques = (state, critique) => {
    let list = unionWith([critique], state.list);
    return {
        ...state,
        list
    }
};

const removeFromCritiques = (state, id) => {
    const list = reject(state.list, {'id' :id });
    return {
        ...state,
        list
    }
};

const updateCritique = (state, obj) => {
    let list = state.list.map((c) => {
        return c.id === obj.comment_id ? {
            ...c,
            comment: obj.comment
        } : c;
    });
    return {
        ...state,
        list
    }
};

const critiqueReducers = (state = initial_state, action) => {
    switch (action.type) {
        case FETCH_CRITIQUES:
            return fetchCritiques(state, action.payload);
        case ADD_CRITIQUE:
            return addToCritiques(state, action.payload)
        case DELETE_CRITIQUE:
            return removeFromCritiques(state, action.payload);
        case UPDATE_CRITIQUE:
            return updateCritique(state, action.payload);
        default:
            return state;
    }
};

export default critiqueReducers;