import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";

import RootReducer from "../reducers";

export default function configureStore(serviceManager) {
    return createStore(
        RootReducer,
        applyMiddleware(thunk.withExtraArgument(serviceManager))
    );
}