import React, { Component } from "react";
import { Typography, Card, Col, Button } from "antd";
import { Link } from "react-router-dom";

const { Text } = Typography;

export class CancelSubscription extends Component {
  render() {
    return (
      <div className="container">
        <div className="section">
          <Card
            type="inner"
            // title="Cancel Your Subscription?"
          >
            <Col>
              <h1>
                <Text type="secondary">Cancel Your Subscription?</Text>
              </h1>
            </Col>
            <p>Click "Finish Cancellation" below to cancel your membership.</p>
            <li>
              Cancellation will be effective at the end of your current billing
              period on May 11, 2020
            </li>
            <div className="cancel-plan-btn">
              <Button className="btn-c1" type="primary" htmlType="submit">
                Confirm
              </Button>
              <Link to="/settings">
                <Button className="btn-c1">Go Back</Button>
              </Link>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default CancelSubscription;
