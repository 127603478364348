import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

//Root Context
import AppProvider from './utils/context'

//Css
import 'antd/dist/antd.less';
import './utils/App.less';


//Configure environment variables
require('dotenv').config()

const {REACT_APP_TWP_SENTRY_DSN, NODE_ENV} = process.env

//Setup Sentry
Sentry.init({
    dsn: REACT_APP_TWP_SENTRY_DSN,
    environment: NODE_ENV
});

//App Render
ReactDOM.render(<AppProvider><App /></AppProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
