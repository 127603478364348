import {
    FETCH_POST_INITIATE,
    FETCH_POST_INITIATE_FAILED,
    FETCH_POST_INITIATE_SUCCESS,
    FETCH_ARTICLES_SUCCESS,
    FETCH_PRACTICE_POST_SUCCESS,
    UPDATE_PRACTICE_POST,
    FETCH_PRACTICE_CRITIQUES,
    ADD_PRACTICE_CRITIQUE,
    UPDATE_PRACTICE_CRITIQUE,
    DELETE_PRACTICE_CRITIQUE,
    USER_SET_PROFILE_POSTS
} from "./_types";

const postApiInvoke = () => ({ type: FETCH_POST_INITIATE });

const postApiInvokeSuccess = () => ({ type: FETCH_POST_INITIATE_SUCCESS });

const postApiInvokeFailed = () => ({ type: FETCH_POST_INITIATE_FAILED });

const setArticleFeed = (articles) => ({ type: FETCH_ARTICLES_SUCCESS, payload: articles});

const setPracticeFeed = (practices) => ({ type: FETCH_PRACTICE_POST_SUCCESS, payload: practices});

const practiceUpdate = (response) => ({ type: UPDATE_PRACTICE_POST, payload: response});

const setCritiquesFromPractice = critiques => ({ type: FETCH_PRACTICE_CRITIQUES, payload: critiques });

const addToCritiques = critique => ({ type: ADD_PRACTICE_CRITIQUE, payload: critique })

const updateCritique = critique => ({ type: UPDATE_PRACTICE_CRITIQUE, payload: critique })

const critiqueDelete = critique_id => ({ type: DELETE_PRACTICE_CRITIQUE, payload: critique_id });

const setUserPosts = (posts) => ({ type: USER_SET_PROFILE_POSTS, payload: posts });


export const getSingleArticle = (id) => (dispatch, getState, services) => {
    dispatch(postApiInvoke());
    let practice_service = services.get("PracticeService");
    return practice_service
        .getSingleArticle(id)
        .then((res) => {
            dispatch(postApiInvokeSuccess());
            return res.data[0];
        })
}

export const getPrivateSingleArticle = (id) => (dispatch, getState, services) => {
    dispatch(postApiInvoke());
    let practice_service = services.get("PracticeService");
    return practice_service
        .getPrivateSingleArticle(id)
        .then((res) => {
            dispatch(postApiInvokeSuccess());
            return res.data[0];
        })
}

export const fetchAllArticles = (offset, limit) => (dispatch, getState, services) => {
    dispatch(postApiInvoke());
    let practice_service = services.get("PracticeService");
    return practice_service
        .getAllArticles(offset, limit)
        .then((res) => {
            dispatch(postApiInvokeSuccess());
            dispatch(setArticleFeed(res.data && res.data.length > 0 ? res.data : []));
            return res;
        })
        .catch((err) => dispatch(postApiInvokeFailed()));
}; 

export const updateArticle = (params) => (dispatch, getState, services) => {
    let practice_service = services.get("PracticeService");
    practice_service
        .updateArticle(params)
        .then((res) => {
            dispatch(practiceUpdate(params));
        })
        .catch((err) => dispatch(postApiInvokeFailed()));
}

export const invokeCreatePracticePost = (params) => (dispatch, getState, services) => {
    dispatch(postApiInvoke());
    let practice_service = services.get("PracticeService");
    return practice_service
        .createPracticePost(params)
        .then((res) => {
            dispatch(postApiInvokeSuccess());
            return res.data[0].post_id;
        })
        .catch((err) => {
            dispatch(postApiInvokeFailed());
            return null;
        });
}

export const getSinglePracticePost = (id) => (dispatch, getState, services) => {
    dispatch(postApiInvoke());
    let practice_service = services.get("PracticeService");
    return practice_service
        .getPracticePost(id)
        .then((res) => {
            dispatch(postApiInvokeSuccess());
            // dispatch(setPracticeFeed(res.data && res.data.length > 0 ? res.data : []));
            return res.data[0];
        })
        .catch((err) => dispatch(postApiInvokeFailed()));
}


export const fetchPracticePostsByGroupId = (id, offset, limit) => (dispatch, getState, services) => {
    dispatch(postApiInvoke());
    let practice_service = services.get("PracticeService");
    return practice_service
        .getPracticePostsByGroup(id, offset, limit)
        .then((res) => {
            const posts = res.data && res.data.map((item) => ({
                ...item,
                group: item.group,
                user: item.user,
            }));

            dispatch(postApiInvokeSuccess());
            dispatch(setPracticeFeed(posts));
            return res;

        })
        .catch((err) => dispatch(postApiInvokeFailed()));
};

export const updatePracticePost = (response) => (dispatch, getState, services) => {
    let practice_service = services.get("PracticeService");
    practice_service
        .updatePracticePost(response)
        .then((res) => {
            dispatch(practiceUpdate(response));
            // return res;
        })
        .catch((err) => dispatch(postApiInvokeFailed()));
};

export const deletePracticePost = (post_id) => (dispatch, getState, services) => {
    dispatch(postApiInvoke());
    let practice_service = services.get("PracticeService");
    practice_service
        .deletePracticePost({ post_id })
        .then((res) => dispatch(postApiInvokeSuccess()))
        .catch((err) => dispatch(postApiInvokeFailed()));
};

export const fetchPracticeCritiques = post_id => (dispatch, getState, services) => {
    let practice_service = services.get("PracticeService")
    practice_service.getCommentsByPostId(post_id)
        .then(res => {
            const critique = res.data.map(c => ({
                ...c,
                user: c.user
            }));
            dispatch(setCritiquesFromPractice(critique));
        })
        .catch(err => console.log({ err }))
}

export const requestAddCritique = (critique) => (dispatch, getState, services) => {
    let practice_service = services.get('PracticeService');
    return practice_service.addPracticeComment(critique)
        .then((res) => {
            const critique = {
                ...res.data[0],
                user: res.data[0].user
            }
            dispatch(addToCritiques(critique))
        })
        .catch((err) => console.log({ err }))
}

export const requestUpdateCritique = (critique) => (dispatch, getState, services) => {
    let practice_service = services.get('PracticeService');
    practice_service.editPracticeComment(critique)
        .then((res) => {
            dispatch(updateCritique(critique))
        })
        .catch((err) => console.log({ err }))
}

export const requestDeleteCritique = (id) => (dispatch, getState, services) => {
    let practice_service = services.get('PracticeService');
    practice_service.deletePracticeComment(id)
        .then((res) => {
            dispatch(critiqueDelete(id))
        })
        .catch((err) => console.log({ err }))
}

export const fetchUserPracticePosts = (id,offset,limit) => (dispatch, getState, services) => {
    const UserService = services.get("PracticeService")
    return UserService.getUserPracticePosts(id,offset,limit)
        .then((res) => {
            const { posts } = res.data[0].posts
            const psts = posts;
            if (psts && psts.length > 0) {
                dispatch(setUserPosts(psts))
            }
            return res;
        }).catch((err) => console.log({ err }))
}

export const fetchPracticeTempPost = (id) => (dispatch, getState, services) => {
    let practice_service = services.get('PracticeService');
    return practice_service.getPracticeTempPost(id)
        .then((res) => {
            // dispatch(postApiInvokeSuccess());
            // dispatch(setPracticeFeed(res.data && res.data.length > 0 ? res.data : []));
            return res.data[0];
        })
        .catch((err) => console.log({ err }))
}