import React from 'react'
import WithoutHeader from '../../components/shared/WithoutHeader'
import RestorePasswordContainer from '../../components/containers/Auth/RestorePassword'

function RestorePassword() {
    return (
        <WithoutHeader>
            <RestorePasswordContainer />
        </WithoutHeader>
    )
}

export default RestorePassword;
