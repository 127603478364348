import React from "react";

import { Row, Col, Modal, Typography } from "antd";

import { pricing } from "../../../utils/statics";
import { useLocation } from "react-router-dom";

import PricingCard from "../../snippets/cards/Pricing";

const { Text } = Typography;

function SubscriptionContainer() {
  let params
  const location = useLocation();
  params = location.state;
  const next = () => { };


  React.useEffect(() => {
    //Subscription Notification
    Modal.info({
      title: 'Choose your subscription package',
      content: (
        <div>
          <Text strong>Welcome to The Write Practice! Begin your writing journey by subscribing to a plan below</Text>
        </div>
      ),
      onOk() { },
    });
  });


  return (
    <div className="bg-primary bg-c1 inner">
      <div className="steps-content">
        <div className="twp-wrapper inner">
          <Row className="inner t-center">
            <h1>Plans & Pricing</h1>
            <p>
              Sign up for one of our affordable monthly payment options below!
              Also, save by signing up for the annual membership!
            </p>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <PricingCard
                title="100 Day Book"
                price="$49"
                priceSuffix="/ per month"
                list={pricing.hundredDay}
                // onSubscribe={() => next()}
                // btnText="Skip"
                subParams={params}
              />
            </Col>
            <Col span={8}>
              <PricingCard
                title="The Write Practice Pro Basic"
                price="$15"
                priceSuffix="/ per month"
                packageSuffix="Best for Short Stories and Articles"
                list={pricing.proBasic}
                // onSubscribe={() => next()}
                // btnText="Skip"
                subParams={params}
              />
            </Col>
            <Col span={8}>
              <PricingCard
                title="The Write Practice Pro Premium"
                price="$25"
                priceSuffix="/ per month"
                list={pricing.proPremium}
                packageSuffix="Best for Novels and Books"
                // onSubscribe={() => next()}
                btnText="Skip"
                subParams={params}
              />
            </Col>
            {/* <Col span={6}>
              <PricingCard
                title="Free"
                price="FREE"
                list={pricing.free}
                onSubscribe={() => next()}
                btnText="Skip"
                subParams={params}
              />
            </Col> */}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionContainer;
