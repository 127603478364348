import React, { ErrorInfo } from "react";
import { withScope, captureException, showReportDialog } from '@sentry/browser';
import { Result, Button, Icon, Typography } from 'antd';

interface ErrorBoundaryProps {
    component?: any
}

interface ErrorBoundaryState {
    error: any,
    errorInfo: any,
    eventId: any
}

const { Paragraph, Text } = Typography;
const { NODE_ENV } = process.env

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null,
            eventId: null
        };

        this.reportToSentry = this.reportToSentry.bind(this)
    }
    
    static getDerivedStateFromError(error: Error) {
        return { errorInfo: true }; // Update state so the next render will show the fallback UI.
    }

    componentDidCatch(error: Error, errorInfo) { //errorInfo: ErrorInfo
        this.setState({
            error: error,
            errorInfo: errorInfo,
        })
        if(NODE_ENV !== 'development'){
            withScope(scope => {
                scope.setExtras(errorInfo);
                const eventId = captureException(error);
                this.setState({ eventId })
            });
        }
    }

    reportToSentry() {
        showReportDialog({ eventId: this.state.eventId })
        const helpURL = `https://thewritepractice.zendesk.com/hc/en-us/requests/new`
        var win = window.open(helpURL, '_blank');
    }

    render() {
        if (this.state.errorInfo) { // Check if error exist and render fallback UI
            return (
                <div className="area">
                    <Result
                        status="warning"
                        title="The WritePractice PRO could not be loaded"
                        subTitle="We're sorry. There seems to be a problem with the application. Try repeating your actions to see if it works, if not click the 'Report Feedback' button, to reach us at Help Desk"
                        extra={NODE_ENV !== 'development' ? [
                            <Button type="danger" onClick={this.reportToSentry}>Report feedback</Button>
                        ] : null}
                    >
                        {NODE_ENV === 'development' ? (
                            <div className="desc">
                                <Paragraph>
                                    <Text></Text>
                                </Paragraph>
                                <Paragraph>
                                    <details style={{ whiteSpace: 'pre-wrap' }}>
                                        {this.state.error && this.state.error.toString()}
                                        {this.state.errorInfo.componentStack}
                                    </details>
                                </Paragraph>
                            </div>
                        ): null}
                    </Result>
                </div>
            );
        }
        return this.props.children; // Render children default

    }
}



export default ErrorBoundary;