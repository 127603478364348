import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Avatar,
  Row,
  Col,
  Icon,
  Descriptions,
  Tag,
  Button,
  Modal,
  Select,
  List,
  Spin
} from "antd";

import { months } from "../../../../utils/statics";
import SettingsBody from "../../../shared/SettingsBody";
import EditProfileContainer from "./EditProfileContainer";
import { fetchUserFollowers, fetchUserFollowing } from "../../../../actions/user";

interface Bio {
  badges: any;
  bio: string;
  books_published: any;
  comments_count: number;
  email: string;
  first_name: string;
  genre: any;
  id: number;
  member_since: string;
  last_name: string;
  posts_count: number;
  profile_pic: any;
  subscription_plan: string;
  user_groups: any;
  user_role: string;
  writing_goal: any;
  website: string;
}

interface Props {
  bio: Bio;
  loading: boolean;
  groups: Array<any>;
  fetchUserFollowers: (user_id: any) => any;
  fetchUserFollowing: (user_id: any) => any;
}

const { Option } = Select;


function ProfileContainer(props: Props) {
  let site;
  const { bio, groups } = props;
  const [editor, toggleEditor] = React.useState(false);
  const [userProfileGroups, setUserProfileGroups] = useState<Array<any>>([]);
  const [followers, setUserFollowers] = React.useState<Array<any>>([]);
  const [visible, setVisible] = useState(false);
  const [following, setFollowingList] = React.useState<Array<any>>([]);
  const [folllowingvisible, setFollowingVisible] = useState(false);
  const [followMessage, setFollowMessage] = useState(false);
  const [followingMessage, setFollowingMessage] = useState(false);

  const user_id = localStorage.getItem("twp-user-id");

  React.useEffect(() => {
    toggleEditor(false);
    // getFollowerList()
  }, [bio]);

  React.useEffect(() => {
    getFollowerList()
    getFollowingList()
  }, []);

  const getFollowerList = async () => {
    // people who are following the user
    const followerList = await props.fetchUserFollowers(user_id);

    if (followerList.success == false) {
      setFollowMessage(true)
    } else {
      setUserFollowers(followerList.data);
    }
    // return followerList;
  };

  //Shows Modal
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const getFollowingList = async () => {
    //people who the user is following
    const followingList = await props.fetchUserFollowing(user_id);

    if (followingList.success == false) {
      setFollowingMessage(true)
    } else {
      setFollowingList(followingList.data);
      // return followingList;
    }
  };


  //Shows Modal
  const showFollowingModal = () => {
    setFollowingVisible(true);
  };

  const handleFollowingCancel = () => {
    setFollowingVisible(false);
  };

  //External Link URL for the bio website
  if (bio.website && !bio.website.match(/^(https|http):\/\//g)) {
    site = `//${bio.website}`;
  }

  const d = new Date(bio.member_since);
  const date = `${months[d.getMonth()]} / ${d.getFullYear()}`;

  return (
    <SettingsBody active="profile">
         {props.loading ? (
        <div className="loading-spin">
          <Spin />
        </div>
      ) : (
      editor ? (
        <div>
          <Row type="flex" align="middle" gutter={16} className="inner">
            <Col>
              <Button onClick={() => toggleEditor(false)}>
                <Icon type="arrow-left" /> Back
              </Button>
            </Col>
            <Col>
              <h2 className="imp">Edit Profile</h2>
            </Col>
          </Row>
          <EditProfileContainer />
        </div>
      ) : (
        <div className="inner">
          <Row type="flex" justify="space-between">
            <Col span={8}>
              {bio.profile_pic === "" ? (
                <Avatar
                  size={120}
                  src="/assets/twp-logo.jpeg"
                />
              ) : (
                <Avatar size={120} src={bio.profile_pic} />
              )}
            </Col>
            <Col span={8}>

            </Col>
            <Col span={8}>
              <Button icon="edit" onClick={() => toggleEditor(true)}>
                Edit
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <h2 className="subtitle">
              {bio.first_name} {bio.last_name}
            </h2>
            {/* <Row>
              <Col span={3}>
                {followMessage == true ? (
                  <label>0 Followers</label>
                ) : <label onClick={showModal}>{followers.length} Followers</label>}
              </Col>
              <Col span={12}>
                {followingMessage == true ? (
                  <label>0 Following</label>
                ) : <label onClick={showFollowingModal}>{following.length} Following</label>}
              </Col>
            </Row> */}
            <br />
            <label>{bio.bio}</label>
          </Row>
          <br />
          <Descriptions
            layout="vertical"
            column={1}
            className="profile-description"
          >
            {followMessage == true ? (
              <Descriptions.Item label="Followers">
                0
              </Descriptions.Item>
            ) : <Descriptions.Item label="Followers"><p onClick={showModal} style={{cursor: "pointer"}}>{followers.length}</p></Descriptions.Item>}

            {followingMessage == true ? (
              <Descriptions.Item label="Following">
                0
              </Descriptions.Item>
            ) : <Descriptions.Item label="Following">
              <p onClick={showFollowingModal} style={{cursor: "pointer"}}>{following.length}</p>
            </Descriptions.Item>}
            <Descriptions.Item label="Email">
              {bio.email}
            </Descriptions.Item>
            {bio.website ? (
              <Descriptions.Item label="Website">
                <a className="link" href={site} target="_blank" rel="noopener noreferrer">
                  {bio.website}
                </a>
              </Descriptions.Item>
            ) : null}
            <Descriptions.Item label="Member since">{date}</Descriptions.Item>
            <Descriptions.Item label="Critiques">
              {bio.comments_count}
            </Descriptions.Item>
            {bio.writing_goal ? (
              <Descriptions.Item label="Writing Goal">
                {bio.writing_goal}
              </Descriptions.Item>
            ) : null}
            {bio.books_published ? (
              <Descriptions.Item label="Books Published">
                {bio.books_published}
              </Descriptions.Item>
            ) : null}

            <Descriptions.Item label="Active groups">
              <div>
                {groups && groups.length > 0 ? (
                  groups.map((grp, i) => (
                    <div className="profile-tags">
                      <Tag className="twp-bubble" key={"profile_grp_" + grp.group_id}>
                        <Avatar size="small">
                          {grp.group_name[0].toUpperCase()}
                        </Avatar>
                        <span className="twp-bubble-text">
                          {grp.group_name}
                        </span>
                      </Tag>
                    </div>
                  ))
                ) : (
                  <span>None</span>
                )}
              </div>
            </Descriptions.Item>
          </Descriptions>
        </div>
      )

      )}
      <Modal
        title={"Followers"}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <Row style={{ paddingBottom: 10 }}>
          <div>
            <Col span={18}>
              <List
                itemLayout="horizontal"
                dataSource={followers}
                renderItem={(a: any, index) => (
                  <List.Item key={index + "_followers"}>
                    <List.Item.Meta
                      avatar={<Avatar src={a.profile_pic} />}
                      title={
                        <a href={`/user/profile/${a.id}`}>{a.followers}</a>
                      }
                    />
                    <div>

                    </div>
                  </List.Item>
                )}
              />
            </Col>
          </div>
        </Row>
      </Modal>
      <Modal
        title={"Following"}
        visible={folllowingvisible}
        onCancel={handleFollowingCancel}
        footer={null}
      >
        <Row style={{ paddingBottom: 10 }}>
          <div>
            <Col span={18}>
              <List
                itemLayout="horizontal"
                dataSource={following}
                renderItem={(a: any, index) => (
                  <List.Item key={index + "_following"}>
                    <List.Item.Meta
                      avatar={<Avatar src={a.profile_pic} />}
                      title={
                        <a href={`/user/profile/${a.id}`}>{a.following}</a>
                      }
                    />
                    <div>

                    </div>
                  </List.Item>
                )}
              />
            </Col>
          </div>
        </Row>
      </Modal>
    </SettingsBody>
  );
}

const Actions = {
  fetchUserFollowers,
  fetchUserFollowing
};

const mapStoP = (state: any) => ({
  bio: state.user.bio,
  groups: state.user.groups,
  loading: state.posts.loading
});

export default connect(mapStoP, Actions)(ProfileContainer);
