import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Menu, Avatar, Row, Col, Icon, Button } from "antd";
import { Link } from "react-router-dom";

import { fetchAllGroups, fetchAdminGroups, followGroups } from "../../../actions/groups";
import { fetchFeedPosts } from "../../../actions/posts"
import { fetchThinkificToken } from "../../../actions/user";

interface Props {
  groups: Array<any>;
  fetchAllGroups: any;
  fetchThinkificToken: () => any;
  fetchAdminGroups: () => any;
  followGroups: (group_id: number) => any;
  fetchFeedPosts: (offset: number, limit: number) => any;
}

function GroupMenuContainer(props: Props) {
  //To enable admin features
  const userRole = localStorage.getItem("twp-user-role");
  const [adminGroups, setAdminGroups] = useState<Array<any>>([]);

  useEffect(() => {
    if (props.groups.length < 1) {
      props.fetchAllGroups();
    };

    if (userRole === "admin") {
      getAllAdminGroups();
    };
  }, []);

  //my courses onClick method
  const routeFromMyCourse = () => {
    const subs = props.fetchThinkificToken().then((res) => {
      const thinificURL = `https://thewritepractice.thinkific.com/api/sso/v2/sso/jwt?jwt=${res.thinkific_token}`
      var win = window.open(thinificURL, '_self');
    });
  };

  const getAllAdminGroups = async () => {
    const adGrps = await props.fetchAdminGroups();
    setAdminGroups(adGrps);
    return adGrps;
  };


  return (
    <Menu
      id="sidebar"
      mode="inline"
      defaultSelectedKeys={["1"]}
      defaultOpenKeys={["sub1"]}
      style={{ height: "100%", borderRight: 0, width: 320 }}
    >
      <Menu.Item>
        <Link to="/">
          <Icon type="layout" /> Feed
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/create-post">
          <Icon type="tool" /> Workshop
        </Link>
      </Menu.Item>
      <Menu.Item>
        <span onClick={routeFromMyCourse}>
          <Icon type="book" /> My Courses
        </span >
      </Menu.Item>
      <Menu.Item>
        <Link to="/articles">
          <Icon type="read" /> Practice
        </Link>
      </Menu.Item>
      <Menu.ItemGroup key="g1" title="Group" className="tour-groups">
        {userRole === "admin" ? (
          adminGroups.map((group, index) => (
            group.isDeleted == "false" ? (
              <Menu.Item key={"_grp " + index} style={{ right: 10, overflowWrap: "break-word", wordWrap: "break-word" }} >
                <a href={`/group/${group.id}`}>
                  <Row type="flex" gutter={15} align="middle">
                    <Col>
                      <Avatar size={20} src={group.icon} />
                    </Col>
                    <Col style={{ width: 10, paddingRight: 30 }}>{group.group_name}</Col>
                  </Row>
                </a>
              </Menu.Item>
            ) : null
          ))
        ) : (
          props.groups.map((group, index) => (
            <Menu.Item key={"_grp " + index} style={{ right: 10, overflowWrap: "break-word", wordWrap: "break-word" }} >
              <a href={`/group/${group.id}`}>
                <Row type="flex" gutter={15} align="middle">
                  <Col>
                    <Avatar size={20} src={group.icon} />
                  </Col>
                  <Col style={{ width: 10, paddingRight: 30 }}>{group.group_name}</Col>
                </Row>
              </a>
            </Menu.Item>
          ))
        )}
      </Menu.ItemGroup>
      {userRole === "admin" ? (
        <div>
          <Button className="new-group" type="primary">
            <Link className="new-group-txt" to="/groups">
              New Group <Icon type="plus" />
            </Link>{" "}
          </Button>
        </div>
      ) : null}

    </Menu>

  );
}

const mapStateToProps = (state) => ({
  groups: state.groups.list,
});

const Actions = {
  fetchAllGroups,
  fetchThinkificToken,
  fetchAdminGroups,
  followGroups,
  fetchFeedPosts
};

export default connect(mapStateToProps, Actions)(GroupMenuContainer);
