import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { useLocation } from "react-router-dom";
import { unionBy, find, filter } from "lodash";
import {
  EditorState,
  ContentState,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Input, Form, Select, Typography } from "antd";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

interface Post {
  id?: string;
  title: string;
  post: string;
  summary: string;
  word_count: string;
  group_name: string;
  // genre_id?: number;
  // feedback?: string;
}

interface Props {
  onChange: ({ }: Post) => void;
  post?: Post;
  mini?: Boolean;
  word_count: any;
  draftPost?: Boolean;
  draftId?: any;
  postUserId?: string
  editGrpWordCount?: any
  practiceGroup? : any;
}

const initPost = {
  title: "",
  post: "",
  summary: "",
  word_count: "",
  group_name: ""
};

const save_interval = parseInt(
  process.env.REACT_APP_DRAFT_TIMESTAMP
    ? process.env.REACT_APP_DRAFT_TIMESTAMP
    : "10000"
);

// A custom hook that builds on useLocation to parse the query string for you.
function useQuery(key) {
  return new URLSearchParams(useLocation().search).get(key);
}

function MessageEditor(props: Props) {
  const query = useQuery("drafts") || "";
  const [editor, setEditor] = React.useState(EditorState.createEmpty());
  const [draft, setDraft] = React.useState(initPost);
  const { post } = props;
  const maxWords = props.word_count;
  const [obj, setObj] = React.useState<Post>(post || initPost);
  const [words, setWords] = React.useState(0);
  const [wordLimit, setWordLimit] = React.useState(props.word_count);
  const [draftMessage, setDraftMessage] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const someRef = React.useRef(0);

  //draft id --- see if you already have it in the query
  const [draftId, setDraftId] = React.useState<any>(
    query
      ? query
      : Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 5)
  );


  const userId = localStorage.getItem("user-id")

  React.useEffect(() => {
    if (props.post && props.post.post) {
      const parsed = htmlToDraft(props.post.post.toString());

      const conversion = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          parsed.contentBlocks,
          parsed.entityMap
        )
      );
      setEditor(conversion);
    }
    setWordLimit(props.word_count);

    //sets the default title on load - fixes the previous issue where title loaded only when editor was clicked
    if(props.mini === false) {
      setObj({
        ...obj,
        title: `Practice Post for ${props.practiceGroup}`, 
      });
    } else  {
      setObj({
        ...obj,
        title: `Practice Post for ${post}`,
      });
    }
  }, [props.post]);


 
  React.useEffect(() => {
    const text = convertToRaw(editor.getCurrentContent());
    const html = draftToHtml(text);

    //checks length
    const s = text.blocks[0]
      ? text.blocks[0].text.split(" ").splice(0, 40).join(" ").toString()
      : "";
    const t = text.blocks.reduce((acc, r) => `${r.text} ${acc}`, "");
    const f = t.trim().split(/\s+/).length;

    setWords(f < 2 ? 0 : f);

    if(props.mini === false) {
      setObj({
        ...obj,
        title: `Practice Post for ${props.practiceGroup}`, 
        summary: s,
        post: html,
        word_count: words.toString()
      });
    } else  {
      setObj({
        ...obj,
        title: `Practice Post for ${post}`,
        summary: s,
        post: html,
        word_count: words.toString()
      });
    }

  }, [editor]);

  
  React.useEffect(() => {
    props.onChange(obj);
    if (!props.postUserId) {
      const timer = setTimeout(() => {
        if (save_interval) {
          setDraftMessage(true);
        }
      }, save_interval);
      return () => clearTimeout(timer);
    }
  }, [obj]);

  return (
    <Form name="writing-piece=form">
      <Form.Item>
        <Input
          id="title"
          placeholder="Title"
          size="large"
          value={obj.title}
          // onChange={(e) => setObj({ ...obj, title: e.target.value })}
        />
      </Form.Item>
      <Editor
        handlePastedText={() => false}
        editorState={editor}
        onEditorStateChange={setEditor}
        toolbar={{
          options: ["inline", "link", "list"],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          list: {
            // className: "post-container",
            options: ["unordered", "ordered"],
          },
        }}
        wrapperClassName="twp-editor-wrapper"
        editorClassName="twp-editor"
      />
      {props.editGrpWordCount ? (
        <div>
          {words <= maxWords ? (
            <span>
              {words} / {maxWords}
            </span>
          ) : (
            <span style={{ color: "red" }}>
              {words} / {props.editGrpWordCount}
            </span>
          )}
        </div>
      ) : (
        <div>
          {words <= maxWords ? (
            <span>
              {words} / {maxWords}
            </span>
          ) : (
            <span style={{ color: "red" }}>
              {words} / {props.word_count}
            </span>
          )}
        </div>
      )}
    </Form>
  );
}

export default MessageEditor;
