import React, { useState } from 'react'
import { connect } from 'react-redux'
import { List } from 'antd'
import { fetchPostsByGroupId } from '../../../actions/posts';
import { useParams } from 'react-router-dom';
import { find } from 'lodash';

import ListingSkeleton from '../../snippets/skeleton/Listing'
import PostBlock from '../../snippets/listing/PostBlock'

interface Props {
    fetchPostsByGroupId: (id: number) => void,
    posts: any,
    loading: boolean;
}

function ListingPostContainer(props: Props) {
    const { id } = useParams();
    const [post, setPost] = useState({
        title: "",
        post: "",
        name: "",
        created_at: ""
    });

    React.useEffect(() => {
        props.fetchPostsByGroupId(parseInt(id ? id : ""));
    }, []);

    React.useEffect(() => {
        if (props.posts.length > 0) {
            let p = find(props.posts);
            setPost(p);
        }
    });

    return props.loading ? (
        <div className="twp-wrapper">
            <ListingSkeleton count={3} />
        </div>
    ) : (
            <div className="reader">
                <List
                    dataSource={props.posts}
                    renderItem={PostBlock}
                />
            </div>
        );
}

const actions = {
    fetchPostsByGroupId
};

const mapStateToProps = (state: any) => ({
    posts: state.posts.list,
    loading: state.posts.loading
})
export default connect(mapStateToProps, actions)(ListingPostContainer)
