import React, { useContext, useState } from "react";
import { Layout, Icon, Row, Col, Dropdown, Drawer, Menu, Avatar } from "antd";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import ErrorBoundary from "./ErrorBoundary";
import GroupDropdownContainer from "../containers/Groups/GroupDropdownContainer";
import NetworkDetector from "./NetworkDetector";

import { AppContext } from "../../utils/context";
import NotificationDropdownContainer from "../containers/Settings/Notifications/NotificationDropdownContainer";
import AssignmentContainer from "../containers/Assignments/AssignmentContainer";
import GroupMenuContainer from "../containers/Groups/GroupMenuContainer";

import { fetchAllGroups, fetchProfileGroups } from "../../actions/groups";
import { Hide } from "../shared/Responsive";
import { fetchThinkificToken } from "../../actions/user";
import ElasticSearchHeader from "../../components/shared/ElasticSearchHeader";
import Tour from "../containers/Tour/Tour";

const { Content, Sider } = Layout;

interface Bio {
  profile_pic: any;
  id: any;
}

interface BodyProps {
  children?: any;
  bio: Bio;
  groups: Array<any>;
  fetchThinkificToken: () => any;
  fetchProfileGroups: any;
  fetchAllGroups: any;
  highlights?: (text: string) => any;
}

function Body(props: BodyProps) {
  let isCourseLink;
  let courseLink;
  let history = useHistory();
  const location = useLocation();
  const { header, sidebar, responsive } = useContext(AppContext);
  const [groupNav, setGroupNav] = useState(false);
  const [search, setSearch] = useState("");
  const [notificationClick, setNotificationClick] = useState(false);
  const [notificationTimestamp, setNotificationTimestamp] = useState("");
  const [tour, setTour] = useState(false);

  const [linkPromoCode, setPromoCode] = useState("");

  //Differentiating the Logged In Profile and the Search Profile Image
  let searchBioImage = localStorage.getItem("search-user-profile") || props.bio.profile_pic;

  //Log Out
  const logout = () => {
    localStorage.removeItem("twp-token");
    history.push("/auth/login");

    //add thinkific signout
    //automatically signs out thinkific too
    // setTimeout(function () {
    // window.location.replace("https://thewritepractice.thinkific.com/users/sign_out");
    // }, 1000);
  };

  //Capture Search value
  const onSearch = (value: string) => {
    setSearch(value);
  };

  //my courses onClick method
  const routeFromMyCourse = () => {
    const subs = props.fetchThinkificToken().then((res) => {
      const thinificURL = `https://thewritepractice.thinkific.com/api/sso/v2/sso/jwt?jwt=${res.thinkific_token}`;
      var win = window.open(thinificURL, "_self");
    });
  };

  //Notification pings
  const handleClick = (e) => {
    let notificationClick = "true";
    var t = moment().format("YYYY-MM-DD hh:mm:ss");

    if (notificationClick == "true") {
      setNotificationClick(true);
      setNotificationTimestamp(t);
      // save the time the dropdown was last opened
      localStorage.setItem("twp-notification-open-ping", t);
    } else {
      setNotificationClick(false);
    }
  };

  const siteWalk = (e?) => {
    let siteClick = "true";
    if (siteClick === "true") {
      history.push("/");
      setTour(true);
    } else {
      setTour(false);
    }
  };

  function highlightCallBack(val) {
    if (val === "false") {
      setTour(false);
    } else {
      setTour(true);
    }
  }

  function onHelpClick() {
    const thinificURL = `https://thewritepractice.zendesk.com/hc/en-us`;
    var win = window.open(thinificURL, "_blank");
  }

  function onOrientationClick() {
    const subs = props.fetchThinkificToken().then((res) => {
      // const thinificURL = `https://courses.thewritepractice.com/courses/take/getting-started/lessons/21288756-how-to-use-the-write-practice-pro-workshop`
      // https://thewritepractice.thinkific.com/api/sso/v2/sso/jwt?jwt=${res.thinkific_token}
      const thinificURL = `http://pro.thewritepractice.com/auth/login?source=https://courses.thewritepractice.com/manage/courses/1173984/contents/21288756`;
      var win = window.open(thinificURL, "_blank");
    });
  }

  React.useEffect(() => {
    const mainGrp = props.fetchProfileGroups;
  }, []);

  React.useEffect(() => {
    isCourseLink = location.search; //capture the query params
    courseLink = new URLSearchParams(isCourseLink).get("source") || "";
    courseLink.slice(0, 9);

    setPromoCode(courseLink);
    //   if (promoCode) {
    //     setVisible(true);
    //     toggleLoading(true)
    //     // validatePromoCode(promoCode)
    //   }
  }, [isCourseLink]);

  return (
    <ErrorBoundary>
      <NetworkDetector />
      <Layout className="twp-app">
        {header ? (
          <div className="twp-header" id="header">
            <Row className="twp-wrapper" type="flex" align="middle">
              <div className="logo">
                <Hide in="xs">
                  <Link to="/">
                    <img src="/assets/twp-logo.jpeg" width={40} alt="Logo" />
                  </Link>
                </Hide>
              </div>
              <Row type="flex" align="middle" gutter={14}>
                <Col>
                  {responsive < 521 ? (
                    <GroupDropdownContainer responsive={true} />
                  ) : (
                    <GroupDropdownContainer />
                  )}
                </Col>
                {/* <Hide in="xs"> */}
                <Col>
                  {responsive < 521 ? (
                    <ElasticSearchHeader size={90} />
                  ) : (
                    <ElasticSearchHeader />
                  )}
                </Col>
                {/* </Hide> */}
              </Row>
              <Hide in="xs">
                <ul className="link-list horizontal">
                  <li style={{ display: "none" }}>
                    <Link to="/create-post">
                      <Icon type="book" /> Practice
                    </Link>
                  </li>
                  <li>
                    <Link to="/create-post">
                      <Icon type="tool" /> Workshop
                    </Link>
                  </li>
                  <li>
                    <a className="body-courses" onClick={routeFromMyCourse}>
                      <Icon type="book" /> My Courses
                    </a>
                  </li>
                </ul>
              </Hide>
              {responsive > 521 ? <div style={{ flex: 1 }}></div> : null}
              <div style={{ flex: 1 }}></div>
              <ul className="menu-icon-list">
                <Hide in="sm">
                  <li className="body-assignments">
                    <AssignmentContainer />
                  </li>
                </Hide>
                <li id="search" onClick={handleClick}>
                  <NotificationDropdownContainer
                    onClick={notificationClick}
                    timeStamp={moment().format("YYYY-MM-DD hh:mm:ss")}
                  />
                </li>
                <li>
                  <Dropdown
                    overlay={
                      <Menu className="link-list">
                        <Menu.Item key={`li-1`}>
                          <Link to="/profile">Profile</Link>
                        </Menu.Item>
                        <Menu.Item key={`li-2`}>
                          <Link to="/drafts">Drafts</Link>
                        </Menu.Item>
                        <Menu.Item key={`li-3`}>
                          <Link to="/settings">Settings</Link>
                        </Menu.Item>
                        <Menu.Item key={`li-4`} className="red">
                          <Link to="/#" onClick={logout}>
                            Logout
                          </Link>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    {props.bio.profile_pic != "" ? (
                      <Avatar
                        className="body-profile"
                        size={32}
                        src={props.bio.profile_pic}
                      />
                    ) : (
                      <Avatar
                        className="body-profile"
                        size={32}
                        src="/assets/twp-logo.jpeg"
                      />
                    )}
                  </Dropdown>
                </li>
                {responsive < 521 ? (
                  <li>
                    <Dropdown
                      overlay={
                        <Menu className="link-list">
                          <Menu.Item key={`li-1`}>
                            <AssignmentContainer />
                          </Menu.Item>
                          <Menu.Item key={`li-2`}>
                            <span onClick={() => onHelpClick()}>Help Desk</span>
                          </Menu.Item>
                          <Menu.Item key={`li-3`}>
                            <span onClick={() => onOrientationClick()}>
                              Orientation
                            </span>
                          </Menu.Item>
                          <Menu.Item key={`li-3`}>
                            <Link to="/activity-feed">Activity Feed</Link>
                          </Menu.Item>
                          <Menu.Item key={`li-4`} className="red">
                            <a onClick={routeFromMyCourse}>
                              <Icon type="book" /> My Courses
                            </a>
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Icon type="menu" />
                    </Dropdown>
                  </li>
                ) : null}
                <div className="link-list help" style={{ paddingLeft: 10 }}>
                  <Hide in="sm">
                    <Dropdown
                      className="help-desk"
                      overlay={
                        <Menu>
                          <Menu.Item key={`li-1`}>
                            <span onClick={() => onHelpClick()}>Help Desk</span>
                          </Menu.Item>
                          <Menu.Item key={`li-2`}>
                            <span onClick={() => onOrientationClick()}>
                              Orientation
                            </span>
                          </Menu.Item>
                          <Menu.Item key={`li-3`}>
                            <span onClick={() => siteWalk()}>Site Tour</span>
                            {tour && (
                              <Tour
                                start={tour}
                                highlights={highlightCallBack}
                              />
                            )}
                          </Menu.Item>
                          <Menu.Item key={`li-3`}>
                            <a
                              target="_blank"
                              href="https://thewritepractice.com/terms-and-conditions/"
                              rel="noopener noreferrer"
                            >
                              Terms & Condition
                            </a>
                            {/* <span onClick={() => siteWalk()}>Terms & Conditions</span>
                            {tour  && <Tour start={tour} highlights={highlightCallBack}/> } */}
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Avatar
                        className="notif-btn"
                        size={32}
                        shape="square"
                        icon={
                          <Icon
                            type="question"
                            style={{ fontSize: 16, fontWeight: "bold" }}
                          />
                        }
                      />
                    </Dropdown>
                  </Hide>
                </div>
              </ul>
            </Row>
            <Drawer
              visible={groupNav}
              onClose={() => setGroupNav(false)}
              className="twp-group-drawer"
            >
              <GroupDropdownContainer />
            </Drawer>
          </div>
        ) : null}
        {sidebar && responsive > 1024 ? (
          <Layout>
            <Sider theme="light" width={300}>
              <GroupMenuContainer />
            </Sider>
            <Content className="twp-body">
              <div className="inner" />
              {props.children}
            </Content>
          </Layout>
        ) : (
          <Content className="twp-body">{props.children}</Content>
        )}
      </Layout>
    </ErrorBoundary>
  );
}

const Actions = {
  fetchThinkificToken,
  fetchAllGroups,
  fetchProfileGroups,
};
const mapStoP = (state: any) => ({
  bio: state.user.bio,
  groups: state.user.groups,
});

export default connect(mapStoP, Actions)(Body);
