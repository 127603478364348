import React from "react";
import { partition } from "lodash";
import { Link } from "react-router-dom";

import { Row, Typography, Col, Icon } from "antd";
import { formatTime } from "../../../utils/helpers";
import ProfileAvatar from "../Avatars/ProfileAvatar";

const { Text } = Typography;

const MAX_LENGTH = 40;

function PostBlock(props: any) {
  var groupObj = props.group;

  const readMore = " read more";
  const link = `/post/${props.post_id}`;
  const grpLInk = `/group/${groupObj.group_id}`;
  const practiceLink = `/practice/post/${props.post_id}`;
  const date = formatTime(props.created_at);

  let splitTitle = props.title.substring(0, 150);

  const [nested, main] = partition(props.comments, (o) => o.parent_id);

  return (
    <div className="post-block" key={props.index + "_post"}>
      {props.post_type === "Message" ? (
        <>
          <Row
            type="flex"
            align="bottom"
            justify="space-between"
            className="action-row"
          >
            {props.user && props.group ? (
              <Col>
                <ProfileAvatar
                  title={
                    props.user.name +
                    " posted a " +
                    "<a class='feed-message-font-type'>" +
                    "message" +
                    "</a>" +
                    " in the " +
                    "<a href=" +
                    `${grpLInk}` +
                    ">" +
                    groupObj.group_name +
                    "</a>" +
                    " group"
                  }
                  subtitle={date}
                  img={props.user.profile_pic}
                  size={36}
                  userRole={props.user.user_role}
                  postType={"Message"}
                  postWordCount={props.word_count}
                />
              </Col>
            ) : (
              <Col>
                <span>{date}</span>
              </Col>
            )}
          </Row>
          <Row>
            <br />
            {props.group_category === "Practice" ? (
              props.title.length > MAX_LENGTH ? (
                // <Link to={link} className="post-title">
                //   {splitTitle}
                //   <a className="link" href={practiceLink}>
                //     {" "}
                //     ****read more
                //   </a>
                // </Link>
                <Link
                  to={link}
                  className="post-title"
                  dangerouslySetInnerHTML={{
                    __html:
                      splitTitle +
                      "<a class='link'  href=#" +
                      `${practiceLink}` +
                      ">" +
                      readMore +
                      "</a>",
                  }}
                ></Link>
              ) : (
                <Link to={link}>
                  <p
                    className="post-container post-title"
                    dangerouslySetInnerHTML={{
                      __html: props.title,
                    }}
                  ></p>
                </Link>
              )
            ) : props.title.length > MAX_LENGTH ? (
              // <Link to={link} className="post-title">
              //   {splitTitle}
              //   <a className="link" href={link}>
              //     {" "}
              //     read more
              //   </a>
              // </Link>
              <>
                <Link
                  to={link}
                  className="post-title"
                  dangerouslySetInnerHTML={{
                    __html:
                      splitTitle +
                      "<a class='link'  href=#" +
                      `${link}` +
                      ">" +
                      readMore +
                      "</a>",
                  }}
                ></Link>
              </>
            ) : (
              // <Link to={link}>
              //   <h2 className="post-title">{props.title}</h2>
              // </Link>
              <Link to={link}>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: props.title,
                  }}
                  className="feed-message-text"
                />
              </Link>
            )}

            <div className="post-description">
              {props.summary === null ? (
                <p></p>
              ) : (
                <div>
                  {props.summary.length > MAX_LENGTH ? (
                    props.group_category === "Practice" ? (
                      <p className="">
                        {props.summary}
                        <Link className="link" to={practiceLink}>
                          {" "}
                          read more
                        </Link>
                      </p>
                    ) : (
                      <p className="">
                        {props.summary}
                        <Link className="link" to={link}>
                          {" "}
                          ***** read more
                        </Link>
                      </p>
                    )
                  ) : (
                    <p>{props.summary}</p>
                  )}
                </div>
              )}
            </div>
            <div style={{ textAlign: "right" }}>
              <Col>
                <a href={link} className="feed-critique-font-type">
                  {main.length} Replies
                </a>
              </Col>
            </div>
          </Row>
        </>
      ) : (
        <>
          <Row>
            {props.group_category === "Practice" ? (
              <Link to={practiceLink}>
                <h2 className="post-title">{props.title}</h2>
              </Link>
            ) : (
              <Link to={link}>
                <h2 className="post-title">{props.title}</h2>
              </Link>
            )}

            <div className="post-description">
              {props.summary === null ? (
                <p></p>
              ) : (
                <div>
                  {props.group_category === "Practice" ? (
                    props.summary.length > MAX_LENGTH ? (
                      <p className="">
                        {props.summary}
                        <Link className="link" to={practiceLink}>
                          {" "}
                          88********read more
                        </Link>
                      </p>
                    ) : (
                      <p>{props.summary}</p>
                    )
                  ) : props.summary.length > MAX_LENGTH ? (
                    <p className="">
                      {props.summary}
                      <Link className="link" to={link}>
                        {" "}
                        read more
                      </Link>
                    </p>
                  ) : (
                    <p>{props.summary}</p>
                  )}
                </div>
              )}
            </div>
          </Row>
          <Row
            type="flex"
            align="bottom"
            justify="space-between"
            className="action-row"
          >
            {props.user && props.group ? (
              <Col>
                <ProfileAvatar
                  title={props.user.name}
                  subtitle={date}
                  img={props.user.profile_pic}
                  groupSubtitle={groupObj.group_name}
                  size={36}
                  userRole={props.user.user_role}
                />
              </Col>
            ) : (
              <Col>
                <span>{date}</span>
              </Col>
            )}
            <Col>
              <a href={link} className="feed-critique-font-type">
                {main.length} Critiques {nested.length} Replies
              </a>
              {/* <Link to={link}>{main.length} Critiques {nested.length} Replies</Link> */}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default PostBlock;
