// export const bookGenres = ['Adventure', 'Comic', 'Crime', 'Docufiction', 'Epistolary', 'Erotic', 'Fiction', 'Fantasy', 'Historical', 'Horror', 'Magic', 'Mystery', 'Paranoid', 'Philosophical', 'Political', 'Romance', 'Saga', 'Satire', 'Science', 'Speculative', 'Superhero', 'Thriller', 'Urban', 'Western'];

export const bookGenres = {
    data: [
        {
            genre: "Adventure",
            id: 1
        },
        {
            genre: "Comic",
            id: 2
        },
        {
            genre: "Crime",
            id: 3
        },
        {
            genre: "Docufiction",
            id: 5
        },
        {
            genre: "Epistolary",
            id: 6
        },
        {
            genre: "Literary & Memoire",
            id: 7
        },
        {
            genre: "Fiction",
            id: 8
        },
        {
            genre: "Fantasy",
            id: 9
        },
        {
            genre: "Historical",
            id: 10
        },
        {
            genre: "Horror",
            id: 11
        },
        {
            genre: "Magic",
            id: 12
        },
        {
            genre: "Mystery",
            id: 13
        },
        {
            genre: "Paranoid",
            id: 14
        },
        {
            genre: "Philosophical",
            id: 15
        },
        {
            genre: "Political",
            id: 16
        },
        {
            genre: "Romance",
            id: 17
        },
        {
            genre: "Saga",
            id: 18
        },
        {
            genre: "Satire",
            id: 19
        },
        {
            genre: "Science",
            id: 20
        },
        {
            genre: "Speculative",
            id: 21
        },
        {
            genre: "Superhero",
            id: 22
        },
        {
            genre: "Thriller",
            id: 23
        },
        {
            genre: "Urban",
            id: 24
        },
        {
            genre: "Women's Fiction",
            id: 25
        }
    ],
}
export const months = [
    "January", 
    "February", 
    "March", 
    "April", 
    "May", 
    "June",
    "July", 
    "August", 
    "September", 
    "October", 
    "November", 
    "December"
];

export const groupCategoryID = {
    groupCategoryID: [
        {
            groupCategory: "Free",
            id: "1"
        },
        {
            groupCategory: "Public",
            id: "2"
        },
        {
            groupCategory: "Invite",
            id: "3"
        }

    ]
}

export const groupTypeID = {
    groupTypeID: [
        {
            group_type: "Writing Piece",
            group_type_id: 1
        },
        {
            group_type: "Message",
            group_type_id: 2
        }
    ]
}


export const assignmentTypeID = {
    assignmentTypeID: [
        {
            assignmentType: "Matches",
            id: 1
        },
        {
            assignmentType: "Free Choice",
            id: 2
        },
        {
            assignmentType: "none",
            id: 3
        }
    ]
}

export const subscriptionPackages = {
    subscriptionPackages: [
        {
            subscription: "Free",
            id: "1"
        },
        {
            subscription: "Pro",
            id: "2"
        },
        {
            subscription: "Premium",
            id: "3"
        }
    ]
}

export const groupAccessPeriod = {
    groupAccessPeriod: [
        {
            period: "2 months",
            id: "1"
        },
        {
            period: "6 months",
            id: "2"
        },
        {
            period: "12 months",
            id: "3"
        },
        {
            period: "Forever",
            id: "4"
        }
    ]
}

export const courseType = {
    courseType: [
        {
            type: "Free",
            id: "1"
        },
        {
            type: "Paid",
            id: "2"
        }
    ]
}

export const coursePeriod = {
    coursePeriod: [
        {
            period: "Monthly",
            id: "1"
        },
        {
            period: "Yearly",
            id: "2"
        }
    ]
}

export const packageDetails = {
    packageDetails: [
        {
            text: "Free",
            price: "$0"
        },
        {
            text: "Basic",
            price: "$50"
        },
        {
            text: "Premium",
            price: "$75"
        }
    ]
};

export const pricing = {
    proBasic: [
        {
            text: "Share unlimited writing pieces up to 3,500 words long in the community",
            ticked: true
        },
        {
            text:"Get the feedback you need to make your writing better",
            ticked: true
        },
        {
            text: "Publish your writing on Short Fiction Break literary magazine",
            ticked: true
        },
        {
            text: "Connect with a community of writers",
            ticked: true
        },
        {
            text: "$135 in Bonuses (including Book Plan Scrivener Templates)",
            ticked: true
        },
        {
            text: "2 months free if you pay yearly",
            ticked: true
        }
    ],
    proPremium: [
        {
            text: "Share unlimited writing pieces up to 5,500 words long in the community",
            ticked: true
        },
        {
            text:"Get the feedback you need to make your writing better",
            ticked: true
        },
        {
            text: "Publish your writing on Short Fiction Break literary magazine",
            ticked: true
        },
        {
            text: "Connect with a community of writers",
            ticked: true
        },
        {
            text: "$135 in Bonuses (includingBook Plan Scrivener Templates)",
            ticked: true
        },
        {
            text: "2 months free if you pay yearly",
            ticked: true
        },
        
    ],
    hundredDay: [
        {
            text: "100 daily writing lessons (10+ hours of writing training)",
            ticked: true
        },
        {
            text:"$100 Incentive",
            ticked: true
        },
        {
            text: "Personal Book Mentor",
            ticked: true
        },
        {
            text: "Peer Feedback on Your Finished Book after the 100 Days (First Draft Track)",
            ticked: true
        },
        {
            text: "Weekly Peer Feedback on your writing (Editing Track)",
            ticked: true
        },
        {
            text: "A year of workshopping your writing in The Write Practice Pro",
            ticked: true
        },
        {
            text: "$200+ in Bonuses",
            ticked: true
        }
    ],
    free: [
        {
            text: "100 daily writing lessons (7+ hours of writing training)",
            ticked: true
        },
        {
            text:"1 year’s access to the Becoming Writer workshopping community",
            ticked: true
        },
        {
            text: "$100 Incentive",
            ticked: false
        },
        {
            text: "Weekly Accountability Check-ins",
            ticked: false
        },
        {
            text: "Community Feedback",
            ticked: true
        },
        {
            text: "Entry to our Summer Book Contest",
            ticked: false
        },
        {
            text: "Story Cartel Publishing Course",
            ticked: false
        },
        {
            text: "$200+ in Bonuses",
            ticked: false
        }
    ],
}


export const notificationSettings = {
    notSettings: [
        {
            id: "1",
            Name: "New Critique",
            Description: "Occurs when somebody critiques a writing piece you wrote"
        },
        {
            id: "2",
            Name: "Critique Reply",
            Description: "Occurs when somebody replies to your critiques in a writing piece you critiqued"
        },
        {
            id: "2",
            Name: "Status update reply",
            Description: "Occurs when somebody replies to a post message you wrote"
        },
        {
            id: "3",
            Name: "Conversation Activity",
            Description: "Occurs when somebody replies to a conversation you are part of"
        },
        {
            id: "4",
            Name: "Followers Activity",
            Description: "Get notification when a writer you follow adds a new post message or a writing piece"
        },
        {
            id: "5",
            Name: "Assignment Update",
            Description: "Occurs if a change has been made to one of your assignments. This does not happen frequently"
        },
        {
            id: "6",
            Name: "New Assignment Update",
            Description: "Occurs when an assignment is assigned to you"
        },
        {
            id: "7",
            Name: "Assignment Complete",
            Description: "Occurs when you complete all your critiques in an assignment"
        },
        {
            id: "8",
            Name: "Group Change",
            Description: "Occurs when you are added/removed from a group, or when a group is made available for you to follow"
        }
    ]
};
