import React, { useState } from "react";
import { connect } from "react-redux";
import { fetchUserProfile } from "../../actions/user";
import { fetchAllGroups } from "../../actions/groups";
import { fetchAllArticles } from "../../actions/practice";
import { useHistory } from "react-router-dom";
import { notification, Icon } from "antd";

interface Bio {
  badges: any;
  bio: string;
  books_published: any;
  comments_count: number;
  email: string;
  first_name: string;
  genre: any;
  id: number;
  member_since: string;
  last_name: string;
  posts_count: number;
  profile_pic: any;
  subscription_plan: string;
  user_groups: any;
  user_role: string;
  writing_goal: any;
  website: string;
}


interface Props {
  fetchUserProfile: () => void;
  fetchAllGroups: () => void;
  fetchAllArticles: () => void;
  profile: Bio;
  groups: Array<any>;
  notifications: Array<any>;
  practice? : boolean;
  articles: Array<any>;
    // subs: userSubscription;

}

function AppContainer(props: Props) {
  const [once, callOnce] = useState(false);
  const history = useHistory();

  const openNotification = () => {
    notification.open({
      message: "Token Expired",
      description: "Login Again",
      icon: <Icon type="exclamation" style={{ color: "#108ee9" }} />,
    });
  };

  React.useEffect(() => {

    if (!once) {
      if (props.practice === true) {
      } else {
        if (!props.profile) {
          props.fetchUserProfile();
        }
  
        if (props.groups.length < 1) {
          props.fetchAllGroups();
        }

        // if (props.articles.length < 1) {
        //   props.fetchAllArticles();
        // } <----removed April 8th to reduce the load 

        
        //Checks if the token is expired, checks localstorage variable that should be false 
        //because it stores the res.message which is false when the token has expired
        if(localStorage.getItem("twp-token-is-expired") === "false") {
          openNotification()
          window.location.reload();
          localStorage.removeItem("twp-token");
          history.push("/auth/login");
          localStorage.removeItem("twp-token-is-expired");
        }
      }
    }

    return () => {
      callOnce(true);
    };
  }, []);

  return null;
}

const mapStoP = (state: any) => ({
  profile: state.user.profile,
  groups: state.groups.list,
  notifications: state.nots.notifications,
  feed: state.posts.feed,
  articles: state.posts.articles
  // subs: state.subscription.sub
});

const Actions = {
  fetchUserProfile,
  fetchAllGroups,
  fetchAllArticles
};

export default connect(mapStoP, Actions)(AppContainer);
