export function formatTime(current) {
    
    var options = {
        year: "numeric",
        month: "2-digit",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    };
    var currDate = new Date(current.replace(/-/g,'/') + " UTC");
    currDate.toString()
    currDate.toLocaleString('en', options)

    var createdYear = currDate.getFullYear();
    var createdMonth = currDate.getMonth() + 1;
    var createdDate = currDate.getDate();
    var createdHours = currDate.getHours();
    var createdMinutes = currDate.getMinutes();
    var createdSeconds = currDate.getSeconds();
    var createdAtDate = new Date(createdYear, createdMonth, createdDate, createdHours, createdMinutes, createdSeconds);

    var nowYear = new Date().getFullYear();
    var nowMonth = new Date().getMonth() + 1;
    var nowDate = new Date().getDate();
    var nowHours = new Date().getHours();
    var nowMinutes = new Date().getMinutes();
    var nowSeconds = new Date().getSeconds();

    var nowDay = new Date(nowYear, nowMonth, nowDate, nowHours, nowMinutes, nowSeconds);
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
    var elapsed = nowDay.valueOf() - createdAtDate.valueOf();

    if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + ' seconds ago';
    } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + ' minutes ago';
    } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + ' hours ago';
    } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + ' days ago';
    } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + ' months ago';
    } else {
        return Math.round(elapsed / msPerYear) + ' years ago';
    }
}
