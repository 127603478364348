export default class AlgoliaService {

    /**
     * @param {ApiService} apiService
     */
    constructor(apiService) {
        this.api = apiService;
    }
    getFeedPosts(){
        return this.api.get(`/user-feed`);
    }
}