import React, { useState } from "react";
import { connect } from "react-redux";
import { Menu, Input, Dropdown, Icon, Button, Avatar } from "antd";
import {
  BrowserRouter as Router,
  useParams,
  useRouteMatch,
  Link,
} from "react-router-dom";

import { fetchThinkificToken } from "../../../actions/user";

const options = [
  {
    label: "Groups",
    options: ["L1", "L2"],
  },
];

interface GroupsContainerProps {
  groups: any;
  fetchThinkificToken: () => any;
  responsive?: any;
}

function GroupDropdownContainer(props: GroupsContainerProps) {
  let post: any;
  const { id } = useParams();
  const isCrt = useRouteMatch("/create-post");
  const isGrp = useRouteMatch("/group/:id");

  const [state, setState] = useState("");
  const [label, setLabel] = useState("Feed");
  const [search, setSearch] = useState([
    {
      group_name: "",
      icon: "",
      id: "",
    },
  ]);
  const [status, setStatus] = useState(0);

  const filter = (e) => {
    setState(e.target.value);

    // filter post list by title using onKeyUp function
    post = props.groups.filter(function (item) {
      return item.group_name.toLowerCase().search(state.toLowerCase()) !== -1;
    });
    setSearch(post);

    if (state.length === 1) {
      setStatus(0);
    } else {
      setStatus(1);
    }
  };

  React.useEffect(() => {
    if (isCrt) setLabel("Practice");

    if (isGrp && id) setLabel("Group");
  }, []);

  //my courses onClick method
  const routeFromMyCourse = () => {
    const subs = props.fetchThinkificToken().then((res) => {
      const thinificURL = `https://thewritepractice.thinkific.com/api/sso/v2/sso/jwt?jwt=${res.thinkific_token}`
      var win = window.open(thinificURL, '_self');

    })
  }

  return (
    <Dropdown
      className="group-dropdown"
      overlay={
        <Menu className="workshop-menu" onClick={(e) => setLabel(e.key)}>
          <div className="search">
            <Input.Search
              placeholder="Filter groups"
              onChange={filter}
              value={state}
            />
          </div>
          <Menu.Item key="Feed">
          <Link to="/">
            <Icon type="layout" /> Feed
            </Link>
          </Menu.Item>
          <Menu.Item key="Workshop">
          <Link to="/create-post">
            <Icon type="tool" /> Workshop</Link>
          </Menu.Item>
          <Menu.Item key="Courses">
            <span onClick={routeFromMyCourse}>
              <Icon type="book" />Courses
            </span >
          </Menu.Item>
          <Menu.Item  key="Practice">
            <Link to="/articles">
              <Icon type="read" /> Practice
            </Link>
          </Menu.Item>
          {status === 0 ? (
            <Menu.ItemGroup key="g1" title="Groups">
              {/* {props.groups != undefined ? ( */}
              {props.groups.map((group, index) => (
                <Menu.Item key={group.group_name} title={group.group_name}>
                  <Link to={`/group/${group.id}`}>
                    <Avatar size={25} src={group.icon} />
                    {group.group_name}
                  </Link>
                </Menu.Item>
              ))}
            </Menu.ItemGroup>
          ) : (
            <Menu.ItemGroup key="g1" title="Groups">
              {search.map((group, index) => (
                <Menu.Item key={group.group_name} title={group.group_name}>
                  <Link to={`/group/${group.id}`}>
                    <Avatar size={25} src={group.icon} />
                    {group.group_name}
                  </Link>
                </Menu.Item>
              ))}
            </Menu.ItemGroup>
          )}
        </Menu>
      }
      trigger={["click"]}
    >
      {props.responsive === true ? (
        <Button>
          {label.substring(0, 5)} <Icon type="caret-down" />
        </Button>
      ) : (
        <Button>
          {label} <Icon type="caret-down" />
        </Button>
      )}

    </Dropdown>
  );
}

const Actions = {
  fetchThinkificToken

};

const mapStateToProps = (state: any) => ({
  groups: state.groups.list,
});

export default connect(mapStateToProps, Actions)(GroupDropdownContainer);
