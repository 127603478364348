import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Modal,
  Form,
  Select,
  Icon,
  Button,
  Table,
  Card,
  message,
} from "antd";

import SettingsBody from "../../../shared/SettingsBody";
import {
  fetchAdminUserRoles,
  updateUserRole,
  fetchUserRoles,
} from "../../../../actions/user";

import TeamElasticSearch from "../../../shared/TeamElasticSearch"

const { Option } = Select;
const { confirm } = Modal;

interface TeamProps {
  form: any;
  fetchAdminUserRoles: () => any;
  fetchUserRoles: () => any;
  updateUserRole: (responseBody: any) => any;
}

function TeamContainer(props: TeamProps) {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [adminRoles, setAdminRoles] = useState<Array<any>>([]);
  const [userRoles, setUserRoles] = useState<Array<any>>([]);

  //Admin Role API Integration
  const getAdminRoles = async () => {
    const subs = await props.fetchAdminUserRoles();
    setAdminRoles(subs);
    return subs;
  };

  //User Role API Integration
  const getUserRoles = async () => {
    const subs = await props.fetchUserRoles();
    setUserRoles(subs);
    return subs;
  };

  React.useEffect(() => {
    getAdminRoles();
    getUserRoles();
  }, []);

  //Opens Modal
  const showModal = () => {
    setVisible(true);
  };

  //Closes modal
  const handleCancel = () => {
    setVisible(false);
  };

  //Table headers
  const columns = [
    {
      title: "Name",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "User Role",
      dataIndex: "user_role",
      key: "user_role",
      render: (text, record) => (
        <Select
          value={record.user_role}
          placeholder="Role"
          onChange={(value) => {
            confirm({
              title: `Are you sure you want to change ${record.user}'s permissions?`,
              icon: <Icon type="exclamation-circle" />,
              onOk() {
                const params = {
                  user_id: record.useR_id,
                  user_role_id: value,
                };

                props.updateUserRole(params).then((r) => {
                  message.success("Removed Admin");
                  setTimeout(function () {
                    window.location.reload();
                  }, 1000);
                });
              },
              onCancel() {
              },
            });
          }}
        >
          {userRoles.map((s) => (
            <Option key={record.useR_id + "user_role"} value={s.id}>
              {s.user_role}
            </Option>
          ))}
        </Select>
      ),
    },
  ];

  //Updates state with Searched value
  const onSearch = (value: string) => {
    setSearch(value);
  };

  const { getFieldDecorator } = props.form;
  const { form } = props;

  return (
    <SettingsBody active="team">
      <div className="twp-wrapper inner-l">
        <Row>
          <Card>
            <Row>
              <Col span={12}>
                <h3>Team Members</h3>
              </Col>

              <Col style={{ paddingBottom: 10 }} span={12}>
                <div style={{ textAlign: "end" }}>
                  <Button type="primary" onClick={showModal}>
                    Add Admin
                  </Button>
                </div>
                <Modal
                  title=" Add an Admin User"
                  visible={visible}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <Form>
                    {/* <Hide in="sm"> */}
                    <Col style={{ paddingBottom: 30, paddingLeft: 80 }}>
                      <TeamElasticSearch />
                    </Col>
                    {/* </Hide> */}
                  </Form>
                </Modal>
              </Col>
            </Row>

            <Row>
              <Table
                pagination={false}
                columns={columns}
                dataSource={adminRoles}
              />
            </Row>
          </Card>
        </Row>
      </div>
    </SettingsBody>
  );
}
const Actions = {
  fetchAdminUserRoles,
  updateUserRole,
  fetchUserRoles,
};

const mapStateToProps = (state: any) => ({
  groups: state.groups.list,
});

//Connect to redux store
const _Form = connect(mapStateToProps, Actions)(TeamContainer);

//Connect to Form and export
export default Form.create({ name: "teams" })(_Form);
