import React from 'react'
import Body from '../components/shared/Body';
import ListingPostContainer from '../components/containers/Posts/ListingPostContainer'
import ErrorBoundary from '../components/shared/ErrorBoundary'

const Post = () => {
    return (
        <div>
            <ListingPostContainer />
        </div>
    )
};

export default Post;