import {
    FETCH_POST_INITIATE,
    FETCH_POST_INITIATE_FAILED,
    FETCH_POST_INITIATE_SUCCESS,
    FETCH_PRACTICE_POST_SUCCESS,
    FETCH_PRACTICE_CRITIQUES,
    ADD_PRACTICE_CRITIQUE,
    UPDATE_PRACTICE_CRITIQUE,
    DELETE_PRACTICE_CRITIQUE
} from "../actions/_types";

import { unionWith, reject, unionBy } from 'lodash'

const initial_state = {
    loading: false,
    total: [],
    list: [],
    practices: [],
    practiceComments: []
}

const practiceReducer = (state = initial_state, action) => {
    switch(action.type) {
        case FETCH_POST_INITIATE:
            return onFetch(state);
        case FETCH_POST_INITIATE_SUCCESS:
            return onFetchFinish(state);
        case FETCH_POST_INITIATE_FAILED:
            return onFetchFinish(state)
        case FETCH_PRACTICE_POST_SUCCESS:
            return setPracticeFeed(state, action.payload)
        case FETCH_PRACTICE_CRITIQUES:
                return setCritiquesFromPractice(state, action.payload);
        case ADD_PRACTICE_CRITIQUE:
                return addToCritiques(state, action.payload)
        case DELETE_PRACTICE_CRITIQUE:
                return removeFromCritiques(state, action.payload);
        case UPDATE_PRACTICE_CRITIQUE:
                return updateCritique(state, action.payload);
        default:
            return state;
    }
};

const onFetch = (state) => ({
    ...state,
    loading: true
});

const onFetchFinish = (state) => ({
    ...state,
    loading: false
});

const setPracticeFeed = (state, posts) => {
    let list = unionBy(state.practices, posts, 'post_id')
    return {
        ...state,
        loading: false,
        practices: list
    }
}

const setCritiquesFromPractice = (state, critiques) => {
    const total = unionWith(critiques, state.list);
    return {
        ...state,
        practiceComments: critiques,
        total
    }
};

const addToCritiques = (state, critique) => {
    let list = unionWith([critique], state.list);
    return {
        ...state,
        list
    }
};

const removeFromCritiques = (state, id) => {
    const list = reject(state.list, {'id' :id });
    return {
        ...state,
        list
    }
};

const updateCritique = (state, obj) => {
    let list = state.list.map((c) => {
        return c.id === obj.comment_id ? {
            ...c,
            comment: obj.comment
        } : c;
    });
    return {
        ...state,
        list
    }
};

export default practiceReducer;