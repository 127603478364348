import React, { useState } from "react";
import {
  Row,
  Button,
  Input,
  Col,
  List,
  Icon,
  Modal,
  message,
  Form,
  Select
} from "antd";
import { nth } from "lodash";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import GroupBlock from "../../../snippets/listing/GroupBlock";
import SettingsBody from "../../../shared/SettingsBody";
import { fetchAllGroups } from "../../../../actions/groups";
import ImageUpload from "../../../snippets/ImageUpload/ImageUpload";
import { invokeCreateGroup, fetchAdminGroups } from "../../../../actions/groups";
import {
  fetchSubscriptions,
  fetchUserSubscription,
} from "../../../../actions/user";

import { assignmentTypeID } from "../../../../utils/statics";
import { groupCategoryID } from "../../../../utils/statics";
import { groupTypeID } from "../../../../utils/statics";
import { subscriptionPackages } from "../../../../utils/statics";

const { TextArea } = Input;
const { Option } = Select;

interface ListingProps {
  groups: Array<any>;
  fetchAllGroups: any;
  form: any;
  invokeCreateGroup: (responseBody: any) => any;
  fetchUserSubscription: () => void;
  fetchSubscriptions: () => any;
  usersubscription: any;
  fetchAdminGroups: () => any
}

function GroupListingContainer(props: ListingProps) {
  const history = useHistory();
  const [imgurl, setImgurl] = useState<any>();
  const [groupCategory, setGroupCategory] = useState<any>();
  const [assignmentType, setAssignmentType] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [subscriptions, setSubscriptions] = useState<Array<any>>([]);
  const [groupType, setGroupType] = useState<Array<any>>([]);
  const [updateSubscription, setUpdateSubscription] = useState<Array<any>>([]);
  const [errors, setErrors] = useState<Array<string>>([]);
  const [iconUploaded, setIconUploaded] = useState(false);
  const [adminGroups, setAdminGroups] = useState<Array<any>>([]);

  React.useEffect(() => {
    if (props.groups.length < 1) {
      props.fetchAllGroups();
    }

  }, []);

  //Subscription plan API fetch
  const getSubscriptions = async () => {
    const subs = await props.fetchSubscriptions();
    setSubscriptions(subs);
    return subs;
  };

  const getAllAdminGroups = async () => {
    const adGrps = await props.fetchAdminGroups();
    setAdminGroups(adGrps);
    return adGrps;
  }

  React.useEffect(() => {
    getSubscriptions();
    getAllAdminGroups();
  }, []);

  //Opens Create Group Modal
  const showModal = () => {
    setVisible(true);
  };

  //Closes Group Modal
  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    props.form.validateFields((err: any, vals: any) => {
      if (!err) {
        if (vals.group_category_id === "Free") {
          vals.group_category_id = 1;
        } else if (vals.group_category_id === "Public") {
          vals.group_category_id = 2;
        } else if (vals.group_category_id === "Invite") {
          vals.group_category_id = 3;
        }

        if (vals.assignment_type_id === "Matches") {
          vals.assignment_type_id = 1;
        } else if (vals.assignment_type_id === "Free Choice") {
          vals.assignment_type_id = 2;
        } else if (vals.assignment_type_id === "None") {
          vals.assignment_type_id = 3;
        }

        if (vals.group_types[0] == "2") {
          vals.assignment_type_id = 3;
        }

        const {
          group_name,
          icon,
          description,
          group_category_id,
          assignment_type_id,
          subscription_ids,
          group_types,
        } = vals;

        const params = {
          ...vals,
          icon: imgurl,
          subscription_ids: updateSubscription[0],
        };

        const isvalid = validate();
        const submit = props.invokeCreateGroup(params);
        submit.then((group_id: any) => {
          if (group_id) {
            setTimeout(() => history.push(`/group/${group_id}`), 500);
            setTimeout(function () {
              window.location.reload();
            }, 1500);
          } else message.info(`Couldn't save group`);
        });
      }
    });
  };

  const validate = () => {
    setErrors([]); //Reset validation to validate again.

    let errs: Array<string> = [];

    if (!imgurl)
      errs.push("Please upload a Group Icon");

    // Set errors
    setErrors(errs);

    // Return validation
    if (errs.length < 1) return true;

    if (iconUploaded === true) {
      setErrors([]);
    }


    return false;
  };

  const { getFieldDecorator } = props.form;
  const { form } = props;

  return (
    <SettingsBody active="groups">
      <div className="twp-wrapper inner-l">
        <Row className="reader">
          <Row>
            <Col span={16}>
              <h1>Groups</h1>
            </Col>
            <Col span={8}>
              <div style={{ textAlign: "end" }}>
                <Button type="primary" onClick={showModal}>
                  Create Groups
                </Button>
              </div>
            </Col>
          </Row>
          <Modal
            title="Create a Group"
            visible={visible}
            onOk={handleOk}
            okText="Submit"
            onCancel={handleCancel}
          >
            <Form>
              <Row className="inner">
                <Form.Item id="group_name">
                  {getFieldDecorator("group_name", {
                    rules: [
                      {
                        required: true,
                        message: "Can't leave this empty",
                      },
                    ],
                  })(<Input placeholder="Group Name" />)}
                </Form.Item>
                <div style={{ paddingBottom: 10 }}>
                  <Row>
                    <Col span={8}><span style={{ paddingRight: 10 }}>Group Icon</span></Col>
                    <Col span={12}>
                      <ImageUpload
                        id="icon"
                        folder="images"
                        height={800}
                        width={800}
                        onFileUpload={(imageUrl) => {
                          setImgurl(imageUrl);
                          setIconUploaded(true)
                        }}
                      /></Col>
                    <Col span={4}>
                      {iconUploaded === true ? (
                        <div className="icon-upload-check">
                          <Icon type="check-circle" />
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </div>
                <Form.Item id="description">
                  {getFieldDecorator("description", {
                    rules: [
                      {
                        required: true,
                        message: "Can't leave this empty",
                      },
                    ],
                  })(
                    <TextArea
                      placeholder="Group Description"
                      rows={5}
                    ></TextArea>
                  )}
                </Form.Item>
                <Form.Item id="group_category_id">
                  {getFieldDecorator("group_category_id", {
                    rules: [
                      {
                        required: true,
                        message: "Please select a value",
                      },
                    ],
                  })(
                    <Select
                      placeholder="Group Category"
                      onChange={(v) => setGroupCategory(v)}
                    >
                      {groupCategoryID.groupCategoryID.map((s) => (
                        <Option
                          key={s.id + "group_category_id"}
                          value={s.groupCategory}
                        >
                          {s.groupCategory}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item id="group_types">
                  {getFieldDecorator("group_types", {
                    rules: [
                      {
                        required: true,
                        message: "Please select a value",
                      },
                    ],
                  })(
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Group Type"
                      onChange={(v) => setGroupType([v])}
                    >
                      {groupTypeID.groupTypeID.map((s) => (
                        <Option key={s.group_type_id + "_group_types"} value={s.group_type_id}>
                          {s.group_type}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
                {(nth(groupType[0], 0) === 1) || (nth(groupType[0], 1) === 2) || (nth(groupType[0], 0) === 2) && (nth(groupType[0], 1) === 1)  || (nth(groupType[0], 1) === 1) ? (
                  <Form.Item id="assignment_type_id">
                    {getFieldDecorator("assignment_type_id", {
                      rules: [
                        {
                          required: true,
                          message: "Please select a value",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Assignment Type"
                        onChange={(v) => setAssignmentType(v)}
                      >
                        {assignmentTypeID.assignmentTypeID.map((s) => (
                          <Option
                            key={s.id + "_grouptype"}
                            value={s.assignmentType}
                          >
                            {s.assignmentType}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                ) : null}

                {groupType[0] === "1" ? (
                  <Form.Item id="assignment_type_id">
                    {getFieldDecorator("assignment_type_id", {
                      rules: [
                        {
                          required: true,
                          message: "Please select a value",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Assignment Type"
                        onChange={(v) => setAssignmentType(v)}
                      >
                        {assignmentTypeID.assignmentTypeID.map((s) => (
                          <Option
                            key={s.id + "_grouptype"}
                            value={s.assignmentType}
                          >
                            {s.assignmentType}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                ) : null}

                {groupType[0] === "2" ? (
                  <Form.Item id="assignment_type_id">
                    {getFieldDecorator("assignment_type_id", {
                      rules: [
                        {
                          // required: true, //commented because it throws errors since no value is selected from dropdown since messages have no assigment types
                          message: "Please select a value",
                        },
                      ],
                    })(
                      <Select
                        placeholder="Assignment Type"
                        onChange={(v) => setAssignmentType(v)}
                      >
                        <Option value="disabled" disabled>
                          No Assignments for Message Groups
                      </Option>
                      </Select>
                    )}
                  </Form.Item>
                ) : null}
                <Form.Item id="subscription_ids">
                  {getFieldDecorator("subscription_ids", {
                    rules: [
                      {
                        required: true,
                        message: "Please select a value",
                      },
                    ],
                  })(
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: "100%" }}
                      placeholder="Subscription Plan"
                      onChange={(v) => setUpdateSubscription([v])}
                    >
                      {subscriptionPackages.subscriptionPackages.map((s) => (
                        <Option key={s.id + "_package"} value={s.id}>
                          {s.subscription}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Row>
              {errors.length > 0 ? (
                <Row className="inner">
                  {errors.map((err, i) => (
                    <div key={`err_${i}`} style={{ color: "red" }}>
                      <b>* {err}</b>
                    </div>
                  ))}
                </Row>
              ) : null}
            </Form>
          </Modal>
          <List dataSource={adminGroups} renderItem={GroupBlock} />
        </Row>
      </div>
    </SettingsBody>
  );
}

const Actions = {
  fetchAllGroups,
  invokeCreateGroup,
  fetchSubscriptions,
  fetchUserSubscription,
  fetchAdminGroups
};

const mapStateToProps = (state: any) => ({
  groups: state.groups.list,
});

//Connect to redux store
const _Form = connect(mapStateToProps, Actions)(GroupListingContainer);

//Connect to Form and export
export default Form.create({ name: "create_groups" })(_Form);
