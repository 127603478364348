import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { useLocation } from "react-router-dom";
import { unionBy, find, filter } from "lodash";
import {
  EditorState,
  ContentState,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Input, Form, Select, Typography } from "antd";
import { bookGenres } from "../../../utils/statics";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

interface Post {
  id?: string;
  title: string;
  post: string;
  genre_id: number;
  summary: string;
  feedback: string;
  word_count: string;
}

interface Props {
  onChange: ({ }: Post) => void;
  post?: Post;
  mini?: Boolean;
  word_count?: any;
  draftPost?: Boolean;
  draftId?: any;
  postUserId?: string
  editGrpWordCount?: any
}

const initPost = {
  title: "",
  post: "",
  summary: "",
  genre_id: 1,
  feedback: "",
  word_count: ""
};

const save_interval = parseInt(
  process.env.REACT_APP_DRAFT_TIMESTAMP
    ? process.env.REACT_APP_DRAFT_TIMESTAMP
    : "10000"
);

// A custom hook that builds on useLocation to parse the query string for you.
function useQuery(key) {
  return new URLSearchParams(useLocation().search).get(key);
}

function MessageEditor(props: Props) {
  const query = useQuery("drafts") || "";
  const [editor, setEditor] = React.useState(EditorState.createEmpty());
  const { post, mini } = props;
  const maxWords = props.word_count;
  const [obj, setObj] = React.useState<Post>(post || initPost);
  const [words, setWords] = React.useState(0);
  const [wordLimit, setWordLimit] = React.useState(props.word_count);
  const [draftMessage, setDraftMessage] = React.useState(false);

  //draft id --- see if you already have it in the query
  const [draftId, setDraftId] = React.useState<any>(
    query
      ? query
      : Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 5)
  );

  const userId = localStorage.getItem("user-id")

  const doDraftSave = () => {

    const id = query ? query : draftId;
    if (props.draftId) {

      if (obj.title.length > 0 || obj.post.length > 0) {
        const p: any = [
          {
            user_id: userId,
            id: props.draftId,
            ...obj,
          },
        ];

        const drfts = localStorage.getItem("twp-drafts") || "";

        const parsed = drfts ? JSON.parse(drfts) : [];

        //Added to remove the posted draft from localstorage
        const com = filter(parsed, (a) => a.id != localStorage.getItem("twp-draft-posted"));

        let d = unionBy(p, com, "id");

        const drft = localStorage.setItem("twp-drafts", JSON.stringify(d));
      }
    } else {

      if (obj.title.length > 0 || obj.post.length > 8) {
        const p: any = [
          {
            user_id: userId,
            id: id,
            ...obj,
          },
        ];

        const drfts = localStorage.getItem("twp-drafts") || "";
        const parsed = drfts ? JSON.parse(drfts) : [];

        //Added to remove the posted draft from localstorage
        const com = filter(parsed, (a) => a.id != localStorage.getItem("twp-draft-posted"));

        let d = unionBy(p, com, "id");

        const drft = localStorage.setItem("twp-drafts", JSON.stringify(d));
      }
    }

  };

  const initDraft = () => {
    const drft = localStorage.getItem("twp-drafts") || "";
    const parsed = drft ? JSON.parse(drft) : [];

    if (parsed && parsed.length > 0 && query) {
      const d = find(parsed, { id: query });
      if (d) {
        const params = {
          ...obj,
          id: draftId,
          post: d.post,
          title: d.title,
          feedback: d.feedback,
          genre_id: d.genre_id,
          user_id: userId
        };
        setObj(params);
      }
    }
  };

  React.useEffect(() => {
    if (props.post && props.post.post) {
      const parsed = htmlToDraft(props.post.post.toString());
      const conversion = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          parsed.contentBlocks,
          parsed.entityMap
        )
      );
      setEditor(conversion);
    }
    initDraft();
    setWordLimit(props.word_count);
  }, []);


  // temp - remove if not working
  React.useEffect(() => {

    //if the post is not a draft
    if (props.draftPost === false) {
      if (props.post && props.post.post) {
        const parsedPost = htmlToDraft(props.post.post.toString());

        const conversion = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            parsedPost.contentBlocks,
            parsedPost.entityMap
          )
        );
        setObj({
          ...obj,
          title: props.post.title,
        });
        setEditor(conversion);
      }
    }

    //if the post is a draft
    if (props.draftPost === true) {
      if (props.post && props.post.post) {
        const parsedTitle = htmlToDraft(props.post.post.toString());

        const conversion = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            parsedTitle.contentBlocks,
            parsedTitle.entityMap
          )
        );
        setEditor(conversion);
        setObj({
          ...obj,
          title: props.post.title,
          post: props.post.post,
        });
      }

    }
    initDraft();
  }, [props.post]); //inserted props.post to test


  React.useEffect(() => {
    const text = convertToRaw(editor.getCurrentContent());
    const html = draftToHtml(text);

    //checks length
    const s = text.blocks[0]
      ? text.blocks[0].text.split(" ").splice(0, 40).join(" ").toString()
      : "";
    const t = text.blocks.reduce((acc, r) => `${r.text} ${acc}`, "");
    const f = t.trim().split(/\s+/).length;

    setWords(f < 2 ? 0 : f);

    setObj({
      ...obj,
      summary: s,
      post: html,
      word_count: words.toString()
    });

  }, [editor]);

  
  React.useEffect(() => {
    props.onChange(obj);
    if (!props.postUserId) {
      const timer = setTimeout(() => {
        doDraftSave();
        if (save_interval) {
          setDraftMessage(true);
        }
      }, save_interval);
      return () => clearTimeout(timer);
    }
  }, [obj]);

  return (
    <div className="mini-create-wp">
    <Form name="writing-piece=form">
      <Form.Item>
        <Input
          id="first_name"
          placeholder="Title"
          size="large"
          value={obj.title}
          onChange={(e) => setObj({ ...obj, title: e.target.value })}
        />
      </Form.Item>
      <Editor
        handlePastedText={() => false}
        editorState={editor}
        onEditorStateChange={setEditor}
        toolbar={{
          options: ["inline", "link", "list"],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          list: {
            // className: "post-container",
            options: ["unordered", "ordered"],
          },
        }}
        wrapperClassName="twp-editor-wrapper"
        editorClassName="twp-editor"
      />
      {props.editGrpWordCount ? (
        <div>
          {words <= maxWords ? (
            <span>
              {words} / {maxWords}
            </span>
          ) : (
            <span style={{ color: "red" }}>
              {words} / {props.editGrpWordCount}
            </span>
          )}
        </div>
      ) : (
        <div>
          {words <= maxWords ? (
            <span>
              {words} / {maxWords}
            </span>
          ) : (
            <span style={{ color: "red" }}>
              {words} / {props.word_count}
            </span>
          )}
        </div>
      )}

      {mini && obj.title.length > 0 && obj.post.length > 0 ? (
        <>
          <Form.Item label="Genre">
            <Select
              onSelect={(v: number) => setObj({ ...obj, genre_id: v })}
              placeholder="Select a genre"
              value={obj.genre_id}
              allowClear
            >
              {bookGenres.data.map((v) => (
                <Option key={v.id} value={v.id}>
                  {v.genre}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="What would you like writers to give you feedback on">
            <TextArea
              rows={2}
              value={obj.feedback}
              onChange={(e) => setObj({ ...obj, feedback: e.target.value })}
            ></TextArea>
            {draftMessage == true ? (
              <div style={{ alignContent: "right" }}>
                <Text type="secondary">Saved to drafts</Text>
              </div>
            ) : null}
          </Form.Item>
          {/* <Form.Item label="Writing Piece word count">
            <Input
              id="word_count"
              // placeholder="Title"
              size="large"
              value={words}
              onChange={(e) => setObj({ ...obj, word_count: words.toString() })}
            />
          </Form.Item> */}
        </>
      ) : null}
    </Form>
    </div>
  );
}

export default MessageEditor;
