import React from "react";
import { connect } from "react-redux";

import { fetchPostsByGroupId } from "../../../../actions/posts";
import { fetchAllUserProfile } from "../../../../actions/app";

import { useParams } from "react-router-dom";

interface Props {
  fetchPostsByGroupId: (id: number) => void;
  posts: any;
  loading: boolean;
  bio: any;
  userId?: any;
  fetchAllUserProfile: (id: number) => void;
}

function UserProfileListingContainer(props: Props) {

  const { id } = useParams();
  const parsedId = parseInt(id ? id : "");

  React.useEffect(() => {
    props.fetchAllUserProfile(parseInt(id ? id : ""));
  }, []);

  // const { id } = useParams();
  // const [post, setPost] = useState({
  //     title: "",
  //     post: "",
  //     name: "",
  //     created_at: ""
  // });

  // React.useEffect(() => {
  //     props.fetchPostsByGroupId(parseInt(id ? id : ""));
  // }, []);

  // React.useEffect(() => {
  //     if (props.posts.length > 0) {
  //         let p = find(props.posts);
  //         setPost(p);
  //     }
  // });

  return (
    <div className="twp-wrapper inner-l">
      <h2>Search results for: </h2>
      <div className="reader">
        <div className="area">
          {/* <List
                dataSource={props}
                renderItem={UserProfileBlock}
            /> */}
          {/* <UserProfileBlock /> */}
        </div>
      </div>
    </div>
  );
}

const actions = {
  fetchPostsByGroupId,
  fetchAllUserProfile
};

const mapStateToProps = (state: any) => ({
  posts: state.posts.list,
  loading: state.posts.loading,
  bio: state.user.bio
});
export default connect(mapStateToProps, actions)(UserProfileListingContainer);
