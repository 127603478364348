import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Icon,
  Descriptions,
  Button,
} from "antd";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import SettingsBody from "../../../shared/SettingsBody";
import EditCourseContainer from "./EditCourseContainer";
import { getSingleCourse, getAllCourses } from "../../../../actions/courses";


interface Props {
  courses: Array<any>;
  getSingleCourse: (id: number) => any;
  getAllCourses: () => any;
}

const initCourse = {
  access_period: "",
  access_period_id: "",
  course_code: "",
  course_id: "",
  description: "",
  group_id: "",
  group_name: "",
  period: "",
  period_id: "",
  price: "",
  title: "",
  url: "",
  word_count: "",
};

function CourseContainer(props: Props) {
  const [editor, toggleEditor] = React.useState(false);
  const { id } = useParams();
  const history = useHistory();
  const parsedId = parseInt(id ? id : "");
  const [singleCourse, setinitCourse] = useState({
    access_period: "",
    access_period_id: "",
    course_code: "",
    course_id: "",
    description: "",
    group_id: "",
    group_name: "",
    period: "",
    period_id: "",
    price: "",
    title: "",
    url: "",
    word_count: "",
  });

  // React.useEffect(() => {
  //   props.getSingleCourse(parsedId);
  // }, [parsedId]);

  React.useEffect(() => {
    props.getSingleCourse(parsedId).then((res) => {
      setinitCourse(res);
    });
  }, []);

  //Back button on Courses
  const backCourses = () => {
    setTimeout(() => history.push("/courses"), 500);
    setTimeout(function () {
      window.location.reload();
    }, 1000);
  };

  React.useEffect(() => {
    toggleEditor(false);
  }, [singleCourse]);

  return (
    <SettingsBody active="profile">
      {editor ? (
        <div>
          <Row type="flex" align="middle" gutter={16} className="inner">
            <Col>
              <Button onClick={() => toggleEditor(false)}>
                <Icon type="arrow-left" /> Back
              </Button>
            </Col>
            <Col>
              <h2 className="imp">Edit Course</h2>
            </Col>
          </Row>
          <EditCourseContainer />
        </div>
      ) : (
          <div className="inner">
            <Row type="flex" justify="space-between">
              <Col>
                <Button onClick={backCourses}>
                  <Icon type="arrow-left" /> Back
              </Button>
              </Col>
              <Col>
                <Button icon="edit" onClick={() => toggleEditor(true)}>
                  Edit
              </Button>
              </Col>
            </Row>
            <br />
            <Row>
              <h2 className="subtitle">{singleCourse.title}</h2>
            </Row>
            <br />
            <br />
            <Descriptions
              layout="vertical"
              column={1}
              className="profile-description"
            >
              {singleCourse.description ? (
                <Descriptions.Item label="Description">
                  {singleCourse.description}
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label="Access Period">
                {singleCourse.access_period}
              </Descriptions.Item>
              {singleCourse.url ? (
                <Descriptions.Item label="URL">
                  {singleCourse.url}
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label="Word Count">
                {singleCourse.word_count}
              </Descriptions.Item>
              <Descriptions.Item label="Course Code">
                {singleCourse.course_code}
              </Descriptions.Item>
              <Descriptions.Item label="Price">
                $ {singleCourse.price}
              </Descriptions.Item>
              <Descriptions.Item label="Period">
                {singleCourse.period}
              </Descriptions.Item>
              <Descriptions.Item label="Group">
                {singleCourse.group_name}
              </Descriptions.Item>
            </Descriptions>
          </div>
        )}
    </SettingsBody>
  );
}

const Actions = {
  getSingleCourse,
  getAllCourses,
};

const mapStoP = (state: any) => ({
  courses: state.courses.list,
});

export default connect(mapStoP, Actions)(CourseContainer);
