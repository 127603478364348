import React from 'react'
import { Tabs, Icon, Row } from 'antd'
import { useHistory } from 'react-router-dom'
import {Show, Hide} from './Responsive'
interface Props {
    children: any,
    active: string
}

const { TabPane } = Tabs;

const links = [
    {
        name: "Profile",
        slug: "profile",
        icon: "user"
    }
    // ,
    // {
    //     name: "Activity Feed",
    //     slug: "general-activity-feed",
    //     icon: 'history'
    // }
]

function GeneralSettingsBody(props: Props) {
    
    const [active, setActive] = React.useState(props.active)
    const history = useHistory();

    function handleChange(key: string) {
        setActive(key)
        //setTimeout(() => history.push('/' + key), 300)
        history.push('/' + key)
    }

    return (
        <>
            <Hide in="xs">
                <div className="twp-wrapper inner">
                    <Row className="bg-primary">
                        <div className="settings-tab-wrapper">
                            <Tabs defaultActiveKey="1" tabPosition="left" onChange={handleChange} activeKey={active} className="config-tab" size="large">
                                {links.map((v, i) => (
                                    <TabPane className="config-tab" tab={
                                        <Row>
                                            <Icon type={v.icon} /> {v.name}
                                        </Row>
                                    } key={`${v.slug}`}>
                                        <div className="settings-tab-body" style={{ marginLeft: 0}}>
                                            {props.children}
                                        </div>
                                    </TabPane>
                                ))}
                            </Tabs>
                        </div>
                    </Row>
                </div>
            </Hide>
            <Show in="xs">
                <div className="bg-primary container">
                    {props.children}
                </div>
            </Show>
        </>
    )
}

export default GeneralSettingsBody;
