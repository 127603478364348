export default class GroupService {

    /**
     * @param {ApiService} apiService
     */
    constructor(apiService) {
        this.api = apiService;
    }

    getGroups(id){
        return this.api.get(`group/${id}`);
    }
    followGroup(group){
        return this.api.post(`group/follow`, group);
    }
    getAllGroups(){
        return this.api.get(`group-by-category`);
    }
    createGroup(groups){
        return this.api.post(`create-group`, groups);
    }
    updateGroup(groups){
        return this.api.put(`update-group`, groups);
    }
    fetchGroupAccessPeriods(){
        return this.api.get(`group-access-periods`);
    }
    fetchGroupFollowers(group_id){
        return this.api.get(`group/followers/${group_id}`);
    }
    searchGroupFollowers(group_id){
        return this.api.post(`user/search-to-add`, group_id);
    }
    addRemoveFollowersToGroups(groups){
        return this.api.post(`user/follow-group`, groups);
    }
    deleteGroup(groups) {
        return this.api.delete(`delete-group`, groups);
    }
    getAdminGroups () {
        return this.api.get(`groups`); 
    }
}