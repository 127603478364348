import {
    ADD_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION,
    DELETE_SUBSCRIPTION,
    FETCH_USER_SUBSCRIPTION
} from "../actions/_types";
import {unionBy} from 'lodash'

const initial_state = {
    loading: false,
    list: [],
    sub: []
}

const subscriptionReducer = (state = initial_state, action) => {
    switch(action.type) {
        // case ADD_SUBSCRIPTION:
        //     return addSubscription(state, action.payload);
        // case UPDATE_SUBSCRIPTION:
        //     return changeSubscription(state, action.payload);
        // case DELETE_SUBSCRIPTION:
        //     return removeSubscription(state, action.payload);
        case FETCH_USER_SUBSCRIPTION:
                return getUserSubscription(state, action.payload);
        default:
            return state;
    }
};

const getUserSubscription = (state, sub) => {
    return {
        ...state,
        sub: sub
    }
};

// const addSubscription = (state, subscription) => {
//     let list = unionBy(state.list, subscription);
//     return {
//         ...state,
//         loading: false,
//         list
//     }
// };

// const changeSubscription = (state, subscription) => {
//     let list = unionBy(state.list, subscription);
//     return {
//         ...state,
//         loading: false,
//         list
//     }
// };

// const removeSubscription = (state, id) => {
//     const list = reject(state.list, {'id' :id });
//     return {
//         ...state,
//         list
//     }
// };

export default subscriptionReducer;