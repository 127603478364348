import React, { useState } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";

import { Input, Button, Form, Result, Icon } from "antd";
import { resetPassword } from "../../../actions/auth";
import AuthLayout from "../../shared/AuthLayout";
import { Link } from "react-router-dom";

const { Password } = Input;
const { Item } = Form;

const _Form = (props: any) => {
  const { token } = useParams();
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  async function submit() {
    const value = props.form.getFieldValue("password");
    if (value && !loading) {
      setLoading(true);
      await props
        .resetPassword({
          verification_token: token,
          password: value,
        })
        .then((res) => {
          setCalled(true);
          if (res.success) {
            setSuccess(true);
          } else {
            setSuccess(false);
          }

          setLoading(false);
        });
    }
  }

  const { getFieldDecorator } = props.form;

  return (
    <AuthLayout>
      {!called ? (
        <Form>
          <div>
            <h1 className="title">Enter your new password</h1>
            <p>
              *Note: Password is not restricted to standards. But it is
              recommended that you apply a strong password
            </p>
          </div>
          <Item>
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "This can't be empty" }],
            })(
              <Password
                prefix={<Icon type="lock" />}
                placeholder="New password"
              />
            )}
          </Item>
          <Item>
            <Button
              size="large"
              className="btn-c1"
              type="primary"
              loading={loading}
              onClick={submit}
            >
              Restore
            </Button>
          </Item>
        </Form>
      ) : (
        <div>
          {success ? (
            <>
            <Result
              status="success"
              title="Success"
              subTitle="Your password has been changed successfully"
              extra={[
                <Link to="/auth/login"> Login</Link>
              ]}
            />
            </>
          ) : (
            <Result
              status="error"
              title="Failed"
              subTitle="Password could not be changed. Either you're in the wrong link or the link to change the password is expired"
            />
          )}
        </div>
      )}
    </AuthLayout>
  );
};

const Actions = {
  resetPassword,
};

//Connect to redux store
const RestorePassword = connect(null, Actions)(_Form);

//Connect to Form and export
export default Form.create({ name: "restore-password" })(RestorePassword);
