import React from 'react';
export const TOUR_STEPS = [
    {
        target: ".feed-wrapper",
        content: <><div className="tour-img"><img  src="/assets/twp-logo.jpeg" width={40} alt="Logo" /></div><br /><h4 className="tour-init">Welcome to TheWritePractice Pro! Let us guide you on how to get started</h4></>,
        disableBeacon: true,
        placement: 'center',
    },
    {
        target: ".feed",
        content: <h5 className="tour-steps">This is the main feed. Posts from all the groups you follow are listed here. Notice there are two types of posts: Messages and Writing Pieces. We’ll talk more about those in a minute</h5>,
       
    },
    {
        target: ".writing-piece-tab",
        content: <h5 className="tour-steps"> This is the editor, where you’ll be able to share messages about your writing with the community and add your writing pieces for critique. Click the tabs to switch from “Messages” to “Writing Pieces.” </h5>
    },
    {
        target: ".writing-piece-tab",
        content: <h5 className="tour-steps">Messages are for things like updates, writing talk, and questions. Not all groups allow Message posts </h5>
    },
    {
        target: ".writing-piece-tab",
        content: <h5 className="tour-steps"> When you post a Writing Piece, you’ll be able to choose the group you wish to post it in, the genre, and give a short summary of what kind of feedback you’re looking for" </h5>
    },
    {
        target: ".mini-create-submit",
        content: <h5 className="tour-steps"> After you submit a writing piece, other members of that group will be able to see your piece and start critiquing it. But before you can view your critiques, you’ll have to critique writing pieces by three other writers. This helps us make sure everyone gets the feedback they need to make their writing better. If you’ve been matched with three other writers, then you’ll get to critique those specific pieces to view critiques on your own writing piece. </h5>
    },
    {
        target: ".body-assignments",
        content: <h5 className="tour-steps">Here you’ll see the pieces you must critique in order to see the feedback on your own piece. We have two different kinds of assignments: matched and free. Matches are specific pieces you must critique. Free assignments mean you get to choose which three pieces you want to critique. Remember, they MUST be in the same group you posted your piece in in order to count as completing a critique. For example, if you posted in 100 Day Book, all three pieces you critique must come from the 100 Day Book group. </h5>
    },
    {
        target: ".tour-groups",
        content: <h5 className="tour-steps"> This is where you can find the writing groups you can join. By default you’re part of a few groups where you can post writing pieces and have community discussions already. Learn more about the different groups here. </h5>
    },
    {
        target: ".body-courses",
        content: <h5 className="tour-steps"> This is where you’ll be taken to our learning platform. All your courses can be found here, including writing contest rules and a video tutorial on how to use this site. To get back to this page from My Courses, simply click the Workshop button in the menu. </h5>
    },
    {
        target: ".body-profile",
        content: <h5 className="tour-steps"> Under your profile, you will find your activity feed, your assignments, and your subscription plan. You can also fill out or edit your profile information </h5>
    },
    {
        target: ".help-desk",
        content: <h5 className="tour-steps">If you have any questions or need help, click the question mark to go to the support pages where you can find a guide or open a ticket. You can also find the video tutorial on how to use this site here </h5>
    },
    {
        target: ".feed-wrapper",
        content: <h4 className="tour-init"> To get started, go ahead and post your first message in the Cafe to introduce yourself to the community. Happy writing! </h4>,
        disableBeacon: true,
        placement: 'center',
    }
];