import React, { useState } from "react";
import { Input, Form, Icon, Button, Row, notification } from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { changePassword } from "../../../actions/auth";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

function ChangePassword(props: any) {
  const history = useHistory();
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [success, setSuccess] = useState(false);

  const openNotification = () => {
    notification.open({
      message: "Success",
      description: "Password Updated - Please login with the new Password!",
      icon: <Icon type="smile" style={{ color: "#108ee9" }} />,
    });
  };

  const handleSubmit = () => {
    props.form.validateFields((err: any, vals: any) => {
      if (!err) {
        const { new_password, confirm_new_password, old_password } = vals;
        const submit = props.changePassword({ old_password, new_password });
          localStorage.removeItem("twp-token");
          history.push("/auth/login");
        // setTimeout(function () {
        //   // window.location.reload();
        // }, 3000);
      }
    });
  };

  const { getFieldDecorator } = props.form;
  const { form } = props;

  const handleConfirmBlur = (e) => {
    const { value } = e.target;
    setConfirmDirty(true || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue("new_password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(["confirm_password"], { force: true });
    }
    callback();
  };

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Row className="inner">
        <Form.Item id="old_password">
          {getFieldDecorator("old_password", {
            rules: [
              {
                required: true,
                message: "Please enter old password!",
              },
            ],
          })(<Input.Password placeholder="Old Password" />)}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator("new_password", {
            rules: [
              {
                required: true,
                message: "Please input your password!",
              },
              {
                validator: validateToNextPassword,
              },
            ],
          })(<Input.Password placeholder="New Password" />)}
        </Form.Item>
        <Form.Item hasFeedback>
          {getFieldDecorator("confirm_password", {
            rules: [
              {
                required: true,
                message: "Please confirm your password!",
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          })(
            <Input.Password
              onBlur={handleConfirmBlur}
              placeholder="Confirm Password"
            />
          )}
        </Form.Item>
      </Row>
      <Form.Item>
        <Button
          className="btn-c1"
          size="large"
          type="primary"
          htmlType="submit"
        >
          Change Password
        </Button>
      </Form.Item>
    </Form>
  );
}

const Actions = {
  changePassword,
};

//Connect to redux store
const _Form = connect(null, Actions)(ChangePassword);

//Connect to Form and export
export default Form.create({ name: "forgot_password" })(_Form);
