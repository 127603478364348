import {
    FETCH_POST_INITIATE,
    FETCH_POST_INITIATE_FAILED,
    FETCH_POST_INITIATE_SUCCESS,
    FETCH_ARTICLES_SUCCESS
} from "../actions/_types";
import {unionBy} from 'lodash'

const initial_state = {
    loading: false,
    list: [],
    articles: []
}

const articleReducer = (state = initial_state, action) => {
    switch(action.type) {
        case FETCH_POST_INITIATE:
            return onFetch(state);
        case FETCH_POST_INITIATE_SUCCESS:
            return onFetchFinish(state);
        case FETCH_POST_INITIATE_FAILED:
            return onFetchFinish(state)
        case FETCH_ARTICLES_SUCCESS:
            return setArticleFeed(state, action.payload)
        default:
            return state;
    }
};

const onFetch = (state) => ({
    ...state,
    loading: true
});

const onFetchFinish = (state) => ({
    ...state,
    loading: false
});

const setArticleFeed = (state, posts) => {
    let list = unionBy(state.articles, posts, 'group_id')
    return {
        ...state,
        loading: false,
        articles: list
    }
}

export default articleReducer;