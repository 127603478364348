export default class CourseService {

    /**
     * @param {ApiService} apiService
     */
    constructor(apiService) {
        this.api = apiService;
    }

    createCourse(course){
        return this.api.post(`create-course`, course);
    }
    fetchCourses() {
        return this.api.get(`courses`);
    }
    fetchSingleCourse(id) {
        return this.api.get(`course/${id}`);
    }
    updateCourse(course){
        return this.api.put(`update-course`, course);
    }

}