import React, { useState, useContext } from "react";
import {
    Row,
    List,
    Col,
    Button,
    Icon,
    Dropdown,
    Menu,
    Card,
    Modal,
    message,
    Skeleton,
    Empty,
    Typography
} from "antd";
import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { AppContext } from "../../../utils/context";
import { fetchPracticePostsByGroupId, getPrivateSingleArticle } from "../../../actions/practice";
import { invokeCreatePost, fetchFeedPosts } from "../../../actions/posts";
import {
    followGroups,
    fetchAllGroups,
    fetchGroupsInit,
    fetchGroupFollowers,
    searchGroupFollowers,
    addRemoveFollowersToGroups,
    deleteGroup
} from "../../../actions/groups";
import { fetchUserSubscription } from "../../../actions/user";
import EditPracticeroupContainer from "../Practice/EditPracticeGroupContainer";

import PracticeCommentBlock from "../../snippets/listing/PracticeCommentBlock";
import MiniCreatePracticePostContainer from "./MiniCreatePracticePostContainer";
import PracticeGroupAvatar from "../../snippets/Avatars/PracticeGroupAvatar";
import PracticePath from "../../snippets/Breadcrumb/PracticePath";
import ListingSkeleton from "../../snippets/skeleton/Listing";

const { confirm } = Modal;
const { Text } = Typography;

interface userSubscription {
    charge: any;
    isExpired: string;
    isValid: string;
    plan: string;
    subscribed_date: string;
    subscription_period: string;
    subscription_plan_id: any;
    user_id: any;
    user_subscription_id: any;
}

interface Props {
    fetchPracticePostsByGroupId: (id: number, offset: number, limit: number) => any;
    followGroups: (group_id: number) => any;
    invokeCreatePost: (responseBody: any) => any;
    fetchAllGroups: () => any;
    fetchGroupsInit: (id: number) => any;
    getPrivateSingleArticle: (id: number) => any;
    fetchGroupFollowers: (id: number) => any;
    searchGroupFollowers: (responseBody: any) => any;
    addRemoveFollowersToGroups: (responseBody: any) => any;
    deleteGroup: (responseBody: any) => any;
    fetchFeedPosts: (offset: number, limit: number) => any;
    groups: any;
    loading: boolean;
    practices: Array<any>;
    articles: Array<any>;
    fetchUserSubscription: () => any;
}

const initGroup = {
    description: "",
    group_category: "",
    group_category_id: "",
    group_id: "",
    group_name: "",
    group_types: [
        {
            group_type: "",
            group_type_id: ""
        }
    ],
    icon: "",
    no_of_followers: "",
    subscription_plans: [
        {
            plan: "",
            subscription_id: ""
        }
    ],
    user_joined: "false",
    word_count: "",
    url: ""

};


function PracticeGroupContainer(props: Props) {
    const { id } = useParams();
    const { responsive } = useContext(AppContext);
    const parsedId = parseInt(id ? id : "");
    const [posts, setPost] = useState<Array<string>>([]);
    // let posts = filter(props.posts, { group: { group_id: parsedId } });

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const limit = 10;
    const [editor, toggleEditor] = React.useState(false);
    const [userSubscription, setUserSubscription] = useState({
        id: "",
        isExpired: "",
        isValid: "",
        plan: "",
        price: "",
        subscribed_date: "",
        subscription_period: "",
        user_id: "",
        user_subscription_id: "",
    });
    const [group, setGroup] = useState(initGroup)
    const [modalvisible, setModalVisible] = useState(false);
    const userRole = localStorage.getItem("twp-user-role");
    const [follow, setFollow] = useState("");
    const [articleAuthor, setArticleAuthor] = useState({
        assignment_type: "Matches",
        assignment_type_id: "",
        author_id: "",
        category: "",
        description: "",
        first_name: "",
        group_category_id: "",
        group_id: "",
        group_name: "",
        group_types: [],
        icon: "",
        last_name: "",
        profile_pic: "",
        url: ""

    });

    React.useEffect(() => {
        if (posts.length < 1) {
            invokeGroupFeed(0);
        }
    }, [parsedId]);

    const handleLoadMore = () => {
        if (hasMore) {
            invokeGroupFeed(offset + 1).then(() => setOffset(offset + 1));
        }
    };

    React.useEffect(() => {
        getSingleGroupDetails();
        getSingleArticleDetails()
    }, [parsedId]);


    //getSingleGroupDetails
    const getSingleGroupDetails = async () => {
        const subs = await props.fetchGroupsInit(parsedId);
        if (subs) {
            setGroup(subs);
        }
        return subs;
    };

    const getSingleArticleDetails = async () => {
        const subs = await props.getPrivateSingleArticle(parsedId);
        if (subs) {
            setArticleAuthor(subs)
        }
    };


    React.useEffect(() => {
        toggleEditor(false);

        //set user_joined value to follow variable
        setFollow(group.user_joined)
    }, [group]);

    //Show Group Feed
    const invokeGroupFeed = async (offset) => {

        setLoading(true);
        return await props
            .fetchPracticePostsByGroupId(parseInt(id ? id : ""), offset, limit)
            .then((res) => {
                setLoading(false);
                const posts =
                    res.data &&
                    res.data.map((item) => ({
                        ...item,
                        group: item.group,
                        user: item.user,
                    }));

                setPost(posts)
                if (posts && posts.length < limit) {
                    setHasMore(false);
                }
                setLoading(false);
            });
    };

    //Deletes the Group
    function showDeleteGroupConfirm() {
        confirm({
            title: `Are you sure you want to remove the group?`,
            icon: <Icon type="exclamation-circle" />,
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                props.deleteGroup(parseInt(id ? id : ""));
                message.success("Group Deleted");
                setTimeout(() => history.push("/"), 500);
                setTimeout(function () {
                    window.location.reload();
                }, 1500);

                // setTimeout(() => history.push("/"), 3000);
            },
        });
    };


    //Follow Groups
    const followGroup = () => {
        props.followGroups(parsedId).then((r) => {
            props.fetchAllGroups();
            props.fetchFeedPosts(offset, limit);

            if (r.message === "unfollowed successfully") {
                setFollow("false");
                setLoading(true);
            } else {
                setFollow("true");
                setLoading(true);
            }

            //Commented to prevent window reloads on follow
            // setTimeout(() => history.push("/"), 3000);
            // setTimeout(function () {
            //   window.location.reload();
            // }, 1000);
        });
    };
    // get all Subscriptions
    // const getUserSubscriptions = async () => {
    //     const subs = await props.fetchUserSubscription();
    //     if (subs) {
    //         setUserSubscription(subs);
    //     }
    //     return subs;
    // };

    // React.useEffect(() => {
    //     getUserSubscriptions();
    // }, []);


    // React.useEffect(() => {
    //     if (props.groups.length > 0 && userRole === "user") {
    //         checkGroupAccess();
    //     }
    // }, [props.groups]);

    // const checkGroupAccess = async () => {
    //     const grpRestrictions = await props.groups.filter((a: any) => a.id === parsedId)
    //     if (grpRestrictions === undefined || grpRestrictions.length == 0) {
    //         setModalVisible(true)
    //     }
    //     return grpRestrictions;
    // };
    
    return (
        <div className="twp-wrapper">
            <Row className="reader">
                <PracticePath blogPost={group.group_name} id={group.group_id} PracticeName={""} />
            </Row>
            <br />
            <Row className="reader">
                <Col style={{ maxWidth: 600 }}>
                    <Row>
                        {editor ? (
                            <div>
                                <Row type="flex" align="middle" gutter={16} className="inner">
                                    <Col>
                                        <Button onClick={() => toggleEditor(false)}>
                                            <Icon type="arrow-left" /> Back
                                        </Button>
                                    </Col>
                                    <Col>
                                        <h2 className="imp">Edit Group</h2>
                                    </Col>
                                </Row>
                                <EditPracticeroupContainer />
                            </div>
                        ) : (
                            props.loading ? (
                                <Skeleton loading={loading} active className="area" />
                            ) : (
                                <div className="area">
                                    {/* responsive */}
                                    {responsive < 541 ? (
                                        <Card size="small">
                                            <Row>
                                                {userRole === "admin" ? (
                                                    <Col className="inner" span={24}>
                                                        <PracticeGroupAvatar
                                                            size={70}
                                                            img={group.icon}
                                                            title={group.group_name}
                                                            description={group.description}
                                                            groupId={parsedId}
                                                            responsive={true}
                                                            author={articleAuthor.first_name + " " + articleAuthor.last_name}
                                                            url={articleAuthor.url}
                                                        />
                                                    </Col>
                                                ) : (
                                                    <Col className="inner" span={24}>
                                                        <PracticeGroupAvatar
                                                            size={70}
                                                            img={group.icon}
                                                            title={group.group_name}
                                                            description={group.description}
                                                            groupId={parsedId}
                                                            responsive={true}
                                                            author={articleAuthor.first_name + " " + articleAuthor.last_name}
                                                            url={articleAuthor.url}
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                            <Row justify="end">
                                                {/*following */}
                                                <Col className="inner" span={8} style={{ textAlign: "center" }}>
                                                    <div style={{ marginTop: 8 }}>
                                                        {follow === "true" ? (
                                                            <Dropdown.Button
                                                                trigger={["click"]}
                                                                overlay={
                                                                    <Menu>
                                                                        <Menu.Item
                                                                            style={{ color: "red" }}
                                                                            onClick={followGroup}
                                                                        >
                                                                            Unfollow
                                                                        </Menu.Item>
                                                                    </Menu>
                                                                }
                                                            >
                                                                {" "}
                                                                Following <Icon type="check" />
                                                            </Dropdown.Button>
                                                        ) : (
                                                            <Button type="primary" onClick={followGroup}>
                                                                Follow
                                                                <Icon type="plus" />
                                                            </Button>
                                                        )
                                                        }
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row style={{ textAlign: "justify" }}>
                                                <h3>{group.description}</h3>
                                            </Row>
                                            {userRole === "admin" ? (
                                                <Row justify="end">
                                                    <Col span={8} style={{ textAlign: "right" }}>
                                                        <Button icon="edit" onClick={() => toggleEditor(true)}>Edit</Button>
                                                    </Col>
                                                    <Col span={8} style={{ textAlign: "right" }}><Button onClick={() => showDeleteGroupConfirm()}>{" "}<Icon type="delete" /></Button></Col>
                                                </Row>
                                            ) : null}
                                        </Card>
                                    ) : (
                                        <Card size="small">
                                            <Row>
                                                {userRole === "admin" ? (
                                                    <Col className="inner" span={24}>
                                                        <PracticeGroupAvatar
                                                            size={70}
                                                            img={group.icon}
                                                            title={group.group_name}
                                                            description={group.description}
                                                            groupId={parsedId}
                                                            responsive={true}
                                                            author={articleAuthor.first_name + " " + articleAuthor.last_name}
                                                            url={articleAuthor.url}
                                                        />
                                                    </Col>
                                                ) : (
                                                    <Col className="inner" span={24}>
                                                        <PracticeGroupAvatar
                                                            size={70}
                                                            img={group.icon}
                                                            title={group.group_name}
                                                            description={group.description}
                                                            groupId={parsedId}
                                                            responsive={true}
                                                            author={articleAuthor.first_name + " " + articleAuthor.last_name}
                                                            url={articleAuthor.url}
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                            <Row justify="end">
                                                <Col className="inner" style={{ textAlign: "right" }}>
                                                    <div style={{ marginTop: 8 }}>
                                                        {follow === "true" ? (
                                                            <Dropdown.Button
                                                                trigger={["click"]}
                                                                overlay={
                                                                    <Menu>
                                                                        <Menu.Item
                                                                            style={{ color: "red" }}
                                                                            onClick={followGroup}
                                                                        >
                                                                            Unfollow
                                                                        </Menu.Item>
                                                                    </Menu>
                                                                }
                                                            >
                                                                {" "}
                                                                Following <Icon type="check" />
                                                            </Dropdown.Button>
                                                        ) : (
                                                            <Button type="primary" onClick={followGroup}>
                                                                Follow
                                                                <Icon type="plus" />
                                                            </Button>
                                                        )
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                            {userRole === "admin" ? (
                                                <Row justify="end">
                                                    <Col span={8}></Col>
                                                    <Col span={9}></Col>
                                                    <Col span={4} style={{ textAlign: "right" }}>
                                                        <Button icon="edit" onClick={() => toggleEditor(true)}>Edit</Button>
                                                    </Col>
                                                    <Col span={3} style={{ textAlign: "right" }}><Button onClick={() => showDeleteGroupConfirm()}>{" "}<Icon type="delete" /></Button></Col>
                                                </Row>
                                            ) : null}
                                        </Card>
                                    )}

                                </div>
                            )
                        )}
                        <div className="inner" />

                    </Row>
                    <div className="area">
                        {userSubscription.isExpired === "true" ? (
                            <MiniCreatePracticePostContainer
                                group_name={group.group_name}
                                groupId={parsedId}
                                groupType={group.group_types}
                                subcriptionExpired={userSubscription.isExpired}
                                type={"practice"}
                                grpWordCount={group.word_count}
                            />
                        ) : (
                            <MiniCreatePracticePostContainer
                                group_name={group.group_name}
                                groupId={parsedId}
                                groupType={group.group_types}
                                type={"practice"}
                                grpWordCount={group.word_count}
                            />
                        )}
                    </div>
                    <div className="inner" />
                    <div>
                        <h3>Practice Feed</h3>

                        {/* {posts.length > 0 ? ( */}
                        {props.loading ? (
                            <ListingSkeleton count={2} />
                        ) : (
                            <>
                                <List dataSource={posts} renderItem={PracticeCommentBlock} />
                                {posts.length > 0 ? (
                                    <Row type="flex" justify="center">
                                        <Button onClick={handleLoadMore}>Load more</Button>
                                        <div style={{ paddingBottom: 10 }}></div>
                                    </Row>
                                ) : null}

                            </>
                        )}
                        {/* ) : (
                            <Empty
                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                imageStyle={{
                                    height: 60,
                                }}
                                description={
                                    <Text style={{ fontSize: 12 }} type="secondary"> Follow groups to view posts on your feed</Text>
                                }
                            >
                            </Empty>
                        )} */}
                    </div>
                    {/* </Row> */}
                </Col>
            </Row>
            {/* <Modal
                visible={modalvisible}
                footer={null}
                maskClosable={false}
                closable={false}
                width={820}
                style={{ top: 200 }}
                bodyStyle={{ height: 400 }}
            >
                <div style={{ paddingTop: 30 }}>
                    <Result
                        subTitle="Sign up for the appropriate community to view this group."
                        extra={[
                            <>
                                <Col>
                                    <Link to="/">Home</Link>
                                </Col>
                                <Col>
                                    <a href={helpDesk} target="_blank">Contact Support</a>
                                </Col>
                            </>
                        ]}
                    />
                </div>
            </Modal> */}
            {/* ) : null} */}
        </div>

    );
}

const Actions = {
    fetchPracticePostsByGroupId,
    followGroups,
    fetchAllGroups,
    invokeCreatePost,
    fetchGroupsInit,
    fetchGroupFollowers,
    searchGroupFollowers,
    addRemoveFollowersToGroups,
    deleteGroup,
    fetchUserSubscription,
    getPrivateSingleArticle,
    fetchFeedPosts
};

const mapStateToProps = (state: any) => ({
    groups: state.groups.list,
    loading: state.posts.loading,
    articles: state.posts.articles,
    practices: state.posts.practices
});

export default connect(mapStateToProps, Actions)(PracticeGroupContainer);