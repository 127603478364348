import React, { useState } from "react";
import axios from 'axios';
import ImgCrop from "antd-img-crop";
import { Button, Upload, message, Icon, Spin, Row, Col } from 'antd';
import { connect } from "react-redux";

import { uploadProfilePicture } from "../../../actions/user";


const generateFileName = (file) => {
    let timestamp = new Date().getTime();
    return `logo_${timestamp}_${file.name}`;
}

const ImageUpload = (props) => {

    const [fileList, setFileList] = useState([]);
    const [iconUploaded, setIconUploaded] = useState(false);

    return (
        <>
            <ImgCrop width={props.width} height={props.height} resize={true}>
                <Upload
                    customRequest={async (e) => {
                        const file = e.file;
                        setFileList([file]);
                        setIconUploaded(true)

                        if (file) {
                            const f = generateFileName(file);
                            const fol = props.folder
                            try {

                                props
                                    .uploadProfilePicture(f, fol)
                                    .then((res) => {

                                        const uploadURL = res.signedRequest;
                                        const fileURL = res.url;
                                        var options = {
                                            headers: {
                                                'Content-Type': file.type
                                            }
                                        };

                                        try {
                                            (async () => {
                                                await axios.put(uploadURL, file, options);

                                                e.onSuccess({
                                                    file: file,
                                                });

                                                setIconUploaded(false)
                                                if (props.onFileUpload) props.onFileUpload(fileURL);
                                            })()
                                        } catch (uploadError) {
                                            console.log(uploadError);
                                        }
                                    })


                            } catch (e) {
                                message.error('File upload error!');
                            }
                        }
                    }}

                    multiple={false}
                    fileList={fileList}
                    onRemove={() => {
                        setFileList([]);
                    }}
                >
                    <div className="profile-image-upload">
                        <Row
                            className="inner"
                            gutter={16}
                            type="flex"
                            align="middle"
                            justify="center">
                            <Col span={20}>
                                <Button type="primary"> <Icon type="upload" />  Upload image</Button>
                            </Col>
                            <Col span={4}>
                                {iconUploaded == true ? (
                                    <Spin />
                                ) : null}
                            </Col>
                        </Row>
                    </div>

                </Upload>
            </ImgCrop>
        </>
    );
}

const Actions = {
    uploadProfilePicture,
};

export default connect(null, Actions)(ImageUpload);
