import React, { useState } from "react";
import { Button, Row, message } from "antd";
import { updatePost } from "../../../actions/posts";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { find } from "lodash";

import WritingPieceEditor from "../../snippets/editors/WritingPieceEditor";
import EditableMessageEditor from "../../snippets/editors/EditableMessageEditor";

interface EditPostProps {
  updatePost: (responseBody: any) => any;
  posts: Array<any>;
  groupType: any;
  postUserId: any
  groups: any
  groupId: any;
}

const initWPPost = {
  title: "",
  post: "",
  genre_id: 1,
  feedback: "",
  summary: "",
  word_count: ""
};

const initMessage = {
  post: "",
}

function EditPost(props: EditPostProps) {
  const { post_id } = useParams();
  const parsedId = parseInt(post_id ? post_id : "");
  const [state, setState] = useState(initWPPost); //WP
  const [initState, setInitState] = useState(initWPPost); //WP
  const [messageState, setMessageState] = useState(initMessage); //Message
  const [initMessageState, setInitMessageState] = useState(initMessage); //Message
  const [errors, setErrors] = useState<Array<string>>([]);
  const maxWords = 2500;
  const history = useHistory();
  const [edtGrpPost, setEdtGrpPost] = useState({
    word_count: ""
  });

  React.useEffect(() => {
    const post = find(props.posts, { post_id: parsedId });
    if (props.groupType === "WP") {
      if (post) {
        setInitState({
          title: post.title + "",
          post: post.post + "",
          genre_id: post.genre_id,
          feedback: post.feedback + "",
          summary: post.summary + "",
          word_count: post.word_count + ""
        });
      }
    } else {
      if (post) {
        setInitMessageState({
          post: post.title + ""
        });
      }
    }

  }, []);

  //gives an error here as 'word_count' is undefined because that said group is not available in the sub
  //admins would have this isssue if they try to edit another wp
  React.useEffect(() => {
    const grpFilter = props.groups.filter((a: any) => a.id == props.groupId);
    setEdtGrpPost(grpFilter[0])
  },[props.groupId])


  const validate = () => {
    setErrors([]); //Reset validation to validate again.
    let errs: Array<string> = [];

    //VALIDATE
    if (!state.title)
      errs.push("You need enter a title for your writing piece");

    if (!state.post.trim()) errs.push("You're writing piece is empty");

    if (state.post.length > maxWords)
      errs.push(
        "You're writing piece is too long. You cannot post anymore than " +
        maxWords +
        " words"
      );

    // set errors
    setErrors(errs);

    // return validation
    if (errs.length < 1) return true;

    return false;
  };

  const handleSubmit = (data: any) => {
    // const isValid = validate();
    if (props.groupType === "WP") {
      // if(isValid){
      const params = {
        ...state,
        post_id: parsedId,
      };

      props.updatePost(params);
      message.success("Post Updated");
      setTimeout(function () {
        window.location.reload();
      }, 1000);
      // }
    } else {

      // if(isValid){
      const params = {
        title: messageState.post,
        post: "",
        summary: "",
        feedback: "",
        genre_id: null,
        post_id: parsedId,
      };

      props.updatePost(params);
      message.success("Post Updated");
      setTimeout(function () {
        window.location.reload();
      }, 1000);

    }
  };

  return (
    <>
      <Row>
        {props.groupType === "WP" && initState.post ? (
          <WritingPieceEditor
            onChange={setState}
            post={initState}
            postUserId={props.postUserId}
            editGrpWordCount={edtGrpPost.word_count}
          ></WritingPieceEditor>
        ) : null}
        {props.groupType === "Message" && initMessageState ? (
          <EditableMessageEditor
            onChange={setMessageState}
            post={initMessageState}
          ></EditableMessageEditor>
        ) : null}
      </Row>
      {errors.length > 0 ? (
        <Row className="inner">
          {errors.map((err, i) => (
            <div key={`err_${i}`} style={{ color: "red" }}>
              <b>* {err}</b>
            </div>
          ))}
        </Row>
      ) : null}
      <br />
      <Row>
        <Button type="primary" htmlType="submit" onClick={handleSubmit}>
          Update
        </Button>
      </Row>
    </>
  );
}

const Actions = {
  updatePost,
};

const mapStoP = (state: any) => ({
  posts: state.posts.list,
  groups: state.groups.list,
});

export default connect(mapStoP, Actions)(EditPost);
