export default class AuthService {
     /**
     * @param {ApiService} apiService
     */

    constructor(apiService) {
        this.api = apiService;
    }

    signUp(user){
        return this.api.post(`signup`, user);
    }
    
    login (credentials) {
        return this.api.post(`signin`, credentials);
    }

    verifyEmail(token) {
        return this.api.post(`signup-email-verification`, {verification_token: token});
    }

    forgotPassword(email){
        return this.api.post('forgot-password', {email})
    }
     
    resetPassword(pwds) {
        return this.api.post('reset-password', pwds)
    }

    changePassword(pwds) {
        return this.api.put('change-password', pwds)
    }

    practiceSignUp(user) {
        return this.api.post(`practice/signup`, user);
    }
}