import React, { useContext } from 'react'
import { Row, Col, Avatar, Typography } from 'antd'
import { AppContext } from "../../../utils/context";

const { Text } = Typography;

interface Props {
    title: any,
    subtitle?: any,
    groupSubtitle?: any,
    genre?: any,
    img?: string,
    handler?: () => any,
    size: number
    postWordCount?: any,
    userRole?: any
    postType?: any;
}

function PracticeAvatar(props: Props) {
    const { title, subtitle, groupSubtitle, genre, img, handler, size, postWordCount, userRole, postType } = props;
    const { responsive } = useContext(AppContext);

    return (
        responsive < 541 ? (
            <Row type="flex" align="middle" gutter={32}>
                <Col onClick={handler}>
                    {/* <Avatar src={img} size={size}>{img ? "" : title && title.length > 0 ? title[0] : ""}</Avatar> */}
                    <div className="article-cover-image" style={{
                            backgroundImage: `url('${img ? "" : title && title.length > 0 ? title[0] : ""}')`,
                            backgroundSize: "cover",
                            backgroundPositionY: "center",
                            backgroundPositionX: "center",
                        }}></div>
                </Col>
                <Col span={18} className="twp-profile" style={{ marginLeft: size / 4 }}>
                    <Row>
                        <p className="twp-profile-title" style={{ fontSize: Math.round(size / 2.8) }} dangerouslySetInnerHTML={{ __html: title }}></p>
                    </Row>
                    <Row>
                        <p className="twp-profile-subtitle" style={{ fontSize: Math.round(size / 2.8) }} dangerouslySetInnerHTML={{ __html: groupSubtitle }}></p>
                        <p className="twp-profile-subtitle" style={{ fontSize: Math.round(size / 2.8) }} dangerouslySetInnerHTML={{ __html: genre }}></p>
                    </Row>
                    <Row>
                        <p className="twp-profile-subtitle" style={{ fontSize: Math.round(size / 2.8) }} dangerouslySetInnerHTML={{ __html: subtitle }}></p>
                    </Row>
                    {userRole === "admin" ? (
                        <Row>
                            <Col span={12}>
                                <button style={{ fontSize: Math.round(size / 3), background: "rgb(233,243,254)", width: 58, border: 4, color: "rgb(61,147,236)", borderRadius: 5 }}>Admin</button>
                            </Col>
                        </Row>
                    ) : null}

                    {postWordCount ? (
                        <p className="twp-profile-subtitle" style={{ fontSize: Math.round(size / 2.8), fontStyle: "italic" }} dangerouslySetInnerHTML={{ __html: postWordCount + " Words" }}></p>
                    ) : null}

                </Col>
            </Row>
        ) : (

            <Row type="flex" align="middle">
                <Col onClick={handler}>
                    {/* <Avatar src={img} size={size}>{img ? "" : title && title.length > 0 ? title[0] : ""}</Avatar> */}
                    <div className="article-cover-image" style={{
                            backgroundImage: `url('${img ? "" : title && title.length > 0 ? title[0] : ""}')`,
                            backgroundSize: "cover",
                            backgroundPositionY: "center",
                            backgroundPositionX: "center",
                        }}></div>
                </Col> 
                <Col className="twp-profile" style={{ marginLeft: size / 4 }}>
                    <Row>
                        <p className="twp-profile-title" style={{ fontSize: Math.round(size / 2.8) }} dangerouslySetInnerHTML={{ __html: title }}></p>
                    </Row>
                    <Row>
                        <p className="twp-profile-subtitle" style={{ fontSize: Math.round(size / 2.8) }} dangerouslySetInnerHTML={{ __html: groupSubtitle }}></p>
                        <p className="twp-profile-subtitle" style={{ fontSize: Math.round(size / 2.8) }} dangerouslySetInnerHTML={{ __html: genre }}></p>
                    </Row>
                    <Row>
                        <p className="twp-profile-subtitle" style={{ fontSize: Math.round(size / 2.8) }} dangerouslySetInnerHTML={{ __html: subtitle }}></p>
                    </Row>
                    {userRole === "admin" ? (
                        <Row>
                            <Col span={12}>
                                <button style={{ fontSize: Math.round(size / 3), background: "rgb(233,243,254)", width: 58, border: 4, color: "rgb(61,147,236)", borderRadius: 5 }}>Admin</button>
                            </Col>
                        </Row>
                    ) : null}

                    {postWordCount ? (
                        <p className="twp-profile-subtitle" style={{ fontSize: Math.round(size / 2.8), fontStyle: "italic" }} dangerouslySetInnerHTML={{ __html: postWordCount + " Words" }}></p>
                    ) : null}

                </Col>
            </Row>
        )

    )
}

export default PracticeAvatar;
