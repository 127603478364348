import React from 'react'
import PracticeContainer from '../../components/containers/Practice/PracticeContainer'
import WithSidebar from '../../components/shared/WithSidebar'

const PracticeFeed = () => {
    return (
        <WithSidebar>
        <PracticeContainer />
        </WithSidebar>
    )
};

export default PracticeFeed;