import React  from "react";
import WithSidebar from '../components/shared/WithSidebar'
import FeedContainer from "../components/containers/Feed/FeedContainer";

const Feed = () => {
    return (
        <WithSidebar>
            <FeedContainer />
        </WithSidebar>
    );
};

export default Feed;
